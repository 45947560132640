import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** action_dispatch_uploaded_file */
  File: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};


export type App = {
  __typename?: 'App';
  id: Scalars['ID'];
  operatingCountries?: Maybe<Array<Country>>;
  restaurantUserCountry?: Maybe<Scalars['String']>;
  restaurantUserCountryCode?: Maybe<Scalars['String']>;
};

export enum AvailableStatus {
  Available = 'available',
  Unavailable = 'unavailable'
}

/** Autogenerated return type of BulkAdd. */
export type BulkAddPayload = {
  __typename?: 'BulkAddPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of BulkEdit. */
export type BulkEditPayload = {
  __typename?: 'BulkEditPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CabinetSlot = {
  __typename?: 'CabinetSlot';
  count: Scalars['Int'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

/** Autogenerated return type of CancelDelivery. */
export type CancelDeliveryPayload = {
  __typename?: 'CancelDeliveryPayload';
  deliveryBooking?: Maybe<DeliveryBooking>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of CancelStoreOrder. */
export type CancelStoreOrderPayload = {
  __typename?: 'CancelStoreOrderPayload';
  errors?: Maybe<Array<Error>>;
  storeOrder?: Maybe<StoreOrder>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  popular: Scalars['Boolean'];
};

/** Autogenerated return type of ClockIn. */
export type ClockInPayload = {
  __typename?: 'ClockInPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<RestaurantUser>;
  timesheet?: Maybe<Timesheet>;
};

/** Autogenerated return type of ClockOut. */
export type ClockOutPayload = {
  __typename?: 'ClockOutPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<RestaurantUser>;
  timesheet?: Maybe<Timesheet>;
};

/** Autogenerated return type of CompletePurchaseOrder. */
export type CompletePurchaseOrderPayload = {
  __typename?: 'CompletePurchaseOrderPayload';
  errors?: Maybe<Array<Error>>;
  purchaseOrder?: Maybe<SupplierPurchaseOrder>;
};

export type Consumer = {
  __typename?: 'Consumer';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  country: Scalars['String'];
  currency: Currency;
  id: Scalars['ID'];
};

/** Autogenerated return type of CreateFinancing. */
export type CreateFinancingPayload = {
  __typename?: 'CreateFinancingPayload';
  errors?: Maybe<Array<Error>>;
  purchaseOrder?: Maybe<SupplierPurchaseOrder>;
};

export type CreateMenuCategoryInput = {
  name: Scalars['String'];
  availableStatus: AvailableStatus;
  sequence?: Maybe<Scalars['Int']>;
  storeId: Scalars['ID'];
  menuSectionId: Scalars['ID'];
};

/** Autogenerated return type of CreateMenuCategory. */
export type CreateMenuCategoryPayload = {
  __typename?: 'CreateMenuCategoryPayload';
  errors?: Maybe<Array<Error>>;
  menuCategory?: Maybe<MenuCategory>;
};

export type CreateMenuItemInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  membershipPrice?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  availableStatus: AvailableStatus;
  sequence?: Maybe<Scalars['Int']>;
  stockEnabled?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Int']>;
  storeId: Scalars['ID'];
  menuCategoryIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateMenuItem. */
export type CreateMenuItemPayload = {
  __typename?: 'CreateMenuItemPayload';
  errors?: Maybe<Array<Error>>;
  menuItem?: Maybe<MenuItem>;
};

export type CreateMenuOptionInput = {
  name: Scalars['String'];
  min: Scalars['Int'];
  max: Scalars['Int'];
  availableStatus: AvailableStatus;
  sequence?: Maybe<Scalars['Int']>;
  storeId: Scalars['ID'];
  itemIds?: Maybe<Array<Scalars['ID']>>;
  itemOptionIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateMenuOption. */
export type CreateMenuOptionPayload = {
  __typename?: 'CreateMenuOptionPayload';
  errors?: Maybe<Array<Error>>;
  menuOption?: Maybe<MenuOption>;
};

export type CreateMenuSectionInput = {
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  storeId: Scalars['ID'];
};

/** Autogenerated return type of CreateMenuSection. */
export type CreateMenuSectionPayload = {
  __typename?: 'CreateMenuSectionPayload';
  errors?: Maybe<Array<Error>>;
  menuSection?: Maybe<MenuSection>;
};

export type CreateMixOrderInput = {
  outletId: Scalars['ID'];
  platform?: Maybe<OrderPlatform>;
  paymentMethod: PaymentMethod;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryService?: Maybe<DeliveryService>;
  deliveryInstruction?: Maybe<Scalars['String']>;
  consumerName?: Maybe<Scalars['String']>;
  consumerPhone?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  storeOrdersAttributes: Array<CreateMixOrderStoreOrderInput>;
};

/** Autogenerated return type of CreateMixOrder. */
export type CreateMixOrderPayload = {
  __typename?: 'CreateMixOrderPayload';
  errors?: Maybe<Array<Error>>;
  mixOrder?: Maybe<MixOrder>;
};

export type CreateMixOrderStoreOrderInput = {
  storeId: Scalars['ID'];
  menuItems: Array<CreateOrderItemsInput>;
};

export type CreateOrderItemsInput = {
  id: Scalars['ID'];
  menuOptionItemIds?: Maybe<Array<Scalars['ID']>>;
  remark?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePaymentProof. */
export type CreatePaymentProofPayload = {
  __typename?: 'CreatePaymentProofPayload';
  errors?: Maybe<Array<Error>>;
  purchaseOrder?: Maybe<SupplierPurchaseOrder>;
};

export type CreatePromoInput = {
  storeId: Scalars['ID'];
  name: Scalars['String'];
  promoVariant: PromoVariant;
  amount: Scalars['Float'];
  menuItemIds?: Maybe<Array<Scalars['ID']>>;
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  days: Array<Days>;
};

/** Autogenerated return type of CreatePromo. */
export type CreatePromoPayload = {
  __typename?: 'CreatePromoPayload';
  errors?: Maybe<Array<Error>>;
  promo?: Maybe<Promo>;
};

export type CreatePurchaseOrderInput = {
  deliverTo: Scalars['String'];
  items: Array<CreatePurchaseOrderItemsInput>;
};

export type CreatePurchaseOrderItemsInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  deliveryDate: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of CreatePurchaseOrder. */
export type CreatePurchaseOrderPayload = {
  __typename?: 'CreatePurchaseOrderPayload';
  errors?: Maybe<Array<Error>>;
  purchaseOrders?: Maybe<Array<SupplierPurchaseOrder>>;
};

export type CreateStoreInput = {
  restaurantId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  hide?: Maybe<Scalars['Boolean']>;
  deliveryOption: Array<DeliveryOption>;
  deliveryServices: Array<DeliveryService>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  priceLevel: StorePriceLevel;
  taxPercentage?: Maybe<Scalars['Int']>;
  techFeePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  image?: Maybe<Scalars['File']>;
  banner?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of CreateStore. */
export type CreateStorePayload = {
  __typename?: 'CreateStorePayload';
  errors?: Maybe<Array<Error>>;
  store?: Maybe<Store>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  id: Scalars['ID'];
  paymentMethods: Array<PaymentMethod>;
  symbol: Scalars['String'];
};

export type DailySales = {
  __typename?: 'DailySales';
  id: Scalars['ID'];
  time: Scalars['String'];
  value: Scalars['Float'];
};

export type DailySoldItems = {
  __typename?: 'DailySoldItems';
  count: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DateRanges = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};

export enum Days {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday'
}

export type DeliveryBooking = {
  __typename?: 'DeliveryBooking';
  bookingId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration: Scalars['String'];
  riderName?: Maybe<Scalars['String']>;
  riderPhoneNumber?: Maybe<Scalars['String']>;
  riderVehicleNo?: Maybe<Scalars['String']>;
  trackingLink?: Maybe<Scalars['String']>;
};

export enum DeliveryOption {
  Delivery = 'delivery',
  Pickup = 'pickup',
  DineIn = 'dine_in'
}

export enum DeliveryService {
  Pandago = 'pandago',
  Lalamove = 'lalamove'
}

export type DiscountCodeOutlet = {
  __typename?: 'DiscountCodeOutlet';
  discounts: Array<DiscountCodeOutletDiscounts>;
  id: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DiscountCodeOutletDiscounts = {
  __typename?: 'DiscountCodeOutletDiscounts';
  appliedAmount: Scalars['Float'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated return type of DuplicateMenus. */
export type DuplicateMenusPayload = {
  __typename?: 'DuplicateMenusPayload';
  errors?: Maybe<Array<Error>>;
  response: StatusMessage;
};

export type EditStoreOrderItemInput = {
  externalId: Scalars['String'];
  count: Scalars['Int'];
};

/** Autogenerated return type of EditStoreOrder. */
export type EditStoreOrderPayload = {
  __typename?: 'EditStoreOrderPayload';
  errors?: Maybe<Array<Error>>;
  storeOrder?: Maybe<StoreOrder>;
};

/** Autogenerated return type of EmailResetPasswordUrl. */
export type EmailResetPasswordUrlPayload = {
  __typename?: 'EmailResetPasswordUrlPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};


/** Autogenerated return type of FinishStoreOrder. */
export type FinishStoreOrderPayload = {
  __typename?: 'FinishStoreOrderPayload';
  errors?: Maybe<Array<Error>>;
  storeOrder?: Maybe<StoreOrder>;
};

export type HeatMapAnalytic = {
  __typename?: 'HeatMapAnalytic';
  days: Array<Scalars['String']>;
  hours: Array<Scalars['Int']>;
  id: Scalars['ID'];
  values: Array<Array<Scalars['Int']>>;
};


export enum Integration {
  Coox = 'coox',
  Grabfood = 'grabfood',
  Foodpanda = 'foodpanda',
  Shopeefood = 'shopeefood'
}

export type IntegrationStatus = {
  __typename?: 'IntegrationStatus';
  id: Integration;
  menuSyncStatus: MenuSyncStatus;
  storePauseStatus: StorePauseStatus;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ItemCountByCategory = {
  __typename?: 'ItemCountByCategory';
  itemCategories: Array<ItemCategory>;
  itemCount: Array<Scalars['String']>;
};

export type KitchenStaffTimesheets = {
  __typename?: 'KitchenStaffTimesheets';
  email: Scalars['String'];
  id: Scalars['ID'];
  latestTimesheets?: Maybe<Array<Timesheet>>;
};

export type KitchenStaffTimesheetsSales = {
  __typename?: 'KitchenStaffTimesheetsSales';
  discount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  paymentMethod: PaymentMethod;
  sales?: Maybe<Scalars['Float']>;
};

export type Log = {
  __typename?: 'Log';
  action: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  loggable?: Maybe<Loggable>;
  store: Store;
};

export type LogPaginate = {
  __typename?: 'LogPaginate';
  id: Scalars['ID'];
  logs?: Maybe<Array<Log>>;
  pageLimit: Scalars['Int'];
  total: Scalars['Int'];
};

export type LogSuperAdmin = {
  __typename?: 'LogSuperAdmin';
  action: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  loggable?: Maybe<Loggable>;
  message: Scalars['String'];
  store: Store;
};

export type Loggable = MenuCategory | MenuItem | MenuOption | MenuSection | OpeningHour | Promo | Store;

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  registrationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Login. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<RestaurantUser>;
};

/** Autogenerated return type of LoginStaff. */
export type LoginStaffPayload = {
  __typename?: 'LoginStaffPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
};

export type MenuCategory = {
  __typename?: 'MenuCategory';
  availableStatus: AvailableStatus;
  id: Scalars['ID'];
  menuItems?: Maybe<Array<MenuItem>>;
  menuSection: MenuSection;
  name: Scalars['String'];
  posItemPositions: Array<Scalars['ID']>;
  sequence: Scalars['Int'];
};

export type MenuItem = {
  __typename?: 'MenuItem';
  availableStatus: AvailableStatus;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<Array<MenuCategory>>;
  menuOptions?: Maybe<Array<MenuOption>>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  priceWithPromo?: Maybe<Scalars['Float']>;
  priceWithTax?: Maybe<Scalars['Float']>;
  sequence: Scalars['Int'];
  stock?: Maybe<Scalars['Int']>;
  stockEnabled?: Maybe<Scalars['Boolean']>;
  store: Store;
  withCategories: Scalars['Boolean'];
};

/** Autogenerated return type of MenuItemUpdate. */
export type MenuItemUpdatePayload = {
  __typename?: 'MenuItemUpdatePayload';
  menuItem?: Maybe<MenuItem>;
};

export type MenuOption = {
  __typename?: 'MenuOption';
  availableStatus: AvailableStatus;
  id: Scalars['ID'];
  itemOptions?: Maybe<Array<MenuItem>>;
  items?: Maybe<Array<MenuItem>>;
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
};

export type MenuSection = {
  __typename?: 'MenuSection';
  id: Scalars['ID'];
  menuCategories?: Maybe<Array<MenuCategory>>;
  name: Scalars['String'];
  openingHours?: Maybe<Array<OpeningHour>>;
  sequence: Scalars['Int'];
};

export type MenuSyncStatus = {
  __typename?: 'MenuSyncStatus';
  id: Integration;
  menuLastSyncAt?: Maybe<Scalars['ISO8601DateTime']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type MerchantSales = {
  __typename?: 'MerchantSales';
  id: Scalars['ID'];
  pageLimit: Scalars['Int'];
  sales: Array<StoreOrder>;
};

export type MerchantSalesTotal = {
  __typename?: 'MerchantSalesTotal';
  id: Scalars['ID'];
  totalCount: Scalars['Int'];
  totalCounterFee: Scalars['Float'];
  totalExternalFee: Scalars['Float'];
  totalMerchantFundedPromo: Scalars['Float'];
  totalNetIncome: Scalars['Float'];
  totalOriginalPrice: Scalars['Float'];
  totalPlatformFee: Scalars['Float'];
  totalPlatformFundedPromo: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  totalRevenueShare: Scalars['Float'];
  totalTax: Scalars['Float'];
};

export type MixDiscountStore = {
  friendlyPath: Scalars['String'];
  subtotal: Scalars['Float'];
};

export type MixOrder = {
  __typename?: 'MixOrder';
  acceptedTime?: Maybe<Scalars['ISO8601DateTime']>;
  address?: Maybe<Scalars['String']>;
  brandNames?: Maybe<Array<Scalars['String']>>;
  consumer?: Maybe<Consumer>;
  consumerName?: Maybe<Scalars['String']>;
  consumerPhone?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creatorId?: Maybe<Scalars['ID']>;
  creatorType?: Maybe<Scalars['String']>;
  cutlery: Scalars['Boolean'];
  deliveryBooking?: Maybe<DeliveryBooking>;
  deliveryFee: Scalars['Float'];
  deliveryOption: DeliveryOption;
  externalFee: Scalars['Float'];
  finalPrice: Scalars['Float'];
  id: Scalars['ID'];
  integration?: Maybe<Integration>;
  merchantFundedPromo: Scalars['Float'];
  netIncome: Scalars['Float'];
  orderCode: Scalars['String'];
  orderId: Scalars['String'];
  orderMenuItems: Array<OrderMenuItems>;
  orderMenuItemsWithCount: Array<OrderMenuItemsWithCount>;
  originalPrice: Scalars['Float'];
  paymentMethod?: Maybe<PaymentMethod>;
  platform: Platform;
  platformFee: Scalars['Float'];
  platformFundedPromo: Scalars['Float'];
  printed: Scalars['Boolean'];
  promo: Scalars['Float'];
  rating?: Maybe<Rating>;
  revenue: Scalars['Float'];
  revenueShare: Scalars['Float'];
  state: OrderState;
  store: Outlet;
  storeOrders?: Maybe<Array<StoreOrder>>;
  tax: Scalars['Float'];
};

/** Autogenerated return type of MixOrderReceived. */
export type MixOrderReceivedPayload = {
  __typename?: 'MixOrderReceivedPayload';
  mixOrder?: Maybe<MixOrder>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkAdd?: Maybe<BulkAddPayload>;
  bulkEdit?: Maybe<BulkEditPayload>;
  cancelDelivery?: Maybe<CancelDeliveryPayload>;
  cancelStoreOrder?: Maybe<CancelStoreOrderPayload>;
  clockIn?: Maybe<ClockInPayload>;
  clockOut?: Maybe<ClockOutPayload>;
  completeSupplierPurchaseOrder?: Maybe<CompletePurchaseOrderPayload>;
  createMenuCategory?: Maybe<CreateMenuCategoryPayload>;
  createMenuItem?: Maybe<CreateMenuItemPayload>;
  createMenuOption?: Maybe<CreateMenuOptionPayload>;
  createMenuSection?: Maybe<CreateMenuSectionPayload>;
  createMixOrder?: Maybe<CreateMixOrderPayload>;
  createPromo?: Maybe<CreatePromoPayload>;
  createStore?: Maybe<CreateStorePayload>;
  createSupplierFinancing?: Maybe<CreateFinancingPayload>;
  createSupplierPaymentProof?: Maybe<CreatePaymentProofPayload>;
  createSupplierPurchaseOrder?: Maybe<CreatePurchaseOrderPayload>;
  duplicateMenus?: Maybe<DuplicateMenusPayload>;
  editStoreOrder?: Maybe<EditStoreOrderPayload>;
  emailResetPasswordUrl?: Maybe<EmailResetPasswordUrlPayload>;
  finishStoreOrder?: Maybe<FinishStoreOrderPayload>;
  login?: Maybe<LoginPayload>;
  loginStaff?: Maybe<LoginStaffPayload>;
  pauseStore?: Maybe<PauseStorePayload>;
  printStoreOrder?: Maybe<PrintStoreOrderPayload>;
  readyStoreOrder?: Maybe<ReadyStoreOrderPayload>;
  removeMenuCategory?: Maybe<RemoveMenuCategoryPayload>;
  removeMenuCategoryAll?: Maybe<RemoveMenuCategoryAllPayload>;
  removeMenuItem?: Maybe<RemoveMenuItemPayload>;
  removeMenuItemAll?: Maybe<RemoveMenuItemAllPayload>;
  removeMenuOption?: Maybe<RemoveMenuOptionPayload>;
  removeMenuOptionAll?: Maybe<RemoveMenuOptionAllPayload>;
  removeMenuSection?: Maybe<RemoveMenuSectionPayload>;
  removePromo?: Maybe<RemovePromoPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  setupStripeIntent?: Maybe<SetupIntentPayload>;
  syncMenus?: Maybe<SyncMenusPayload>;
  togglePromo?: Maybe<TogglePromoPayload>;
  updateGcmToken?: Maybe<UpdateGcmTokenPayload>;
  updateMenuCategory?: Maybe<UpdateMenuCategoryPayload>;
  updateMenuItem?: Maybe<UpdateMenuItemPayload>;
  updateMenuOption?: Maybe<UpdateMenuOptionPayload>;
  updateMenuSection?: Maybe<UpdateMenuSectionPayload>;
  updateOpeningHour?: Maybe<UpdateOpeningHourPayload>;
  updatePosItemPositions?: Maybe<UpdatePosItemPositionsPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updatePromo?: Maybe<UpdatePromoPayload>;
  updateStoreInfo?: Maybe<UpdateStoreInfoPayload>;
};


export type MutationBulkAddArgs = {
  file: Scalars['File'];
  storeId: Scalars['ID'];
};


export type MutationBulkEditArgs = {
  file: Scalars['File'];
  storeId: Scalars['ID'];
};


export type MutationCancelDeliveryArgs = {
  mixOrderId: Scalars['ID'];
};


export type MutationCancelStoreOrderArgs = {
  id: Scalars['ID'];
  refundAmount: Scalars['Float'];
  cancelCode: Scalars['Int'];
};


export type MutationClockInArgs = {
  pin: Scalars['String'];
};


export type MutationClockOutArgs = {
  pin: Scalars['String'];
};


export type MutationCompleteSupplierPurchaseOrderArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationCreateMenuCategoryArgs = {
  input: CreateMenuCategoryInput;
};


export type MutationCreateMenuItemArgs = {
  input: CreateMenuItemInput;
};


export type MutationCreateMenuOptionArgs = {
  input: CreateMenuOptionInput;
};


export type MutationCreateMenuSectionArgs = {
  input: CreateMenuSectionInput;
};


export type MutationCreateMixOrderArgs = {
  input: CreateMixOrderInput;
};


export type MutationCreatePromoArgs = {
  input: CreatePromoInput;
};


export type MutationCreateStoreArgs = {
  input: CreateStoreInput;
};


export type MutationCreateSupplierFinancingArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationCreateSupplierPaymentProofArgs = {
  file: Scalars['File'];
  purchaseOrderId: Scalars['ID'];
};


export type MutationCreateSupplierPurchaseOrderArgs = {
  input: CreatePurchaseOrderInput;
};


export type MutationDuplicateMenusArgs = {
  storeFromId: Scalars['ID'];
  storeToId: Scalars['ID'];
};


export type MutationEditStoreOrderArgs = {
  id: Scalars['ID'];
  items: Array<EditStoreOrderItemInput>;
};


export type MutationEmailResetPasswordUrlArgs = {
  email: Scalars['String'];
};


export type MutationFinishStoreOrderArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginStaffArgs = {
  pin: Scalars['String'];
};


export type MutationPauseStoreArgs = {
  id: Scalars['ID'];
  paused: Scalars['Boolean'];
};


export type MutationPrintStoreOrderArgs = {
  id: Scalars['ID'];
};


export type MutationReadyStoreOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMenuCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMenuCategoryAllArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRemoveMenuItemArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMenuItemAllArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRemoveMenuOptionArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMenuOptionAllArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRemoveMenuSectionArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePromoArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSyncMenusArgs = {
  storeId: Scalars['ID'];
  integration: Integration;
};


export type MutationTogglePromoArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateGcmTokenArgs = {
  input: UpdateGcmTokenInput;
};


export type MutationUpdateMenuCategoryArgs = {
  input: UpdateMenuCategoryInput;
  id: Scalars['ID'];
};


export type MutationUpdateMenuItemArgs = {
  input: UpdateMenuItemInput;
  id: Scalars['ID'];
};


export type MutationUpdateMenuOptionArgs = {
  input: UpdateMenuOptionInput;
  id: Scalars['ID'];
};


export type MutationUpdateMenuSectionArgs = {
  input: UpdateMenuSectionInput;
  id: Scalars['ID'];
};


export type MutationUpdateOpeningHourArgs = {
  menuSectionId: Scalars['ID'];
  input: UpdateOpeningHourInput;
};


export type MutationUpdatePosItemPositionsArgs = {
  id: Scalars['ID'];
  posItemPositions: Array<Scalars['ID']>;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdatePromoArgs = {
  id: Scalars['ID'];
  input: UpdatePromoInput;
};


export type MutationUpdateStoreInfoArgs = {
  input: UpdateStoreInfoInput;
  id: Scalars['ID'];
};

/** Attributes for Infinite Scrolling */
export type OffsetAttributes = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type OpeningHour = {
  __typename?: 'OpeningHour';
  close: Scalars['String'];
  day: Scalars['String'];
  id: Scalars['ID'];
  open: Scalars['String'];
};

export type OpeningHourInput = {
  day: Scalars['String'];
  open: Scalars['String'];
  close: Scalars['String'];
};

export type OrderMenuItemDetails = {
  __typename?: 'OrderMenuItemDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['String'];
};

export type OrderMenuItems = {
  __typename?: 'OrderMenuItems';
  details?: Maybe<Array<OrderMenuItemDetails>>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
};

export type OrderMenuItemsWithCount = {
  __typename?: 'OrderMenuItemsWithCount';
  count: Scalars['Int'];
  id: Scalars['ID'];
  item: OrderMenuItems;
};

export enum OrderPlatform {
  Web = 'web',
  Android = 'android',
  Ios = 'ios',
  AndroidPos = 'android_pos',
  IosPos = 'ios_pos'
}

export enum OrderState {
  Unfulfilled = 'unfulfilled',
  Accepted = 'accepted',
  Readied = 'readied',
  Collected = 'collected',
  Finished = 'finished',
  Cancelled = 'cancelled'
}

export type Outlet = {
  __typename?: 'Outlet';
  address: Scalars['String'];
  friendlyPath: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  restaurants?: Maybe<Array<Restaurant>>;
};

/** Autogenerated return type of PauseStore. */
export type PauseStorePayload = {
  __typename?: 'PauseStorePayload';
  errors?: Maybe<Array<Error>>;
  store?: Maybe<Store>;
};

export enum PaymentMethod {
  Fpx = 'fpx',
  Card = 'card',
  Cash = 'cash',
  Grabpay = 'grabpay',
  Qr = 'qr'
}

export enum Platform {
  Web = 'web',
  Android = 'android',
  Ios = 'ios',
  AndroidPos = 'android_pos',
  IosPos = 'ios_pos'
}

/** Autogenerated return type of PrintStoreOrder. */
export type PrintStoreOrderPayload = {
  __typename?: 'PrintStoreOrderPayload';
  errors?: Maybe<Array<Error>>;
  storeOrder?: Maybe<StoreOrder>;
};

export type Promo = {
  __typename?: 'Promo';
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  days: Array<Days>;
  endTime: Scalars['String'];
  id: Scalars['ID'];
  menuItemIds?: Maybe<Array<Scalars['ID']>>;
  menuItems: Array<MenuItem>;
  name: Scalars['String'];
  promoVariant: PromoVariant;
  startTime: Scalars['String'];
  store: Store;
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
};

export enum PromoVariant {
  Fixed = 'fixed',
  Percentage = 'percentage'
}

export type Query = {
  __typename?: 'Query';
  app?: Maybe<App>;
  applyMixDiscountCode?: Maybe<DiscountCodeOutlet>;
  bnpl?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  heatMapAnalytic?: Maybe<HeatMapAnalytic>;
  itemsBulkAddTemplate: Scalars['String'];
  itemsBulkEditTemplate: Scalars['String'];
  itemsCountByCategories?: Maybe<ItemCountByCategory>;
  kitchenAppUpdateUrl?: Maybe<Scalars['String']>;
  log?: Maybe<LogSuperAdmin>;
  logActionsMeta: Array<Scalars['String']>;
  logsPaginate?: Maybe<LogPaginate>;
  me?: Maybe<RestaurantUser>;
  meStaff?: Maybe<RestaurantUser>;
  menuCategory?: Maybe<MenuCategory>;
  menuItem?: Maybe<MenuItem>;
  menuItems?: Maybe<Array<MenuItem>>;
  menuSection?: Maybe<MenuSection>;
  merchantSales?: Maybe<MerchantSales>;
  merchantSalesCsv?: Maybe<Scalars['String']>;
  merchantSalesTotal?: Maybe<MerchantSalesTotal>;
  mixOrder?: Maybe<MixOrder>;
  mixOrders?: Maybe<Array<MixOrder>>;
  outlets?: Maybe<Array<Outlet>>;
  priceLevels?: Maybe<Array<RestaurantStorePriceLevel>>;
  ratings?: Maybe<Array<Rating>>;
  ratingsBreakdown?: Maybe<Array<RatingBreakdown>>;
  restaurants?: Maybe<Array<Restaurant>>;
  settlements?: Maybe<Array<Settlement>>;
  settlementsDownload?: Maybe<Scalars['String']>;
  siblingStores?: Maybe<Array<Store>>;
  staff?: Maybe<RestaurantUser>;
  staffs?: Maybe<Array<RestaurantUser>>;
  store?: Maybe<Store>;
  storeStatus?: Maybe<StoreStatus>;
  stores?: Maybe<Array<Store>>;
  storesAnalytic?: Maybe<StoreAnalytic>;
  supplierItemCategories?: Maybe<Array<SupplierItemCategory>>;
  supplierItems?: Maybe<Array<SupplierItem>>;
  supplierPurchaseOrder?: Maybe<SupplierPurchaseOrder>;
  supplierPurchaseOrders?: Maybe<Array<SupplierPurchaseOrder>>;
  suppliers?: Maybe<Array<Supplier>>;
  timesheets?: Maybe<Array<KitchenStaffTimesheets>>;
};


export type QueryApplyMixDiscountCodeArgs = {
  discountCode: Scalars['String'];
  subtotal: Scalars['Float'];
  deliveryOption: DeliveryOption;
  paymentMethod?: Maybe<PaymentMethod>;
  storeData: Array<MixDiscountStore>;
  platform?: Maybe<Platform>;
};


export type QueryHeatMapAnalyticArgs = {
  storeId: Scalars['ID'];
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};


export type QueryItemsBulkEditTemplateArgs = {
  storeId: Scalars['ID'];
  menuCategoryIds: Array<Scalars['ID']>;
};


export type QueryItemsCountByCategoriesArgs = {
  storeId: Scalars['ID'];
};


export type QueryKitchenAppUpdateUrlArgs = {
  currentVersion: Scalars['String'];
};


export type QueryLogArgs = {
  id: Scalars['ID'];
};


export type QueryLogsPaginateArgs = {
  page: Scalars['Int'];
  storeIds: Array<Scalars['ID']>;
  actions: Array<Scalars['String']>;
};


export type QueryMenuCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryMenuItemArgs = {
  id: Scalars['ID'];
};


export type QueryMenuItemsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMenuSectionArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantSalesArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration>>;
  states?: Maybe<Array<OrderState>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  page: Scalars['Int'];
};


export type QueryMerchantSalesCsvArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration>>;
  states?: Maybe<Array<OrderState>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};


export type QueryMerchantSalesTotalArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration>>;
  states?: Maybe<Array<OrderState>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};


export type QueryMixOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMixOrdersArgs = {
  state: Array<OrderState>;
  offsetAttributes: OffsetAttributes;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  integrations?: Maybe<Array<Integration>>;
};


export type QueryRestaurantsArgs = {
  outletIds: Array<Scalars['ID']>;
};


export type QuerySettlementsArgs = {
  restaurantIds: Array<Scalars['ID']>;
  dateRanges: Array<DateRanges>;
};


export type QuerySettlementsDownloadArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryStaffArgs = {
  id: Scalars['ID'];
};


export type QueryStoreArgs = {
  id: Scalars['ID'];
};


export type QueryStoreStatusArgs = {
  storeId: Scalars['ID'];
};


export type QueryStoresArgs = {
  restaurantIds: Array<Scalars['ID']>;
  showHidden?: Maybe<Scalars['Boolean']>;
  shouldSort?: Maybe<Scalars['Boolean']>;
};


export type QueryStoresAnalyticArgs = {
  storeIds: Array<Scalars['ID']>;
  integrations: Array<Integration>;
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};


export type QuerySupplierItemsArgs = {
  supplierItemCategoryIds?: Maybe<Array<Scalars['ID']>>;
  supplierIds?: Maybe<Array<Scalars['ID']>>;
};


export type QuerySupplierPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierPurchaseOrdersArgs = {
  states: Array<SupplierPurchaseOrderRestaurantUserState>;
};

export type Rating = {
  __typename?: 'Rating';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  rate: Scalars['Int'];
  storeMixOrder: StoreOrder;
};

export type RatingBreakdown = {
  __typename?: 'RatingBreakdown';
  id: Scalars['ID'];
  starCount: Scalars['Int'];
  value: Scalars['Int'];
};

/** Autogenerated return type of ReadyStoreOrder. */
export type ReadyStoreOrderPayload = {
  __typename?: 'ReadyStoreOrderPayload';
  errors?: Maybe<Array<Error>>;
  storeOrder?: Maybe<StoreOrder>;
};

/** Autogenerated return type of RemoveMenuCategoryAll. */
export type RemoveMenuCategoryAllPayload = {
  __typename?: 'RemoveMenuCategoryAllPayload';
  errors?: Maybe<Array<Error>>;
  menuCategories?: Maybe<Array<MenuCategory>>;
};

/** Autogenerated return type of RemoveMenuCategory. */
export type RemoveMenuCategoryPayload = {
  __typename?: 'RemoveMenuCategoryPayload';
  errors?: Maybe<Array<Error>>;
  menuCategory?: Maybe<MenuCategory>;
};

/** Autogenerated return type of RemoveMenuItemAll. */
export type RemoveMenuItemAllPayload = {
  __typename?: 'RemoveMenuItemAllPayload';
  errors?: Maybe<Array<Error>>;
  menuItems?: Maybe<Array<MenuItem>>;
};

/** Autogenerated return type of RemoveMenuItem. */
export type RemoveMenuItemPayload = {
  __typename?: 'RemoveMenuItemPayload';
  errors?: Maybe<Array<Error>>;
  menuItem?: Maybe<MenuItem>;
};

/** Autogenerated return type of RemoveMenuOptionAll. */
export type RemoveMenuOptionAllPayload = {
  __typename?: 'RemoveMenuOptionAllPayload';
  errors?: Maybe<Array<Error>>;
  menuOptions?: Maybe<Array<MenuOption>>;
};

/** Autogenerated return type of RemoveMenuOption. */
export type RemoveMenuOptionPayload = {
  __typename?: 'RemoveMenuOptionPayload';
  errors?: Maybe<Array<Error>>;
  menuOption?: Maybe<MenuOption>;
};

/** Autogenerated return type of RemoveMenuSection. */
export type RemoveMenuSectionPayload = {
  __typename?: 'RemoveMenuSectionPayload';
  errors?: Maybe<Array<Error>>;
  menuSection?: Maybe<MenuSection>;
};

/** Autogenerated return type of RemovePromo. */
export type RemovePromoPayload = {
  __typename?: 'RemovePromoPayload';
  errors?: Maybe<Array<Error>>;
  promo?: Maybe<Promo>;
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<RestaurantUser>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  id: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  outlet: Outlet;
  stores?: Maybe<Array<Store>>;
};

export type RestaurantStorePriceLevel = {
  __typename?: 'RestaurantStorePriceLevel';
  id: StorePriceLevel;
  level: StorePriceLevel;
  name: Scalars['String'];
};

export type RestaurantUser = {
  __typename?: 'RestaurantUser';
  activeTimesheet?: Maybe<Timesheet>;
  countryCode: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isBanned: Scalars['Boolean'];
  isCashier: Scalars['Boolean'];
  nationalNumber: Scalars['String'];
  phoneNumber: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
  role: RestaurantUserRole;
  savedCardDetails?: Maybe<Scalars['String']>;
  timesheets?: Maybe<Array<Timesheet>>;
};

export enum RestaurantUserRole {
  SuperAdmin = 'super_admin',
  KitchenStaff = 'kitchen_staff',
  Owner = 'owner'
}

export type SalesByBrand = {
  __typename?: 'SalesByBrand';
  brand?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['Float'];
};

export type SalesByDeliveryOption = {
  __typename?: 'SalesByDeliveryOption';
  color: Scalars['String'];
  deliveryOption?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  value: Scalars['Float'];
};

export type Settlement = {
  __typename?: 'Settlement';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  pdf?: Maybe<Scalars['String']>;
  restaurant: Restaurant;
};

/** Autogenerated return type of SetupIntent. */
export type SetupIntentPayload = {
  __typename?: 'SetupIntentPayload';
  clientSecret?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

export type StatusMessage = {
  __typename?: 'StatusMessage';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Store = {
  __typename?: 'Store';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  analytic: StoreAnalytic;
  banner?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  deliveryOption: Array<DeliveryOption>;
  deliveryServices: Array<DeliveryService>;
  description?: Maybe<Scalars['String']>;
  foodpandaChainId?: Maybe<Scalars['String']>;
  friendlyPath: Scalars['String'];
  grabfoodStoreId?: Maybe<Scalars['String']>;
  hide: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  menuCategories?: Maybe<Array<MenuCategory>>;
  menuCategory?: Maybe<MenuCategory>;
  menuItem?: Maybe<MenuItem>;
  menuItems?: Maybe<Array<MenuItem>>;
  menuItemsWithCategories?: Maybe<Array<MenuItem>>;
  menuOption?: Maybe<MenuOption>;
  menuOptions?: Maybe<Array<MenuOption>>;
  menuSection?: Maybe<MenuSection>;
  name: Scalars['String'];
  nationalNumber: Scalars['String'];
  openingHours?: Maybe<Array<OpeningHour>>;
  pauseTime?: Maybe<Scalars['ISO8601DateTime']>;
  paused: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  priceLevel: StorePriceLevel;
  promo?: Maybe<Promo>;
  promos?: Maybe<Array<Promo>>;
  restaurant: Restaurant;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  shopeefoodStoreId?: Maybe<Scalars['String']>;
  taxPercentage: Scalars['Int'];
  techFeePercentage?: Maybe<Scalars['Float']>;
  variant: StoreVariant;
};


export type StoreAnalyticArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};


export type StoreMenuCategoryArgs = {
  id: Scalars['ID'];
};


export type StoreMenuItemArgs = {
  id: Scalars['ID'];
};


export type StoreMenuOptionArgs = {
  id: Scalars['ID'];
};


export type StorePromoArgs = {
  id: Scalars['ID'];
};

export type StoreAnalytic = {
  __typename?: 'StoreAnalytic';
  dailySales?: Maybe<Array<DailySales>>;
  dailySoldItems?: Maybe<Array<DailySoldItems>>;
  id: Scalars['ID'];
  ordersCount: Scalars['Int'];
  sales: Scalars['Float'];
  salesByBrand?: Maybe<Array<SalesByBrand>>;
  salesByDeliveryOption?: Maybe<Array<SalesByDeliveryOption>>;
};

export type StoreOrder = {
  __typename?: 'StoreOrder';
  acceptedTime?: Maybe<Scalars['ISO8601DateTime']>;
  cabinetSlot?: Maybe<CabinetSlot>;
  cabinetSlotNumber?: Maybe<Scalars['String']>;
  cancelMessage?: Maybe<Scalars['String']>;
  cancelableData?: Maybe<StoreOrderCancel>;
  consumer?: Maybe<Consumer>;
  consumerName?: Maybe<Scalars['String']>;
  consumerPhone?: Maybe<Scalars['String']>;
  counterFee: Scalars['Float'];
  counterFeePercentage: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  cutlery: Scalars['Boolean'];
  deliveryOption: DeliveryOption;
  discountCode?: Maybe<Scalars['String']>;
  externalFee: Scalars['Float'];
  finalPrice: Scalars['Float'];
  id: Scalars['ID'];
  integration?: Maybe<Integration>;
  isFirstMobileOrder: Scalars['Boolean'];
  isMine: Scalars['Boolean'];
  merchantFundedPromo: Scalars['Float'];
  mixOrderId: Scalars['ID'];
  netIncome: Scalars['Float'];
  orderCode: Scalars['String'];
  orderId: Scalars['String'];
  orderMenuItems: Array<OrderMenuItems>;
  orderMenuItemsWithCount: Array<OrderMenuItemsWithCount>;
  originalPrice: Scalars['Float'];
  paymentMethod?: Maybe<PaymentMethod>;
  platformFee: Scalars['Float'];
  platformFeePercentage: Scalars['Float'];
  platformFundedPromo: Scalars['Float'];
  printed: Scalars['Boolean'];
  promo: Scalars['Float'];
  rating?: Maybe<Rating>;
  revenue: Scalars['Float'];
  revenueShare: Scalars['Float'];
  revenueSharePercentage: Scalars['Float'];
  state: OrderState;
  store: Store;
  tax: Scalars['Float'];
};

export type StoreOrderCancel = {
  __typename?: 'StoreOrderCancel';
  cancelable: Scalars['Boolean'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
  nonCancelableReason: Scalars['String'];
  reasons: Array<StoreOrderCancelReason>;
};

export type StoreOrderCancelReason = {
  __typename?: 'StoreOrderCancelReason';
  code: Scalars['Int'];
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type StorePauseStatus = {
  __typename?: 'StorePauseStatus';
  changeable: Scalars['Boolean'];
  id: Integration;
  paused: Scalars['Boolean'];
  reasonType?: Maybe<Scalars['String']>;
  response: Scalars['String'];
};

export enum StorePriceLevel {
  Cheap = 'cheap',
  Normal = 'normal',
  Expensive = 'expensive'
}

export type StoreStatus = {
  __typename?: 'StoreStatus';
  foodpanda: IntegrationStatus;
  grabfood: IntegrationStatus;
  id: Scalars['ID'];
  shopeefood: IntegrationStatus;
};

export enum StoreVariant {
  Food = 'food',
  Mart = 'mart'
}

export type Subscription = {
  __typename?: 'Subscription';
  menuItemUpdate: MenuItemUpdatePayload;
  mixOrderReceived: MixOrderReceivedPayload;
};

export type Supplier = {
  __typename?: 'Supplier';
  email: Scalars['String'];
  id: Scalars['ID'];
  minOrderPrice: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export enum SupplierFinancing {
  None = 'none',
  Reviewing = 'reviewing',
  Accepted = 'accepted',
  Paid = 'paid',
  Rejected = 'rejected'
}

export type SupplierItem = {
  __typename?: 'SupplierItem';
  availableStatus: AvailableStatus;
  deliveryDate?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryDays: Array<Scalars['String']>;
  halal: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  sku: Scalars['String'];
  stockCount?: Maybe<Scalars['Int']>;
  stockEnabled: Scalars['Boolean'];
  supplier: Supplier;
  uom: Scalars['String'];
};

export type SupplierItemCategory = {
  __typename?: 'SupplierItemCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SupplierPurchaseOrder = {
  __typename?: 'SupplierPurchaseOrder';
  createdAt: Scalars['ISO8601DateTime'];
  deliverTo: Scalars['String'];
  financingState: SupplierFinancing;
  id: Scalars['ID'];
  invoice?: Maybe<Scalars['String']>;
  invoicedAt?: Maybe<Scalars['ISO8601DateTime']>;
  items: Array<SupplierItem>;
  orderCode: Scalars['String'];
  paymentProof?: Maybe<Scalars['String']>;
  paymentProvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  price: Scalars['Int'];
  rejectReason?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
  restaurantUser: RestaurantUser;
  state?: Maybe<SupplierPurchaseOrderRestaurantUserState>;
  supplier: Supplier;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SupplierPurchaseOrderRestaurantUserState {
  Created = 'created',
  Acknowledged = 'acknowledged',
  SupplierRejected = 'supplier_rejected',
  Invoiced = 'invoiced',
  FundingReviewing = 'funding_reviewing',
  FundingRejected = 'funding_rejected',
  Paid = 'paid',
  Scheduled = 'scheduled',
  Delivering = 'delivering',
  Delivered = 'delivered',
  Cancelled = 'cancelled'
}

/** Autogenerated return type of SyncMenus. */
export type SyncMenusPayload = {
  __typename?: 'SyncMenusPayload';
  errors?: Maybe<Array<Error>>;
  response: StatusMessage;
};

export type Timesheet = {
  __typename?: 'Timesheet';
  clockHours?: Maybe<Scalars['Float']>;
  clockInAt: Scalars['ISO8601DateTime'];
  clockOutAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  sales?: Maybe<Array<KitchenStaffTimesheetsSales>>;
};

/** Autogenerated return type of TogglePromo. */
export type TogglePromoPayload = {
  __typename?: 'TogglePromoPayload';
  errors?: Maybe<Array<Error>>;
  promo?: Maybe<Promo>;
};

export type UpdateGcmTokenInput = {
  idToDelete?: Maybe<Scalars['String']>;
  idToAdd?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateGcmToken. */
export type UpdateGcmTokenPayload = {
  __typename?: 'UpdateGcmTokenPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<RestaurantUser>;
};

export type UpdateMenuCategoryInput = {
  name?: Maybe<Scalars['String']>;
  availableStatus?: Maybe<AvailableStatus>;
  sequence?: Maybe<Scalars['Int']>;
  menuSectionId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateMenuCategory. */
export type UpdateMenuCategoryPayload = {
  __typename?: 'UpdateMenuCategoryPayload';
  errors?: Maybe<Array<Error>>;
  menuCategory?: Maybe<MenuCategory>;
};

export type UpdateMenuItemInput = {
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  availableStatus?: Maybe<AvailableStatus>;
  sequence?: Maybe<Scalars['Int']>;
  stockEnabled?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['File']>;
  menuCategoryIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateMenuItem. */
export type UpdateMenuItemPayload = {
  __typename?: 'UpdateMenuItemPayload';
  errors?: Maybe<Array<Error>>;
  menuItem?: Maybe<MenuItem>;
};

export type UpdateMenuOptionInput = {
  name?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  availableStatus?: Maybe<AvailableStatus>;
  sequence?: Maybe<Scalars['Int']>;
  itemIds?: Maybe<Array<Scalars['ID']>>;
  itemOptionIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateMenuOption. */
export type UpdateMenuOptionPayload = {
  __typename?: 'UpdateMenuOptionPayload';
  errors?: Maybe<Array<Error>>;
  menuOption?: Maybe<MenuOption>;
};

export type UpdateMenuSectionInput = {
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateMenuSection. */
export type UpdateMenuSectionPayload = {
  __typename?: 'UpdateMenuSectionPayload';
  errors?: Maybe<Array<Error>>;
  menuSection?: Maybe<MenuSection>;
};

export type UpdateOpeningHourInput = {
  openingHours: Array<OpeningHourInput>;
};

/** Autogenerated return type of UpdateOpeningHour. */
export type UpdateOpeningHourPayload = {
  __typename?: 'UpdateOpeningHourPayload';
  errors?: Maybe<Array<Error>>;
  menuSection?: Maybe<MenuSection>;
};

/** Autogenerated return type of UpdatePosItemPositions. */
export type UpdatePosItemPositionsPayload = {
  __typename?: 'UpdatePosItemPositionsPayload';
  errors?: Maybe<Array<Error>>;
  menuCategory?: Maybe<MenuCategory>;
};

export type UpdateProfileInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<RestaurantUser>;
};

export type UpdatePromoInput = {
  name?: Maybe<Scalars['String']>;
  promoVariant?: Maybe<PromoVariant>;
  amount?: Maybe<Scalars['Float']>;
  menuItemIds?: Maybe<Array<Scalars['ID']>>;
  validFrom?: Maybe<Scalars['ISO8601DateTime']>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  days?: Maybe<Array<Days>>;
};

/** Autogenerated return type of UpdatePromo. */
export type UpdatePromoPayload = {
  __typename?: 'UpdatePromoPayload';
  errors?: Maybe<Array<Error>>;
  promo?: Maybe<Promo>;
};

export type UpdateStoreInfoInput = {
  restaurantId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Array<DeliveryOption>>;
  deliveryServices?: Maybe<Array<DeliveryService>>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  hide?: Maybe<Scalars['Boolean']>;
  priceLevel: StorePriceLevel;
  taxPercentage?: Maybe<Scalars['Int']>;
  techFeePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  grabfoodStoreId?: Maybe<Scalars['String']>;
  foodpandaChainId?: Maybe<Scalars['String']>;
  shopeefoodStoreId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  banner?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of UpdateStoreInfo. */
export type UpdateStoreInfoPayload = {
  __typename?: 'UpdateStoreInfoPayload';
  errors?: Maybe<Array<Error>>;
  store?: Maybe<Store>;
};

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail' | 'metadata'>
);

export type IntegrationStatusFragmentFragment = (
  { __typename?: 'IntegrationStatus' }
  & Pick<IntegrationStatus, 'id'>
  & { storePauseStatus: (
    { __typename?: 'StorePauseStatus' }
    & Pick<StorePauseStatus, 'id' | 'paused' | 'changeable' | 'response' | 'reasonType'>
  ), menuSyncStatus: (
    { __typename?: 'MenuSyncStatus' }
    & Pick<MenuSyncStatus, 'id' | 'menuLastSyncAt' | 'success' | 'message'>
  ) }
);

export type MeFragment = (
  { __typename?: 'RestaurantUser' }
  & Pick<RestaurantUser, 'id' | 'role' | 'email' | 'isCashier'>
);

export type SupplierPurchaseOrdersFragment = (
  { __typename?: 'SupplierPurchaseOrder' }
  & Pick<SupplierPurchaseOrder, 'id' | 'orderCode' | 'createdAt' | 'updatedAt' | 'state' | 'price'>
  & { supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  ) }
);

export type SupplierPurchaseOrderFragment = (
  { __typename?: 'SupplierPurchaseOrder' }
  & Pick<SupplierPurchaseOrder, 'id' | 'orderCode' | 'createdAt' | 'invoicedAt' | 'paymentProvedAt' | 'price' | 'state' | 'financingState' | 'deliverTo' | 'rejectReason' | 'report' | 'invoice' | 'paymentProof'>
  & { restaurantUser: (
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email'>
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'email' | 'phoneNumber'>
  ) }
);

export type TimesheetFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'clockInAt' | 'clockOutAt' | 'clockHours'>
  & { sales?: Maybe<Array<(
    { __typename?: 'KitchenStaffTimesheetsSales' }
    & Pick<KitchenStaffTimesheetsSales, 'id' | 'paymentMethod' | 'sales' | 'discount'>
  )>> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  registrationId?: Maybe<Scalars['String']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'jwt'>
    & { me?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id' | 'email'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CancelDeliveryMutationVariables = Exact<{
  mixOrderId: Scalars['ID'];
}>;


export type CancelDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { cancelDelivery?: Maybe<(
    { __typename?: 'CancelDeliveryPayload' }
    & { deliveryBooking?: Maybe<(
      { __typename?: 'DeliveryBooking' }
      & Pick<DeliveryBooking, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateGcmTokenMutationVariables = Exact<{
  idToDelete?: Maybe<Scalars['String']>;
  idToAdd?: Maybe<Scalars['String']>;
}>;


export type UpdateGcmTokenMutation = (
  { __typename?: 'Mutation' }
  & { updateGcmToken?: Maybe<(
    { __typename?: 'UpdateGcmTokenPayload' }
    & { me?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type BulkAddMutationVariables = Exact<{
  file: Scalars['File'];
  storeId: Scalars['ID'];
}>;


export type BulkAddMutation = (
  { __typename?: 'Mutation' }
  & { bulkAdd?: Maybe<(
    { __typename?: 'BulkAddPayload' }
    & Pick<BulkAddPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type BulkEditMutationVariables = Exact<{
  file: Scalars['File'];
  storeId: Scalars['ID'];
}>;


export type BulkEditMutation = (
  { __typename?: 'Mutation' }
  & { bulkEdit?: Maybe<(
    { __typename?: 'BulkEditPayload' }
    & Pick<BulkEditPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMenuCategoryMutationVariables = Exact<{
  storeId: Scalars['ID'];
  menuSectionId: Scalars['ID'];
  name: Scalars['String'];
  availableStatus: AvailableStatus;
  sequence?: Maybe<Scalars['Int']>;
}>;


export type CreateMenuCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createMenuCategory?: Maybe<(
    { __typename?: 'CreateMenuCategoryPayload' }
    & { menuCategory?: Maybe<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMenuCategoryMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuCategory?: Maybe<(
    { __typename?: 'RemoveMenuCategoryPayload' }
    & { menuCategory?: Maybe<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuCategoryAllMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveMenuCategoryAllMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuCategoryAll?: Maybe<(
    { __typename?: 'RemoveMenuCategoryAllPayload' }
    & { menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMenuCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  menuSectionId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  availableStatus?: Maybe<AvailableStatus>;
  sequence?: Maybe<Scalars['Int']>;
}>;


export type UpdateMenuCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateMenuCategory?: Maybe<(
    { __typename?: 'UpdateMenuCategoryPayload' }
    & { menuCategory?: Maybe<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdatePosItemPositionsMutationVariables = Exact<{
  id: Scalars['ID'];
  posItemPositions: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdatePosItemPositionsMutation = (
  { __typename?: 'Mutation' }
  & { updatePosItemPositions?: Maybe<(
    { __typename?: 'UpdatePosItemPositionsPayload' }
    & { menuCategory?: Maybe<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'posItemPositions'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMenuItemMutationVariables = Exact<{
  storeId: Scalars['ID'];
  name: Scalars['String'];
  availableStatus: AvailableStatus;
  price: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  stockEnabled: Scalars['Boolean'];
  stock?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['File']>;
  menuCategoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { createMenuItem?: Maybe<(
    { __typename?: 'CreateMenuItemPayload' }
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'description' | 'price' | 'image' | 'stockEnabled' | 'stock'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuItem?: Maybe<(
    { __typename?: 'RemoveMenuItemPayload' }
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuItemAllMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveMenuItemAllMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuItemAll?: Maybe<(
    { __typename?: 'RemoveMenuItemAllPayload' }
    & { menuItems?: Maybe<Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMenuItemMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  availableStatus?: Maybe<AvailableStatus>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  stockEnabled: Scalars['Boolean'];
  stock?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['File']>;
  menuCategoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { updateMenuItem?: Maybe<(
    { __typename?: 'UpdateMenuItemPayload' }
    & { menuItem?: Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'description' | 'price' | 'image' | 'stockEnabled' | 'stock'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMenuOptionMutationVariables = Exact<{
  storeId: Scalars['ID'];
  name: Scalars['String'];
  availableStatus: AvailableStatus;
  sequence?: Maybe<Scalars['Int']>;
  min: Scalars['Int'];
  max: Scalars['Int'];
  itemIds: Array<Scalars['ID']> | Scalars['ID'];
  itemOptionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateMenuOptionMutation = (
  { __typename?: 'Mutation' }
  & { createMenuOption?: Maybe<(
    { __typename?: 'CreateMenuOptionPayload' }
    & { menuOption?: Maybe<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name' | 'min' | 'max'>
      & { items?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'price'>
      )>>, itemOptions?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'price'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuOptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMenuOptionMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuOption?: Maybe<(
    { __typename?: 'RemoveMenuOptionPayload' }
    & { menuOption?: Maybe<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuOptionAllMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveMenuOptionAllMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuOptionAll?: Maybe<(
    { __typename?: 'RemoveMenuOptionAllPayload' }
    & { menuOptions?: Maybe<Array<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMenuOptionMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  availableStatus?: Maybe<AvailableStatus>;
  sequence?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  itemIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  itemOptionIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateMenuOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateMenuOption?: Maybe<(
    { __typename?: 'UpdateMenuOptionPayload' }
    & { menuOption?: Maybe<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name' | 'min' | 'max'>
      & { items?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'price'>
      )>>, itemOptions?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'price'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMenuSectionMutationVariables = Exact<{
  storeId: Scalars['ID'];
  name: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
}>;


export type CreateMenuSectionMutation = (
  { __typename?: 'Mutation' }
  & { createMenuSection?: Maybe<(
    { __typename?: 'CreateMenuSectionPayload' }
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id' | 'name' | 'sequence'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMenuSectionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMenuSectionMutation = (
  { __typename?: 'Mutation' }
  & { removeMenuSection?: Maybe<(
    { __typename?: 'RemoveMenuSectionPayload' }
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMenuSectionMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
}>;


export type UpdateMenuSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateMenuSection?: Maybe<(
    { __typename?: 'UpdateMenuSectionPayload' }
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id' | 'name' | 'sequence'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateOpeningHourMutationVariables = Exact<{
  menuSectionId: Scalars['ID'];
  openingHours: Array<OpeningHourInput> | OpeningHourInput;
}>;


export type UpdateOpeningHourMutation = (
  { __typename?: 'Mutation' }
  & { updateOpeningHour?: Maybe<(
    { __typename?: 'UpdateOpeningHourPayload' }
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
      & { openingHours?: Maybe<Array<(
        { __typename?: 'OpeningHour' }
        & Pick<OpeningHour, 'id' | 'day' | 'open' | 'close'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EmailResetPasswordUrlMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type EmailResetPasswordUrlMutation = (
  { __typename?: 'Mutation' }
  & { emailResetPasswordUrl?: Maybe<(
    { __typename?: 'EmailResetPasswordUrlPayload' }
    & Pick<EmailResetPasswordUrlPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & { user?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UpdateProfilePayload' }
    & { me?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id' | 'email'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePromoMutationVariables = Exact<{
  storeId: Scalars['ID'];
  name: Scalars['String'];
  promoVariant: PromoVariant;
  amount: Scalars['Float'];
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  days: Array<Days> | Days;
  menuItemIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreatePromoMutation = (
  { __typename?: 'Mutation' }
  & { createPromo?: Maybe<(
    { __typename?: 'CreatePromoPayload' }
    & { promo?: Maybe<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemovePromoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemovePromoMutation = (
  { __typename?: 'Mutation' }
  & { removePromo?: Maybe<(
    { __typename?: 'RemovePromoPayload' }
    & { promo?: Maybe<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type TogglePromoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TogglePromoMutation = (
  { __typename?: 'Mutation' }
  & { togglePromo?: Maybe<(
    { __typename?: 'TogglePromoPayload' }
    & { promo?: Maybe<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'active'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdatePromoMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  promoVariant?: Maybe<PromoVariant>;
  amount?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['ISO8601DateTime']>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  days?: Maybe<Array<Days> | Days>;
  menuItemIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdatePromoMutation = (
  { __typename?: 'Mutation' }
  & { updatePromo?: Maybe<(
    { __typename?: 'UpdatePromoPayload' }
    & { promo?: Maybe<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateStoreMutationVariables = Exact<{
  restaurantId: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  hide?: Maybe<Scalars['Boolean']>;
  deliveryOption: Array<DeliveryOption> | DeliveryOption;
  deliveryServices: Array<DeliveryService> | DeliveryService;
  taxPercentage?: Maybe<Scalars['Int']>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  techFeePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  categoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  priceLevel: StorePriceLevel;
  image?: Maybe<Scalars['File']>;
  banner?: Maybe<Scalars['File']>;
}>;


export type CreateStoreMutation = (
  { __typename?: 'Mutation' }
  & { createStore?: Maybe<(
    { __typename?: 'CreateStorePayload' }
    & { store?: Maybe<(
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name' | 'paused' | 'pauseTime' | 'description' | 'address' | 'longitude' | 'latitude' | 'deliveryOption' | 'taxPercentage' | 'counterFeePercentage' | 'techFeePercentage' | 'revenueSharePercentage' | 'priceLevel'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DuplicateMenusMutationVariables = Exact<{
  storeFromId: Scalars['ID'];
  storeToId: Scalars['ID'];
}>;


export type DuplicateMenusMutation = (
  { __typename?: 'Mutation' }
  & { duplicateMenus?: Maybe<(
    { __typename?: 'DuplicateMenusPayload' }
    & { response: (
      { __typename?: 'StatusMessage' }
      & Pick<StatusMessage, 'success' | 'message'>
    ) }
  )> }
);

export type PauseStoreMutationVariables = Exact<{
  id: Scalars['ID'];
  paused: Scalars['Boolean'];
}>;


export type PauseStoreMutation = (
  { __typename?: 'Mutation' }
  & { pauseStore?: Maybe<(
    { __typename?: 'PauseStorePayload' }
    & { store?: Maybe<(
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'paused'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SyncMenusMutationVariables = Exact<{
  storeId: Scalars['ID'];
  integration: Integration;
}>;


export type SyncMenusMutation = (
  { __typename?: 'Mutation' }
  & { syncMenus?: Maybe<(
    { __typename?: 'SyncMenusPayload' }
    & { response: (
      { __typename?: 'StatusMessage' }
      & Pick<StatusMessage, 'success' | 'message'>
    ) }
  )> }
);

export type UpdateStoreInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  restaurantId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  hide?: Maybe<Scalars['Boolean']>;
  deliveryOption?: Maybe<Array<DeliveryOption> | DeliveryOption>;
  deliveryServices?: Maybe<Array<DeliveryService> | DeliveryService>;
  taxPercentage?: Maybe<Scalars['Int']>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  techFeePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  grabfoodStoreId?: Maybe<Scalars['String']>;
  foodpandaChainId?: Maybe<Scalars['String']>;
  shopeefoodStoreId?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  image?: Maybe<Scalars['File']>;
  banner?: Maybe<Scalars['File']>;
  priceLevel: StorePriceLevel;
}>;


export type UpdateStoreInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateStoreInfo?: Maybe<(
    { __typename?: 'UpdateStoreInfoPayload' }
    & { store?: Maybe<(
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name' | 'description' | 'address' | 'longitude' | 'latitude' | 'deliveryOption' | 'taxPercentage' | 'counterFeePercentage' | 'techFeePercentage' | 'revenueSharePercentage' | 'priceLevel'>
      & { restaurant: (
        { __typename?: 'Restaurant' }
        & Pick<Restaurant, 'id'>
      ), categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
      )>> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CancelStoreOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  refundAmount: Scalars['Float'];
  cancelCode: Scalars['Int'];
}>;


export type CancelStoreOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelStoreOrder?: Maybe<(
    { __typename?: 'CancelStoreOrderPayload' }
    & { storeOrder?: Maybe<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EditStoreOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  items: Array<EditStoreOrderItemInput> | EditStoreOrderItemInput;
}>;


export type EditStoreOrderMutation = (
  { __typename?: 'Mutation' }
  & { editStoreOrder?: Maybe<(
    { __typename?: 'EditStoreOrderPayload' }
    & { storeOrder?: Maybe<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ReadyStoreOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReadyStoreOrderMutation = (
  { __typename?: 'Mutation' }
  & { readyStoreOrder?: Maybe<(
    { __typename?: 'ReadyStoreOrderPayload' }
    & { storeOrder?: Maybe<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'state' | 'cabinetSlotNumber'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CompletePurchaseOrderMutationVariables = Exact<{
  purchaseOrderId: Scalars['ID'];
}>;


export type CompletePurchaseOrderMutation = (
  { __typename?: 'Mutation' }
  & { completeSupplierPurchaseOrder?: Maybe<(
    { __typename?: 'CompletePurchaseOrderPayload' }
    & { purchaseOrder?: Maybe<(
      { __typename?: 'SupplierPurchaseOrder' }
      & SupplierPurchaseOrderFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateFinancingMutationVariables = Exact<{
  purchaseOrderId: Scalars['ID'];
}>;


export type CreateFinancingMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierFinancing?: Maybe<(
    { __typename?: 'CreateFinancingPayload' }
    & { purchaseOrder?: Maybe<(
      { __typename?: 'SupplierPurchaseOrder' }
      & SupplierPurchaseOrderFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSupplierPaymentProofMutationVariables = Exact<{
  file: Scalars['File'];
  purchaseOrderId: Scalars['ID'];
}>;


export type CreateSupplierPaymentProofMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierPaymentProof?: Maybe<(
    { __typename?: 'CreatePaymentProofPayload' }
    & { purchaseOrder?: Maybe<(
      { __typename?: 'SupplierPurchaseOrder' }
      & SupplierPurchaseOrderFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePurchaseOrderMutationVariables = Exact<{
  deliverTo: Scalars['String'];
  items: Array<CreatePurchaseOrderItemsInput> | CreatePurchaseOrderItemsInput;
}>;


export type CreatePurchaseOrderMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierPurchaseOrder?: Maybe<(
    { __typename?: 'CreatePurchaseOrderPayload' }
    & { purchaseOrders?: Maybe<Array<(
      { __typename?: 'SupplierPurchaseOrder' }
      & SupplierPurchaseOrderFragment
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SetupStripeIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type SetupStripeIntentMutation = (
  { __typename?: 'Mutation' }
  & { setupStripeIntent?: Maybe<(
    { __typename?: 'SetupIntentPayload' }
    & Pick<SetupIntentPayload, 'clientSecret'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type LogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LogQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, log?: Maybe<(
    { __typename?: 'LogSuperAdmin' }
    & Pick<LogSuperAdmin, 'id' | 'email' | 'action' | 'message' | 'createdAt'>
    & { store: (
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name'>
    ), loggable?: Maybe<(
      { __typename: 'MenuCategory' }
      & Pick<MenuCategory, 'id'>
    ) | (
      { __typename: 'MenuItem' }
      & Pick<MenuItem, 'id'>
    ) | (
      { __typename: 'MenuOption' }
      & Pick<MenuOption, 'id'>
    ) | (
      { __typename: 'MenuSection' }
      & Pick<MenuSection, 'id'>
    ) | (
      { __typename: 'OpeningHour' }
      & Pick<OpeningHour, 'id'>
    ) | (
      { __typename: 'Promo' }
      & Pick<Promo, 'id'>
    ) | (
      { __typename: 'Store' }
      & Pick<Store, 'id'>
    )> }
  )> }
);

export type OrderDetailPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderDetailPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, mixOrder?: Maybe<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'state' | 'orderId' | 'orderCode' | 'consumerName' | 'consumerPhone' | 'integration' | 'deliveryOption' | 'platform' | 'cutlery' | 'paymentMethod' | 'acceptedTime' | 'address'>
    & { store: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name'>
    ), deliveryBooking?: Maybe<(
      { __typename?: 'DeliveryBooking' }
      & Pick<DeliveryBooking, 'id' | 'integration' | 'bookingId' | 'riderName' | 'riderPhoneNumber' | 'riderVehicleNo' | 'trackingLink'>
    )>, storeOrders?: Maybe<Array<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'isMine' | 'state' | 'cancelMessage' | 'cabinetSlotNumber' | 'originalPrice' | 'tax' | 'promo' | 'finalPrice' | 'revenue' | 'externalFee' | 'counterFee' | 'platformFee' | 'revenueShare' | 'netIncome'>
      & { cancelableData?: Maybe<(
        { __typename?: 'StoreOrderCancel' }
        & Pick<StoreOrderCancel, 'id' | 'cancelable' | 'nonCancelableReason' | 'limit'>
        & { reasons: Array<(
          { __typename?: 'StoreOrderCancelReason' }
          & Pick<StoreOrderCancelReason, 'id' | 'code' | 'reason'>
        )> }
      )>, rating?: Maybe<(
        { __typename?: 'Rating' }
        & Pick<Rating, 'id' | 'rate' | 'comment'>
      )>, store: (
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name' | 'country'>
      ), orderMenuItemsWithCount: Array<(
        { __typename?: 'OrderMenuItemsWithCount' }
        & Pick<OrderMenuItemsWithCount, 'id' | 'count'>
        & { item: (
          { __typename?: 'OrderMenuItems' }
          & Pick<OrderMenuItems, 'id' | 'externalId' | 'name' | 'price' | 'remark'>
          & { details?: Maybe<Array<(
            { __typename?: 'OrderMenuItemDetails' }
            & Pick<OrderMenuItemDetails, 'id' | 'name'>
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type StoreInfoPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StoreInfoPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
    & { restaurants?: Maybe<Array<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number'>
    )>> }
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, priceLevels?: Maybe<Array<(
    { __typename?: 'RestaurantStorePriceLevel' }
    & Pick<RestaurantStorePriceLevel, 'id' | 'level' | 'name'>
  )>>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'description' | 'friendlyPath' | 'countryCode' | 'nationalNumber' | 'address' | 'hide' | 'image' | 'banner' | 'longitude' | 'latitude' | 'deliveryOption' | 'deliveryServices' | 'taxPercentage' | 'counterFeePercentage' | 'techFeePercentage' | 'revenueSharePercentage' | 'priceLevel' | 'grabfoodStoreId' | 'foodpandaChainId' | 'shopeefoodStoreId'>
    & { restaurant: (
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id'>
    ), categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    )>> }
  )> }
);

export type EditMenuCategoryPageQueryVariables = Exact<{
  id: Scalars['ID'];
  storeId: Scalars['ID'];
}>;


export type EditMenuCategoryPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id'>
    & { menuCategory?: Maybe<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name' | 'availableStatus' | 'sequence'>
      & { menuSection: (
        { __typename?: 'MenuSection' }
        & Pick<MenuSection, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type CreateMenuCategoryPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type CreateMenuCategoryPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id' | 'name'>
    )> }
  )> }
);

export type MenuCategoryListPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type MenuCategoryListPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
    )>, menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name' | 'availableStatus'>
      & { menuItems?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'availableStatus' | 'description' | 'price' | 'image'>
      )>> }
    )>> }
  )> }
);

export type EditMenuItemPageQueryVariables = Exact<{
  id: Scalars['ID'];
  storeId: Scalars['ID'];
}>;


export type EditMenuItemPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name'>
    & { menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name'>
      & { menuSection: (
        { __typename?: 'MenuSection' }
        & Pick<MenuSection, 'id' | 'name'>
      ) }
    )>>, menuItem?: Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'availableStatus' | 'description' | 'sequence' | 'image' | 'price' | 'stock' | 'stockEnabled'>
      & { menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type CreateMenuItemPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type CreateMenuItemPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name'>
    & { menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name'>
      & { menuSection: (
        { __typename?: 'MenuSection' }
        & Pick<MenuSection, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type MenuItemListPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type MenuItemListPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country'>
    & { menuItems?: Maybe<Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'availableStatus' | 'withCategories' | 'description' | 'image' | 'price' | 'stockEnabled' | 'stock'>
    )>> }
  )> }
);

export type EditMenuOptionPageQueryVariables = Exact<{
  id: Scalars['ID'];
  storeId: Scalars['ID'];
}>;


export type EditMenuOptionPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country'>
    & { menuItems?: Maybe<Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'price' | 'withCategories'>
    )>>, menuOption?: Maybe<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name' | 'availableStatus' | 'sequence' | 'min' | 'max'>
      & { items?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id'>
      )>>, itemOptions?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id'>
      )>> }
    )> }
  )> }
);

export type CreateMenuOptionPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type CreateMenuOptionPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country'>
    & { menuItems?: Maybe<Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'price' | 'withCategories'>
    )>> }
  )> }
);

export type MenuOptionListPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type MenuOptionListPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country'>
    & { menuOptions?: Maybe<Array<(
      { __typename?: 'MenuOption' }
      & Pick<MenuOption, 'id' | 'name' | 'availableStatus' | 'min' | 'max'>
      & { items?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'price'>
      )>>, itemOptions?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'name' | 'price'>
      )>> }
    )>> }
  )> }
);

export type EditMenuSectionPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type EditMenuSectionPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id' | 'name' | 'sequence'>
    )> }
  )> }
);

export type OpeningHourPageQueryVariables = Exact<{
  menuSectionId: Scalars['ID'];
}>;


export type OpeningHourPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, menuSection?: Maybe<(
    { __typename?: 'MenuSection' }
    & Pick<MenuSection, 'id' | 'name'>
    & { openingHours?: Maybe<Array<(
      { __typename?: 'OpeningHour' }
      & Pick<OpeningHour, 'id' | 'day' | 'open' | 'close'>
    )>> }
  )> }
);

export type CreateMenuSectionPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type CreateMenuSectionPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id'>
    )> }
  )> }
);

export type MenuSectionListPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type MenuSectionListPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id'>
    & { menuSection?: Maybe<(
      { __typename?: 'MenuSection' }
      & Pick<MenuSection, 'id' | 'name'>
      & { openingHours?: Maybe<Array<(
        { __typename?: 'OpeningHour' }
        & Pick<OpeningHour, 'id' | 'day' | 'open' | 'close'>
      )>>, menuCategories?: Maybe<Array<(
        { __typename?: 'MenuCategory' }
        & Pick<MenuCategory, 'id' | 'name' | 'availableStatus'>
      )>> }
    )> }
  )> }
);

export type StorePromoUpdatePageQueryVariables = Exact<{
  id: Scalars['ID'];
  storeId: Scalars['ID'];
}>;


export type StorePromoUpdatePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'country'>
    & { menuItemsWithCategories?: Maybe<Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'price'>
    )>>, promo?: Maybe<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'name' | 'promoVariant' | 'amount' | 'validFrom' | 'validUntil' | 'startTime' | 'endTime' | 'days' | 'active' | 'menuItemIds'>
    )> }
  )> }
);

export type StorePromoPageQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type StorePromoPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'country'>
    & { menuItemsWithCategories?: Maybe<Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name' | 'price'>
    )>> }
  )> }
);

export type CreateStorePageQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateStorePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
    & { restaurants?: Maybe<Array<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number'>
    )>> }
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, priceLevels?: Maybe<Array<(
    { __typename?: 'RestaurantStorePriceLevel' }
    & Pick<RestaurantStorePriceLevel, 'id' | 'level' | 'name'>
  )>> }
);

export type UpdateProfilePageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateProfilePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'countryCode' | 'nationalNumber' | 'phoneNumber'>
    & MeFragment
  )> }
);

export type AppMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type AppMetadataQuery = (
  { __typename?: 'Query' }
  & { app?: Maybe<(
    { __typename?: 'App' }
    & Pick<App, 'id' | 'restaurantUserCountry' | 'restaurantUserCountryCode'>
    & { operatingCountries?: Maybe<Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'country' | 'code'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol' | 'code'>
      ) }
    )>> }
  )> }
);

export type ExportMerchantSalesCsvQueryVariables = Exact<{
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  integrations?: Maybe<Array<Integration> | Integration>;
  states?: Maybe<Array<OrderState> | OrderState>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type ExportMerchantSalesCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'merchantSalesCsv'>
);

export type StoreStatusQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type StoreStatusQuery = (
  { __typename?: 'Query' }
  & { storeStatus?: Maybe<(
    { __typename?: 'StoreStatus' }
    & Pick<StoreStatus, 'id'>
    & { grabfood: (
      { __typename?: 'IntegrationStatus' }
      & IntegrationStatusFragmentFragment
    ), foodpanda: (
      { __typename?: 'IntegrationStatus' }
      & IntegrationStatusFragmentFragment
    ), shopeefood: (
      { __typename?: 'IntegrationStatus' }
      & IntegrationStatusFragmentFragment
    ) }
  )> }
);

export type ItemsBulkAddTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemsBulkAddTemplateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'itemsBulkAddTemplate'>
);

export type ItemsCountByCategoriesQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type ItemsCountByCategoriesQuery = (
  { __typename?: 'Query' }
  & { itemsCountByCategories?: Maybe<(
    { __typename?: 'ItemCountByCategory' }
    & Pick<ItemCountByCategory, 'itemCount'>
    & { itemCategories: Array<(
      { __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'id' | 'name'>
    )> }
  )> }
);

export type ItemsBulkEditTemplateQueryVariables = Exact<{
  storeId: Scalars['ID'];
  menuCategoryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ItemsBulkEditTemplateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'itemsBulkEditTemplate'>
);

export type KitchensQueryVariables = Exact<{
  outletIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type KitchensQuery = (
  { __typename?: 'Query' }
  & { restaurants?: Maybe<Array<(
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'name' | 'number'>
  )>> }
);

export type KitchenStaffsQueryVariables = Exact<{ [key: string]: never; }>;


export type KitchenStaffsQuery = (
  { __typename?: 'Query' }
  & { staffs?: Maybe<Array<(
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email' | 'role' | 'phoneNumber' | 'pin' | 'isCashier' | 'isBanned'>
  )>> }
);

export type KitchenStaffQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KitchenStaffQuery = (
  { __typename?: 'Query' }
  & { staff?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email' | 'role' | 'phoneNumber' | 'pin' | 'isBanned'>
    & { timesheets?: Maybe<Array<(
      { __typename?: 'Timesheet' }
      & TimesheetFragment
    )>> }
  )> }
);

export type KitchenStaffsTimesheetsQueryVariables = Exact<{ [key: string]: never; }>;


export type KitchenStaffsTimesheetsQuery = (
  { __typename?: 'Query' }
  & { timesheets?: Maybe<Array<(
    { __typename?: 'KitchenStaffTimesheets' }
    & Pick<KitchenStaffTimesheets, 'id' | 'email'>
    & { latestTimesheets?: Maybe<Array<(
      { __typename?: 'Timesheet' }
      & TimesheetFragment
    )>> }
  )>> }
);

export type LogsPaginateQueryVariables = Exact<{
  page: Scalars['Int'];
  storeIds: Array<Scalars['ID']> | Scalars['ID'];
  actions: Array<Scalars['String']> | Scalars['String'];
}>;


export type LogsPaginateQuery = (
  { __typename?: 'Query' }
  & { logsPaginate?: Maybe<(
    { __typename?: 'LogPaginate' }
    & Pick<LogPaginate, 'id' | 'total' | 'pageLimit'>
    & { logs?: Maybe<Array<(
      { __typename?: 'Log' }
      & Pick<Log, 'id' | 'email' | 'action' | 'createdAt'>
      & { store: (
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name'>
      ), loggable?: Maybe<(
        { __typename: 'MenuCategory' }
        & Pick<MenuCategory, 'id'>
      ) | (
        { __typename: 'MenuItem' }
        & Pick<MenuItem, 'id'>
      ) | (
        { __typename: 'MenuOption' }
        & Pick<MenuOption, 'id'>
      ) | (
        { __typename: 'MenuSection' }
        & Pick<MenuSection, 'id'>
      ) | (
        { __typename: 'OpeningHour' }
        & Pick<OpeningHour, 'id'>
      ) | (
        { __typename: 'Promo' }
        & Pick<Promo, 'id'>
      ) | (
        { __typename: 'Store' }
        & Pick<Store, 'id'>
      )> }
    )>> }
  )> }
);

export type LogActionsMetaQueryVariables = Exact<{ [key: string]: never; }>;


export type LogActionsMetaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'logActionsMeta'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & MeFragment
  )> }
);

export type MeWithCardQueryVariables = Exact<{ [key: string]: never; }>;


export type MeWithCardQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'savedCardDetails'>
    & MeFragment
  )> }
);

export type MixOrdersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  state: Array<OrderState> | OrderState;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  integrations?: Maybe<Array<Integration> | Integration>;
}>;


export type MixOrdersQuery = (
  { __typename?: 'Query' }
  & { mixOrders?: Maybe<Array<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'orderCode' | 'integration' | 'state' | 'acceptedTime' | 'brandNames'>
    & { storeOrders?: Maybe<Array<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'cabinetSlotNumber'>
    )>>, store: (
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name'>
    ) }
  )>> }
);

export type OutletsQueryVariables = Exact<{ [key: string]: never; }>;


export type OutletsQuery = (
  { __typename?: 'Query' }
  & { outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )>> }
);

export type PosMenuSetupQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type PosMenuSetupQuery = (
  { __typename?: 'Query' }
  & { store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'country'>
    & { menuCategories?: Maybe<Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id' | 'name' | 'availableStatus' | 'posItemPositions'>
      & { menuItems?: Maybe<Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'id' | 'image' | 'name' | 'availableStatus' | 'stockEnabled' | 'price'>
      )>> }
    )>> }
  )> }
);

export type PromosQueryVariables = Exact<{
  storeId: Scalars['ID'];
}>;


export type PromosQuery = (
  { __typename?: 'Query' }
  & { store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'country'>
    & { promos?: Maybe<Array<(
      { __typename?: 'Promo' }
      & Pick<Promo, 'id' | 'active' | 'name' | 'promoVariant' | 'amount' | 'validFrom' | 'validUntil' | 'startTime' | 'endTime' | 'days'>
    )>> }
  )> }
);

export type RatingsBreakdownQueryVariables = Exact<{ [key: string]: never; }>;


export type RatingsBreakdownQuery = (
  { __typename?: 'Query' }
  & { ratingsBreakdown?: Maybe<Array<(
    { __typename?: 'RatingBreakdown' }
    & Pick<RatingBreakdown, 'id' | 'starCount' | 'value'>
  )>> }
);

export type RatingsQueryVariables = Exact<{ [key: string]: never; }>;


export type RatingsQuery = (
  { __typename?: 'Query' }
  & { ratings?: Maybe<Array<(
    { __typename?: 'Rating' }
    & Pick<Rating, 'id' | 'rate' | 'comment' | 'createdAt'>
    & { storeMixOrder: (
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id'>
      & { consumer?: Maybe<(
        { __typename?: 'Consumer' }
        & Pick<Consumer, 'id' | 'name'>
      )>, store: (
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name'>
      ), orderMenuItems: Array<(
        { __typename?: 'OrderMenuItems' }
        & Pick<OrderMenuItems, 'id' | 'name'>
      )> }
    ) }
  )>> }
);

export type HeatMapAnalyticQueryVariables = Exact<{
  storeId: Scalars['ID'];
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type HeatMapAnalyticQuery = (
  { __typename?: 'Query' }
  & { heatMapAnalytic?: Maybe<(
    { __typename?: 'HeatMapAnalytic' }
    & Pick<HeatMapAnalytic, 'id' | 'days' | 'hours' | 'values'>
  )> }
);

export type MerchantSalesQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration> | Integration>;
  states?: Maybe<Array<OrderState> | OrderState>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  page: Scalars['Int'];
}>;


export type MerchantSalesQuery = (
  { __typename?: 'Query' }
  & { merchantSales?: Maybe<(
    { __typename?: 'MerchantSales' }
    & Pick<MerchantSales, 'id' | 'pageLimit'>
    & { sales: Array<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'mixOrderId' | 'state' | 'orderCode' | 'orderId' | 'integration' | 'paymentMethod' | 'createdAt' | 'discountCode' | 'originalPrice' | 'isFirstMobileOrder' | 'platformFundedPromo' | 'merchantFundedPromo' | 'counterFeePercentage' | 'platformFeePercentage' | 'revenueSharePercentage' | 'revenue' | 'tax' | 'externalFee' | 'counterFee' | 'platformFee' | 'revenueShare' | 'netIncome' | 'consumerName' | 'consumerPhone'>
      & { store: (
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name' | 'country'>
        & { restaurant: (
          { __typename?: 'Restaurant' }
          & Pick<Restaurant, 'id' | 'name' | 'number'>
        ) }
      ) }
    )> }
  )> }
);

export type MerchantSalesTotalQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration> | Integration>;
  states?: Maybe<Array<OrderState> | OrderState>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type MerchantSalesTotalQuery = (
  { __typename?: 'Query' }
  & { merchantSalesTotal?: Maybe<(
    { __typename?: 'MerchantSalesTotal' }
    & Pick<MerchantSalesTotal, 'id' | 'totalCount' | 'totalOriginalPrice' | 'totalPlatformFundedPromo' | 'totalMerchantFundedPromo' | 'totalRevenue' | 'totalTax' | 'totalExternalFee' | 'totalCounterFee' | 'totalPlatformFee' | 'totalRevenueShare' | 'totalNetIncome'>
  )> }
);

export type SettlementsQueryVariables = Exact<{
  restaurantIds: Array<Scalars['ID']> | Scalars['ID'];
  dateRanges: Array<DateRanges> | DateRanges;
}>;


export type SettlementsQuery = (
  { __typename?: 'Query' }
  & { settlements?: Maybe<Array<(
    { __typename?: 'Settlement' }
    & Pick<Settlement, 'id' | 'pdf' | 'createdAt'>
    & { restaurant: (
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number'>
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id' | 'name'>
      ) }
    ) }
  )>> }
);

export type SettlementsDownloadQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SettlementsDownloadQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'settlementsDownload'>
);

export type SiblingStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type SiblingStoresQuery = (
  { __typename?: 'Query' }
  & { siblingStores?: Maybe<Array<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name'>
  )>> }
);

export type StoresQueryVariables = Exact<{
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
  shouldSort?: Maybe<Scalars['Boolean']>;
}>;


export type StoresQuery = (
  { __typename?: 'Query' }
  & { stores?: Maybe<Array<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'paused' | 'pauseTime'>
  )>> }
);

export type StoreQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StoreQuery = (
  { __typename?: 'Query' }
  & { store?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'paused' | 'grabfoodStoreId' | 'foodpandaChainId' | 'shopeefoodStoreId'>
  )> }
);

export type StoreAnalyticQueryVariables = Exact<{
  storeIds: Array<Scalars['ID']> | Scalars['ID'];
  integrations: Array<Integration> | Integration;
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type StoreAnalyticQuery = (
  { __typename?: 'Query' }
  & { storesAnalytic?: Maybe<(
    { __typename?: 'StoreAnalytic' }
    & Pick<StoreAnalytic, 'id' | 'sales' | 'ordersCount'>
    & { salesByBrand?: Maybe<Array<(
      { __typename?: 'SalesByBrand' }
      & Pick<SalesByBrand, 'id' | 'brand' | 'value' | 'color'>
    )>>, salesByDeliveryOption?: Maybe<Array<(
      { __typename?: 'SalesByDeliveryOption' }
      & Pick<SalesByDeliveryOption, 'id' | 'deliveryOption' | 'value' | 'color'>
    )>>, dailySales?: Maybe<Array<(
      { __typename?: 'DailySales' }
      & Pick<DailySales, 'id' | 'time' | 'value'>
    )>>, dailySoldItems?: Maybe<Array<(
      { __typename?: 'DailySoldItems' }
      & Pick<DailySoldItems, 'id' | 'name' | 'count'>
    )>> }
  )> }
);

export type SupplierItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplierItemCategoriesQuery = (
  { __typename?: 'Query' }
  & { supplierItemCategories?: Maybe<Array<(
    { __typename?: 'SupplierItemCategory' }
    & Pick<SupplierItemCategory, 'id' | 'name'>
  )>> }
);

export type SuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type SuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers?: Maybe<Array<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>> }
);

export type SupplierItemsQueryVariables = Exact<{
  supplierItemCategoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  supplierIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type SupplierItemsQuery = (
  { __typename?: 'Query' }
  & { supplierItems?: Maybe<Array<(
    { __typename?: 'SupplierItem' }
    & Pick<SupplierItem, 'id' | 'image' | 'sku' | 'name' | 'uom' | 'price' | 'availableStatus' | 'stockEnabled' | 'stockCount' | 'deliveryDays'>
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    ) }
  )>> }
);

export type SupplierDestinationsQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplierDestinationsQuery = (
  { __typename?: 'Query' }
  & { outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'address'>
  )>> }
);

export type SupplierPurchaseOrdersQueryVariables = Exact<{
  states: Array<SupplierPurchaseOrderRestaurantUserState> | SupplierPurchaseOrderRestaurantUserState;
}>;


export type SupplierPurchaseOrdersQuery = (
  { __typename?: 'Query' }
  & { supplierPurchaseOrders?: Maybe<Array<(
    { __typename?: 'SupplierPurchaseOrder' }
    & SupplierPurchaseOrdersFragment
  )>> }
);

export type SupplierPurchaseOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SupplierPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { supplierPurchaseOrder?: Maybe<(
    { __typename?: 'SupplierPurchaseOrder' }
    & SupplierPurchaseOrderFragment
  )> }
);

export type SupplyBnplQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplyBnplQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bnpl'>
);

export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
  metadata
}
    `;
export const IntegrationStatusFragmentFragmentDoc = gql`
    fragment IntegrationStatusFragment on IntegrationStatus {
  id
  storePauseStatus {
    id
    paused
    changeable
    response
    reasonType
  }
  menuSyncStatus {
    id
    menuLastSyncAt
    success
    message
  }
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on RestaurantUser {
  id
  role
  email
  isCashier
}
    `;
export const SupplierPurchaseOrdersFragmentDoc = gql`
    fragment SupplierPurchaseOrders on SupplierPurchaseOrder {
  id
  orderCode
  createdAt
  supplier {
    id
    name
  }
  updatedAt
  state
  price
}
    `;
export const SupplierPurchaseOrderFragmentDoc = gql`
    fragment SupplierPurchaseOrder on SupplierPurchaseOrder {
  id
  orderCode
  createdAt
  invoicedAt
  paymentProvedAt
  price
  state
  financingState
  deliverTo
  rejectReason
  restaurantUser {
    id
    email
  }
  supplier {
    id
    name
    email
    phoneNumber
  }
  report
  invoice
  paymentProof
}
    `;
export const TimesheetFragmentDoc = gql`
    fragment Timesheet on Timesheet {
  id
  clockInAt
  clockOutAt
  clockHours
  sales {
    id
    paymentMethod
    sales
    discount
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!, $registrationId: String) {
  login(
    input: {email: $email, password: $password, registrationId: $registrationId}
  ) {
    jwt
    me {
      id
      email
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      registrationId: // value for 'registrationId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CancelDeliveryDocument = gql`
    mutation CancelDelivery($mixOrderId: ID!) {
  cancelDelivery(mixOrderId: $mixOrderId) {
    deliveryBooking {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CancelDeliveryMutationFn = Apollo.MutationFunction<CancelDeliveryMutation, CancelDeliveryMutationVariables>;

/**
 * __useCancelDeliveryMutation__
 *
 * To run a mutation, you first call `useCancelDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeliveryMutation, { data, loading, error }] = useCancelDeliveryMutation({
 *   variables: {
 *      mixOrderId: // value for 'mixOrderId'
 *   },
 * });
 */
export function useCancelDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CancelDeliveryMutation, CancelDeliveryMutationVariables>) {
        return Apollo.useMutation<CancelDeliveryMutation, CancelDeliveryMutationVariables>(CancelDeliveryDocument, baseOptions);
      }
export type CancelDeliveryMutationHookResult = ReturnType<typeof useCancelDeliveryMutation>;
export type CancelDeliveryMutationResult = Apollo.MutationResult<CancelDeliveryMutation>;
export type CancelDeliveryMutationOptions = Apollo.BaseMutationOptions<CancelDeliveryMutation, CancelDeliveryMutationVariables>;
export const UpdateGcmTokenDocument = gql`
    mutation UpdateGcmToken($idToDelete: String, $idToAdd: String) {
  updateGcmToken(input: {idToDelete: $idToDelete, idToAdd: $idToAdd}) {
    me {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateGcmTokenMutationFn = Apollo.MutationFunction<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>;

/**
 * __useUpdateGcmTokenMutation__
 *
 * To run a mutation, you first call `useUpdateGcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGcmTokenMutation, { data, loading, error }] = useUpdateGcmTokenMutation({
 *   variables: {
 *      idToDelete: // value for 'idToDelete'
 *      idToAdd: // value for 'idToAdd'
 *   },
 * });
 */
export function useUpdateGcmTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>) {
        return Apollo.useMutation<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>(UpdateGcmTokenDocument, baseOptions);
      }
export type UpdateGcmTokenMutationHookResult = ReturnType<typeof useUpdateGcmTokenMutation>;
export type UpdateGcmTokenMutationResult = Apollo.MutationResult<UpdateGcmTokenMutation>;
export type UpdateGcmTokenMutationOptions = Apollo.BaseMutationOptions<UpdateGcmTokenMutation, UpdateGcmTokenMutationVariables>;
export const BulkAddDocument = gql`
    mutation BulkAdd($file: File!, $storeId: ID!) {
  bulkAdd(file: $file, storeId: $storeId) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type BulkAddMutationFn = Apollo.MutationFunction<BulkAddMutation, BulkAddMutationVariables>;

/**
 * __useBulkAddMutation__
 *
 * To run a mutation, you first call `useBulkAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddMutation, { data, loading, error }] = useBulkAddMutation({
 *   variables: {
 *      file: // value for 'file'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useBulkAddMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddMutation, BulkAddMutationVariables>) {
        return Apollo.useMutation<BulkAddMutation, BulkAddMutationVariables>(BulkAddDocument, baseOptions);
      }
export type BulkAddMutationHookResult = ReturnType<typeof useBulkAddMutation>;
export type BulkAddMutationResult = Apollo.MutationResult<BulkAddMutation>;
export type BulkAddMutationOptions = Apollo.BaseMutationOptions<BulkAddMutation, BulkAddMutationVariables>;
export const BulkEditDocument = gql`
    mutation BulkEdit($file: File!, $storeId: ID!) {
  bulkEdit(file: $file, storeId: $storeId) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type BulkEditMutationFn = Apollo.MutationFunction<BulkEditMutation, BulkEditMutationVariables>;

/**
 * __useBulkEditMutation__
 *
 * To run a mutation, you first call `useBulkEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditMutation, { data, loading, error }] = useBulkEditMutation({
 *   variables: {
 *      file: // value for 'file'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useBulkEditMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditMutation, BulkEditMutationVariables>) {
        return Apollo.useMutation<BulkEditMutation, BulkEditMutationVariables>(BulkEditDocument, baseOptions);
      }
export type BulkEditMutationHookResult = ReturnType<typeof useBulkEditMutation>;
export type BulkEditMutationResult = Apollo.MutationResult<BulkEditMutation>;
export type BulkEditMutationOptions = Apollo.BaseMutationOptions<BulkEditMutation, BulkEditMutationVariables>;
export const CreateMenuCategoryDocument = gql`
    mutation CreateMenuCategory($storeId: ID!, $menuSectionId: ID!, $name: String!, $availableStatus: AvailableStatus!, $sequence: Int) {
  createMenuCategory(
    input: {storeId: $storeId, menuSectionId: $menuSectionId, name: $name, availableStatus: $availableStatus, sequence: $sequence}
  ) {
    menuCategory {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMenuCategoryMutationFn = Apollo.MutationFunction<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>;

/**
 * __useCreateMenuCategoryMutation__
 *
 * To run a mutation, you first call `useCreateMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuCategoryMutation, { data, loading, error }] = useCreateMenuCategoryMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      menuSectionId: // value for 'menuSectionId'
 *      name: // value for 'name'
 *      availableStatus: // value for 'availableStatus'
 *      sequence: // value for 'sequence'
 *   },
 * });
 */
export function useCreateMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>) {
        return Apollo.useMutation<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>(CreateMenuCategoryDocument, baseOptions);
      }
export type CreateMenuCategoryMutationHookResult = ReturnType<typeof useCreateMenuCategoryMutation>;
export type CreateMenuCategoryMutationResult = Apollo.MutationResult<CreateMenuCategoryMutation>;
export type CreateMenuCategoryMutationOptions = Apollo.BaseMutationOptions<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>;
export const RemoveMenuCategoryDocument = gql`
    mutation RemoveMenuCategory($id: ID!) {
  removeMenuCategory(id: $id) {
    menuCategory {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuCategoryMutationFn = Apollo.MutationFunction<RemoveMenuCategoryMutation, RemoveMenuCategoryMutationVariables>;

/**
 * __useRemoveMenuCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuCategoryMutation, { data, loading, error }] = useRemoveMenuCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuCategoryMutation, RemoveMenuCategoryMutationVariables>) {
        return Apollo.useMutation<RemoveMenuCategoryMutation, RemoveMenuCategoryMutationVariables>(RemoveMenuCategoryDocument, baseOptions);
      }
export type RemoveMenuCategoryMutationHookResult = ReturnType<typeof useRemoveMenuCategoryMutation>;
export type RemoveMenuCategoryMutationResult = Apollo.MutationResult<RemoveMenuCategoryMutation>;
export type RemoveMenuCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveMenuCategoryMutation, RemoveMenuCategoryMutationVariables>;
export const RemoveMenuCategoryAllDocument = gql`
    mutation RemoveMenuCategoryAll($ids: [ID!]!) {
  removeMenuCategoryAll(ids: $ids) {
    menuCategories {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuCategoryAllMutationFn = Apollo.MutationFunction<RemoveMenuCategoryAllMutation, RemoveMenuCategoryAllMutationVariables>;

/**
 * __useRemoveMenuCategoryAllMutation__
 *
 * To run a mutation, you first call `useRemoveMenuCategoryAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuCategoryAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuCategoryAllMutation, { data, loading, error }] = useRemoveMenuCategoryAllMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveMenuCategoryAllMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuCategoryAllMutation, RemoveMenuCategoryAllMutationVariables>) {
        return Apollo.useMutation<RemoveMenuCategoryAllMutation, RemoveMenuCategoryAllMutationVariables>(RemoveMenuCategoryAllDocument, baseOptions);
      }
export type RemoveMenuCategoryAllMutationHookResult = ReturnType<typeof useRemoveMenuCategoryAllMutation>;
export type RemoveMenuCategoryAllMutationResult = Apollo.MutationResult<RemoveMenuCategoryAllMutation>;
export type RemoveMenuCategoryAllMutationOptions = Apollo.BaseMutationOptions<RemoveMenuCategoryAllMutation, RemoveMenuCategoryAllMutationVariables>;
export const UpdateMenuCategoryDocument = gql`
    mutation UpdateMenuCategory($id: ID!, $menuSectionId: ID, $name: String, $availableStatus: AvailableStatus, $sequence: Int) {
  updateMenuCategory(
    id: $id
    input: {name: $name, availableStatus: $availableStatus, sequence: $sequence, menuSectionId: $menuSectionId}
  ) {
    menuCategory {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMenuCategoryMutationFn = Apollo.MutationFunction<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>;

/**
 * __useUpdateMenuCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuCategoryMutation, { data, loading, error }] = useUpdateMenuCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      menuSectionId: // value for 'menuSectionId'
 *      name: // value for 'name'
 *      availableStatus: // value for 'availableStatus'
 *      sequence: // value for 'sequence'
 *   },
 * });
 */
export function useUpdateMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>) {
        return Apollo.useMutation<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>(UpdateMenuCategoryDocument, baseOptions);
      }
export type UpdateMenuCategoryMutationHookResult = ReturnType<typeof useUpdateMenuCategoryMutation>;
export type UpdateMenuCategoryMutationResult = Apollo.MutationResult<UpdateMenuCategoryMutation>;
export type UpdateMenuCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>;
export const UpdatePosItemPositionsDocument = gql`
    mutation UpdatePosItemPositions($id: ID!, $posItemPositions: [ID!]!) {
  updatePosItemPositions(id: $id, posItemPositions: $posItemPositions) {
    menuCategory {
      id
      posItemPositions
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdatePosItemPositionsMutationFn = Apollo.MutationFunction<UpdatePosItemPositionsMutation, UpdatePosItemPositionsMutationVariables>;

/**
 * __useUpdatePosItemPositionsMutation__
 *
 * To run a mutation, you first call `useUpdatePosItemPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePosItemPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePosItemPositionsMutation, { data, loading, error }] = useUpdatePosItemPositionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      posItemPositions: // value for 'posItemPositions'
 *   },
 * });
 */
export function useUpdatePosItemPositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePosItemPositionsMutation, UpdatePosItemPositionsMutationVariables>) {
        return Apollo.useMutation<UpdatePosItemPositionsMutation, UpdatePosItemPositionsMutationVariables>(UpdatePosItemPositionsDocument, baseOptions);
      }
export type UpdatePosItemPositionsMutationHookResult = ReturnType<typeof useUpdatePosItemPositionsMutation>;
export type UpdatePosItemPositionsMutationResult = Apollo.MutationResult<UpdatePosItemPositionsMutation>;
export type UpdatePosItemPositionsMutationOptions = Apollo.BaseMutationOptions<UpdatePosItemPositionsMutation, UpdatePosItemPositionsMutationVariables>;
export const CreateMenuItemDocument = gql`
    mutation CreateMenuItem($storeId: ID!, $name: String!, $availableStatus: AvailableStatus!, $price: Float!, $description: String, $sequence: Int, $stockEnabled: Boolean!, $stock: Int, $image: File, $menuCategoryIds: [ID!]) {
  createMenuItem(
    input: {storeId: $storeId, name: $name, availableStatus: $availableStatus, price: $price, description: $description, sequence: $sequence, stockEnabled: $stockEnabled, stock: $stock, image: $image, menuCategoryIds: $menuCategoryIds}
  ) {
    menuItem {
      id
      name
      description
      price
      image
      stockEnabled
      stock
      menuCategories {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMenuItemMutationFn = Apollo.MutationFunction<CreateMenuItemMutation, CreateMenuItemMutationVariables>;

/**
 * __useCreateMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemMutation, { data, loading, error }] = useCreateMenuItemMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      availableStatus: // value for 'availableStatus'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *      sequence: // value for 'sequence'
 *      stockEnabled: // value for 'stockEnabled'
 *      stock: // value for 'stock'
 *      image: // value for 'image'
 *      menuCategoryIds: // value for 'menuCategoryIds'
 *   },
 * });
 */
export function useCreateMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>) {
        return Apollo.useMutation<CreateMenuItemMutation, CreateMenuItemMutationVariables>(CreateMenuItemDocument, baseOptions);
      }
export type CreateMenuItemMutationHookResult = ReturnType<typeof useCreateMenuItemMutation>;
export type CreateMenuItemMutationResult = Apollo.MutationResult<CreateMenuItemMutation>;
export type CreateMenuItemMutationOptions = Apollo.BaseMutationOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>;
export const RemoveMenuItemDocument = gql`
    mutation RemoveMenuItem($id: ID!) {
  removeMenuItem(id: $id) {
    menuItem {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuItemMutationFn = Apollo.MutationFunction<RemoveMenuItemMutation, RemoveMenuItemMutationVariables>;

/**
 * __useRemoveMenuItemMutation__
 *
 * To run a mutation, you first call `useRemoveMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuItemMutation, { data, loading, error }] = useRemoveMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuItemMutation, RemoveMenuItemMutationVariables>) {
        return Apollo.useMutation<RemoveMenuItemMutation, RemoveMenuItemMutationVariables>(RemoveMenuItemDocument, baseOptions);
      }
export type RemoveMenuItemMutationHookResult = ReturnType<typeof useRemoveMenuItemMutation>;
export type RemoveMenuItemMutationResult = Apollo.MutationResult<RemoveMenuItemMutation>;
export type RemoveMenuItemMutationOptions = Apollo.BaseMutationOptions<RemoveMenuItemMutation, RemoveMenuItemMutationVariables>;
export const RemoveMenuItemAllDocument = gql`
    mutation RemoveMenuItemAll($ids: [ID!]!) {
  removeMenuItemAll(ids: $ids) {
    menuItems {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuItemAllMutationFn = Apollo.MutationFunction<RemoveMenuItemAllMutation, RemoveMenuItemAllMutationVariables>;

/**
 * __useRemoveMenuItemAllMutation__
 *
 * To run a mutation, you first call `useRemoveMenuItemAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuItemAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuItemAllMutation, { data, loading, error }] = useRemoveMenuItemAllMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveMenuItemAllMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuItemAllMutation, RemoveMenuItemAllMutationVariables>) {
        return Apollo.useMutation<RemoveMenuItemAllMutation, RemoveMenuItemAllMutationVariables>(RemoveMenuItemAllDocument, baseOptions);
      }
export type RemoveMenuItemAllMutationHookResult = ReturnType<typeof useRemoveMenuItemAllMutation>;
export type RemoveMenuItemAllMutationResult = Apollo.MutationResult<RemoveMenuItemAllMutation>;
export type RemoveMenuItemAllMutationOptions = Apollo.BaseMutationOptions<RemoveMenuItemAllMutation, RemoveMenuItemAllMutationVariables>;
export const UpdateMenuItemDocument = gql`
    mutation UpdateMenuItem($id: ID!, $name: String, $availableStatus: AvailableStatus, $price: Float, $description: String, $sequence: Int, $stockEnabled: Boolean!, $stock: Int, $image: File, $menuCategoryIds: [ID!]) {
  updateMenuItem(
    id: $id
    input: {name: $name, availableStatus: $availableStatus, price: $price, description: $description, sequence: $sequence, stockEnabled: $stockEnabled, stock: $stock, image: $image, menuCategoryIds: $menuCategoryIds}
  ) {
    menuItem {
      id
      name
      description
      price
      image
      stockEnabled
      stock
      menuCategories {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMenuItemMutationFn = Apollo.MutationFunction<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;

/**
 * __useUpdateMenuItemMutation__
 *
 * To run a mutation, you first call `useUpdateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuItemMutation, { data, loading, error }] = useUpdateMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      availableStatus: // value for 'availableStatus'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *      sequence: // value for 'sequence'
 *      stockEnabled: // value for 'stockEnabled'
 *      stock: // value for 'stock'
 *      image: // value for 'image'
 *      menuCategoryIds: // value for 'menuCategoryIds'
 *   },
 * });
 */
export function useUpdateMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>) {
        return Apollo.useMutation<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>(UpdateMenuItemDocument, baseOptions);
      }
export type UpdateMenuItemMutationHookResult = ReturnType<typeof useUpdateMenuItemMutation>;
export type UpdateMenuItemMutationResult = Apollo.MutationResult<UpdateMenuItemMutation>;
export type UpdateMenuItemMutationOptions = Apollo.BaseMutationOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;
export const CreateMenuOptionDocument = gql`
    mutation CreateMenuOption($storeId: ID!, $name: String!, $availableStatus: AvailableStatus!, $sequence: Int, $min: Int!, $max: Int!, $itemIds: [ID!]!, $itemOptionIds: [ID!]!) {
  createMenuOption(
    input: {storeId: $storeId, name: $name, availableStatus: $availableStatus, sequence: $sequence, min: $min, max: $max, itemIds: $itemIds, itemOptionIds: $itemOptionIds}
  ) {
    menuOption {
      id
      name
      min
      max
      items {
        id
        name
        price
      }
      itemOptions {
        id
        name
        price
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMenuOptionMutationFn = Apollo.MutationFunction<CreateMenuOptionMutation, CreateMenuOptionMutationVariables>;

/**
 * __useCreateMenuOptionMutation__
 *
 * To run a mutation, you first call `useCreateMenuOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuOptionMutation, { data, loading, error }] = useCreateMenuOptionMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      availableStatus: // value for 'availableStatus'
 *      sequence: // value for 'sequence'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      itemIds: // value for 'itemIds'
 *      itemOptionIds: // value for 'itemOptionIds'
 *   },
 * });
 */
export function useCreateMenuOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuOptionMutation, CreateMenuOptionMutationVariables>) {
        return Apollo.useMutation<CreateMenuOptionMutation, CreateMenuOptionMutationVariables>(CreateMenuOptionDocument, baseOptions);
      }
export type CreateMenuOptionMutationHookResult = ReturnType<typeof useCreateMenuOptionMutation>;
export type CreateMenuOptionMutationResult = Apollo.MutationResult<CreateMenuOptionMutation>;
export type CreateMenuOptionMutationOptions = Apollo.BaseMutationOptions<CreateMenuOptionMutation, CreateMenuOptionMutationVariables>;
export const RemoveMenuOptionDocument = gql`
    mutation RemoveMenuOption($id: ID!) {
  removeMenuOption(id: $id) {
    menuOption {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuOptionMutationFn = Apollo.MutationFunction<RemoveMenuOptionMutation, RemoveMenuOptionMutationVariables>;

/**
 * __useRemoveMenuOptionMutation__
 *
 * To run a mutation, you first call `useRemoveMenuOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuOptionMutation, { data, loading, error }] = useRemoveMenuOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMenuOptionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuOptionMutation, RemoveMenuOptionMutationVariables>) {
        return Apollo.useMutation<RemoveMenuOptionMutation, RemoveMenuOptionMutationVariables>(RemoveMenuOptionDocument, baseOptions);
      }
export type RemoveMenuOptionMutationHookResult = ReturnType<typeof useRemoveMenuOptionMutation>;
export type RemoveMenuOptionMutationResult = Apollo.MutationResult<RemoveMenuOptionMutation>;
export type RemoveMenuOptionMutationOptions = Apollo.BaseMutationOptions<RemoveMenuOptionMutation, RemoveMenuOptionMutationVariables>;
export const RemoveMenuOptionAllDocument = gql`
    mutation RemoveMenuOptionAll($ids: [ID!]!) {
  removeMenuOptionAll(ids: $ids) {
    menuOptions {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuOptionAllMutationFn = Apollo.MutationFunction<RemoveMenuOptionAllMutation, RemoveMenuOptionAllMutationVariables>;

/**
 * __useRemoveMenuOptionAllMutation__
 *
 * To run a mutation, you first call `useRemoveMenuOptionAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuOptionAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuOptionAllMutation, { data, loading, error }] = useRemoveMenuOptionAllMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveMenuOptionAllMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuOptionAllMutation, RemoveMenuOptionAllMutationVariables>) {
        return Apollo.useMutation<RemoveMenuOptionAllMutation, RemoveMenuOptionAllMutationVariables>(RemoveMenuOptionAllDocument, baseOptions);
      }
export type RemoveMenuOptionAllMutationHookResult = ReturnType<typeof useRemoveMenuOptionAllMutation>;
export type RemoveMenuOptionAllMutationResult = Apollo.MutationResult<RemoveMenuOptionAllMutation>;
export type RemoveMenuOptionAllMutationOptions = Apollo.BaseMutationOptions<RemoveMenuOptionAllMutation, RemoveMenuOptionAllMutationVariables>;
export const UpdateMenuOptionDocument = gql`
    mutation UpdateMenuOption($id: ID!, $name: String, $availableStatus: AvailableStatus, $sequence: Int, $min: Int, $max: Int, $itemIds: [ID!], $itemOptionIds: [ID!]) {
  updateMenuOption(
    id: $id
    input: {name: $name, availableStatus: $availableStatus, sequence: $sequence, min: $min, max: $max, itemIds: $itemIds, itemOptionIds: $itemOptionIds}
  ) {
    menuOption {
      id
      name
      min
      max
      items {
        id
        name
        price
      }
      itemOptions {
        id
        name
        price
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMenuOptionMutationFn = Apollo.MutationFunction<UpdateMenuOptionMutation, UpdateMenuOptionMutationVariables>;

/**
 * __useUpdateMenuOptionMutation__
 *
 * To run a mutation, you first call `useUpdateMenuOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuOptionMutation, { data, loading, error }] = useUpdateMenuOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      availableStatus: // value for 'availableStatus'
 *      sequence: // value for 'sequence'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      itemIds: // value for 'itemIds'
 *      itemOptionIds: // value for 'itemOptionIds'
 *   },
 * });
 */
export function useUpdateMenuOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuOptionMutation, UpdateMenuOptionMutationVariables>) {
        return Apollo.useMutation<UpdateMenuOptionMutation, UpdateMenuOptionMutationVariables>(UpdateMenuOptionDocument, baseOptions);
      }
export type UpdateMenuOptionMutationHookResult = ReturnType<typeof useUpdateMenuOptionMutation>;
export type UpdateMenuOptionMutationResult = Apollo.MutationResult<UpdateMenuOptionMutation>;
export type UpdateMenuOptionMutationOptions = Apollo.BaseMutationOptions<UpdateMenuOptionMutation, UpdateMenuOptionMutationVariables>;
export const CreateMenuSectionDocument = gql`
    mutation CreateMenuSection($storeId: ID!, $name: String!, $sequence: Int) {
  createMenuSection(input: {storeId: $storeId, name: $name, sequence: $sequence}) {
    menuSection {
      id
      name
      sequence
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMenuSectionMutationFn = Apollo.MutationFunction<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>;

/**
 * __useCreateMenuSectionMutation__
 *
 * To run a mutation, you first call `useCreateMenuSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuSectionMutation, { data, loading, error }] = useCreateMenuSectionMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      sequence: // value for 'sequence'
 *   },
 * });
 */
export function useCreateMenuSectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>) {
        return Apollo.useMutation<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>(CreateMenuSectionDocument, baseOptions);
      }
export type CreateMenuSectionMutationHookResult = ReturnType<typeof useCreateMenuSectionMutation>;
export type CreateMenuSectionMutationResult = Apollo.MutationResult<CreateMenuSectionMutation>;
export type CreateMenuSectionMutationOptions = Apollo.BaseMutationOptions<CreateMenuSectionMutation, CreateMenuSectionMutationVariables>;
export const RemoveMenuSectionDocument = gql`
    mutation RemoveMenuSection($id: ID!) {
  removeMenuSection(id: $id) {
    menuSection {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMenuSectionMutationFn = Apollo.MutationFunction<RemoveMenuSectionMutation, RemoveMenuSectionMutationVariables>;

/**
 * __useRemoveMenuSectionMutation__
 *
 * To run a mutation, you first call `useRemoveMenuSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuSectionMutation, { data, loading, error }] = useRemoveMenuSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMenuSectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMenuSectionMutation, RemoveMenuSectionMutationVariables>) {
        return Apollo.useMutation<RemoveMenuSectionMutation, RemoveMenuSectionMutationVariables>(RemoveMenuSectionDocument, baseOptions);
      }
export type RemoveMenuSectionMutationHookResult = ReturnType<typeof useRemoveMenuSectionMutation>;
export type RemoveMenuSectionMutationResult = Apollo.MutationResult<RemoveMenuSectionMutation>;
export type RemoveMenuSectionMutationOptions = Apollo.BaseMutationOptions<RemoveMenuSectionMutation, RemoveMenuSectionMutationVariables>;
export const UpdateMenuSectionDocument = gql`
    mutation UpdateMenuSection($id: ID!, $name: String, $sequence: Int) {
  updateMenuSection(id: $id, input: {name: $name, sequence: $sequence}) {
    menuSection {
      id
      name
      sequence
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMenuSectionMutationFn = Apollo.MutationFunction<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>;

/**
 * __useUpdateMenuSectionMutation__
 *
 * To run a mutation, you first call `useUpdateMenuSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuSectionMutation, { data, loading, error }] = useUpdateMenuSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      sequence: // value for 'sequence'
 *   },
 * });
 */
export function useUpdateMenuSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>) {
        return Apollo.useMutation<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>(UpdateMenuSectionDocument, baseOptions);
      }
export type UpdateMenuSectionMutationHookResult = ReturnType<typeof useUpdateMenuSectionMutation>;
export type UpdateMenuSectionMutationResult = Apollo.MutationResult<UpdateMenuSectionMutation>;
export type UpdateMenuSectionMutationOptions = Apollo.BaseMutationOptions<UpdateMenuSectionMutation, UpdateMenuSectionMutationVariables>;
export const UpdateOpeningHourDocument = gql`
    mutation UpdateOpeningHour($menuSectionId: ID!, $openingHours: [OpeningHourInput!]!) {
  updateOpeningHour(
    menuSectionId: $menuSectionId
    input: {openingHours: $openingHours}
  ) {
    menuSection {
      id
      openingHours {
        id
        day
        open
        close
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateOpeningHourMutationFn = Apollo.MutationFunction<UpdateOpeningHourMutation, UpdateOpeningHourMutationVariables>;

/**
 * __useUpdateOpeningHourMutation__
 *
 * To run a mutation, you first call `useUpdateOpeningHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpeningHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpeningHourMutation, { data, loading, error }] = useUpdateOpeningHourMutation({
 *   variables: {
 *      menuSectionId: // value for 'menuSectionId'
 *      openingHours: // value for 'openingHours'
 *   },
 * });
 */
export function useUpdateOpeningHourMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpeningHourMutation, UpdateOpeningHourMutationVariables>) {
        return Apollo.useMutation<UpdateOpeningHourMutation, UpdateOpeningHourMutationVariables>(UpdateOpeningHourDocument, baseOptions);
      }
export type UpdateOpeningHourMutationHookResult = ReturnType<typeof useUpdateOpeningHourMutation>;
export type UpdateOpeningHourMutationResult = Apollo.MutationResult<UpdateOpeningHourMutation>;
export type UpdateOpeningHourMutationOptions = Apollo.BaseMutationOptions<UpdateOpeningHourMutation, UpdateOpeningHourMutationVariables>;
export const EmailResetPasswordUrlDocument = gql`
    mutation EmailResetPasswordUrl($email: String!) {
  emailResetPasswordUrl(email: $email) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EmailResetPasswordUrlMutationFn = Apollo.MutationFunction<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>;

/**
 * __useEmailResetPasswordUrlMutation__
 *
 * To run a mutation, you first call `useEmailResetPasswordUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailResetPasswordUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailResetPasswordUrlMutation, { data, loading, error }] = useEmailResetPasswordUrlMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailResetPasswordUrlMutation(baseOptions?: Apollo.MutationHookOptions<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>) {
        return Apollo.useMutation<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>(EmailResetPasswordUrlDocument, baseOptions);
      }
export type EmailResetPasswordUrlMutationHookResult = ReturnType<typeof useEmailResetPasswordUrlMutation>;
export type EmailResetPasswordUrlMutationResult = Apollo.MutationResult<EmailResetPasswordUrlMutation>;
export type EmailResetPasswordUrlMutationOptions = Apollo.BaseMutationOptions<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    input: {token: $token, password: $password, passwordConfirmation: $passwordConfirmation}
  ) {
    user {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($email: String, $phoneNumber: String) {
  updateProfile(input: {email: $email, phoneNumber: $phoneNumber}) {
    me {
      id
      email
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const CreatePromoDocument = gql`
    mutation CreatePromo($storeId: ID!, $name: String!, $promoVariant: PromoVariant!, $amount: Float!, $validFrom: ISO8601DateTime!, $validUntil: ISO8601DateTime!, $startTime: String!, $endTime: String!, $days: [Days!]!, $menuItemIds: [ID!]) {
  createPromo(
    input: {storeId: $storeId, name: $name, promoVariant: $promoVariant, amount: $amount, validFrom: $validFrom, validUntil: $validUntil, startTime: $startTime, endTime: $endTime, days: $days, menuItemIds: $menuItemIds}
  ) {
    promo {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreatePromoMutationFn = Apollo.MutationFunction<CreatePromoMutation, CreatePromoMutationVariables>;

/**
 * __useCreatePromoMutation__
 *
 * To run a mutation, you first call `useCreatePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoMutation, { data, loading, error }] = useCreatePromoMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *      promoVariant: // value for 'promoVariant'
 *      amount: // value for 'amount'
 *      validFrom: // value for 'validFrom'
 *      validUntil: // value for 'validUntil'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      days: // value for 'days'
 *      menuItemIds: // value for 'menuItemIds'
 *   },
 * });
 */
export function useCreatePromoMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoMutation, CreatePromoMutationVariables>) {
        return Apollo.useMutation<CreatePromoMutation, CreatePromoMutationVariables>(CreatePromoDocument, baseOptions);
      }
export type CreatePromoMutationHookResult = ReturnType<typeof useCreatePromoMutation>;
export type CreatePromoMutationResult = Apollo.MutationResult<CreatePromoMutation>;
export type CreatePromoMutationOptions = Apollo.BaseMutationOptions<CreatePromoMutation, CreatePromoMutationVariables>;
export const RemovePromoDocument = gql`
    mutation RemovePromo($id: ID!) {
  removePromo(id: $id) {
    promo {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemovePromoMutationFn = Apollo.MutationFunction<RemovePromoMutation, RemovePromoMutationVariables>;

/**
 * __useRemovePromoMutation__
 *
 * To run a mutation, you first call `useRemovePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePromoMutation, { data, loading, error }] = useRemovePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePromoMutation(baseOptions?: Apollo.MutationHookOptions<RemovePromoMutation, RemovePromoMutationVariables>) {
        return Apollo.useMutation<RemovePromoMutation, RemovePromoMutationVariables>(RemovePromoDocument, baseOptions);
      }
export type RemovePromoMutationHookResult = ReturnType<typeof useRemovePromoMutation>;
export type RemovePromoMutationResult = Apollo.MutationResult<RemovePromoMutation>;
export type RemovePromoMutationOptions = Apollo.BaseMutationOptions<RemovePromoMutation, RemovePromoMutationVariables>;
export const TogglePromoDocument = gql`
    mutation TogglePromo($id: ID!) {
  togglePromo(id: $id) {
    promo {
      id
      active
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type TogglePromoMutationFn = Apollo.MutationFunction<TogglePromoMutation, TogglePromoMutationVariables>;

/**
 * __useTogglePromoMutation__
 *
 * To run a mutation, you first call `useTogglePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePromoMutation, { data, loading, error }] = useTogglePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTogglePromoMutation(baseOptions?: Apollo.MutationHookOptions<TogglePromoMutation, TogglePromoMutationVariables>) {
        return Apollo.useMutation<TogglePromoMutation, TogglePromoMutationVariables>(TogglePromoDocument, baseOptions);
      }
export type TogglePromoMutationHookResult = ReturnType<typeof useTogglePromoMutation>;
export type TogglePromoMutationResult = Apollo.MutationResult<TogglePromoMutation>;
export type TogglePromoMutationOptions = Apollo.BaseMutationOptions<TogglePromoMutation, TogglePromoMutationVariables>;
export const UpdatePromoDocument = gql`
    mutation UpdatePromo($id: ID!, $name: String, $promoVariant: PromoVariant, $amount: Float, $validFrom: ISO8601DateTime, $validUntil: ISO8601DateTime, $startTime: String, $endTime: String, $days: [Days!], $menuItemIds: [ID!]) {
  updatePromo(
    id: $id
    input: {name: $name, promoVariant: $promoVariant, amount: $amount, validFrom: $validFrom, validUntil: $validUntil, startTime: $startTime, endTime: $endTime, days: $days, menuItemIds: $menuItemIds}
  ) {
    promo {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdatePromoMutationFn = Apollo.MutationFunction<UpdatePromoMutation, UpdatePromoMutationVariables>;

/**
 * __useUpdatePromoMutation__
 *
 * To run a mutation, you first call `useUpdatePromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoMutation, { data, loading, error }] = useUpdatePromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      promoVariant: // value for 'promoVariant'
 *      amount: // value for 'amount'
 *      validFrom: // value for 'validFrom'
 *      validUntil: // value for 'validUntil'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      days: // value for 'days'
 *      menuItemIds: // value for 'menuItemIds'
 *   },
 * });
 */
export function useUpdatePromoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromoMutation, UpdatePromoMutationVariables>) {
        return Apollo.useMutation<UpdatePromoMutation, UpdatePromoMutationVariables>(UpdatePromoDocument, baseOptions);
      }
export type UpdatePromoMutationHookResult = ReturnType<typeof useUpdatePromoMutation>;
export type UpdatePromoMutationResult = Apollo.MutationResult<UpdatePromoMutation>;
export type UpdatePromoMutationOptions = Apollo.BaseMutationOptions<UpdatePromoMutation, UpdatePromoMutationVariables>;
export const CreateStoreDocument = gql`
    mutation CreateStore($restaurantId: ID!, $name: String!, $address: String!, $latitude: Float!, $longitude: Float!, $description: String, $phoneNumber: String!, $hide: Boolean, $deliveryOption: [DeliveryOption!]!, $deliveryServices: [DeliveryService!]!, $taxPercentage: Int, $counterFeePercentage: Float, $techFeePercentage: Float, $revenueSharePercentage: Float, $categoryIds: [ID!], $priceLevel: StorePriceLevel!, $image: File, $banner: File) {
  createStore(
    input: {restaurantId: $restaurantId, name: $name, description: $description, phoneNumber: $phoneNumber, hide: $hide, address: $address, latitude: $latitude, longitude: $longitude, deliveryOption: $deliveryOption, deliveryServices: $deliveryServices, taxPercentage: $taxPercentage, counterFeePercentage: $counterFeePercentage, techFeePercentage: $techFeePercentage, revenueSharePercentage: $revenueSharePercentage, categoryIds: $categoryIds, priceLevel: $priceLevel, image: $image, banner: $banner}
  ) {
    store {
      id
      name
      paused
      pauseTime
      description
      address
      longitude
      latitude
      deliveryOption
      taxPercentage
      counterFeePercentage
      techFeePercentage
      revenueSharePercentage
      priceLevel
      categories {
        id
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      description: // value for 'description'
 *      phoneNumber: // value for 'phoneNumber'
 *      hide: // value for 'hide'
 *      deliveryOption: // value for 'deliveryOption'
 *      deliveryServices: // value for 'deliveryServices'
 *      taxPercentage: // value for 'taxPercentage'
 *      counterFeePercentage: // value for 'counterFeePercentage'
 *      techFeePercentage: // value for 'techFeePercentage'
 *      revenueSharePercentage: // value for 'revenueSharePercentage'
 *      categoryIds: // value for 'categoryIds'
 *      priceLevel: // value for 'priceLevel'
 *      image: // value for 'image'
 *      banner: // value for 'banner'
 *   },
 * });
 */
export function useCreateStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>) {
        return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, baseOptions);
      }
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const DuplicateMenusDocument = gql`
    mutation DuplicateMenus($storeFromId: ID!, $storeToId: ID!) {
  duplicateMenus(storeFromId: $storeFromId, storeToId: $storeToId) {
    response {
      success
      message
    }
  }
}
    `;
export type DuplicateMenusMutationFn = Apollo.MutationFunction<DuplicateMenusMutation, DuplicateMenusMutationVariables>;

/**
 * __useDuplicateMenusMutation__
 *
 * To run a mutation, you first call `useDuplicateMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateMenusMutation, { data, loading, error }] = useDuplicateMenusMutation({
 *   variables: {
 *      storeFromId: // value for 'storeFromId'
 *      storeToId: // value for 'storeToId'
 *   },
 * });
 */
export function useDuplicateMenusMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateMenusMutation, DuplicateMenusMutationVariables>) {
        return Apollo.useMutation<DuplicateMenusMutation, DuplicateMenusMutationVariables>(DuplicateMenusDocument, baseOptions);
      }
export type DuplicateMenusMutationHookResult = ReturnType<typeof useDuplicateMenusMutation>;
export type DuplicateMenusMutationResult = Apollo.MutationResult<DuplicateMenusMutation>;
export type DuplicateMenusMutationOptions = Apollo.BaseMutationOptions<DuplicateMenusMutation, DuplicateMenusMutationVariables>;
export const PauseStoreDocument = gql`
    mutation PauseStore($id: ID!, $paused: Boolean!) {
  pauseStore(id: $id, paused: $paused) {
    store {
      id
      paused
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type PauseStoreMutationFn = Apollo.MutationFunction<PauseStoreMutation, PauseStoreMutationVariables>;

/**
 * __usePauseStoreMutation__
 *
 * To run a mutation, you first call `usePauseStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseStoreMutation, { data, loading, error }] = usePauseStoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paused: // value for 'paused'
 *   },
 * });
 */
export function usePauseStoreMutation(baseOptions?: Apollo.MutationHookOptions<PauseStoreMutation, PauseStoreMutationVariables>) {
        return Apollo.useMutation<PauseStoreMutation, PauseStoreMutationVariables>(PauseStoreDocument, baseOptions);
      }
export type PauseStoreMutationHookResult = ReturnType<typeof usePauseStoreMutation>;
export type PauseStoreMutationResult = Apollo.MutationResult<PauseStoreMutation>;
export type PauseStoreMutationOptions = Apollo.BaseMutationOptions<PauseStoreMutation, PauseStoreMutationVariables>;
export const SyncMenusDocument = gql`
    mutation SyncMenus($storeId: ID!, $integration: Integration!) {
  syncMenus(storeId: $storeId, integration: $integration) {
    response {
      success
      message
    }
  }
}
    `;
export type SyncMenusMutationFn = Apollo.MutationFunction<SyncMenusMutation, SyncMenusMutationVariables>;

/**
 * __useSyncMenusMutation__
 *
 * To run a mutation, you first call `useSyncMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncMenusMutation, { data, loading, error }] = useSyncMenusMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      integration: // value for 'integration'
 *   },
 * });
 */
export function useSyncMenusMutation(baseOptions?: Apollo.MutationHookOptions<SyncMenusMutation, SyncMenusMutationVariables>) {
        return Apollo.useMutation<SyncMenusMutation, SyncMenusMutationVariables>(SyncMenusDocument, baseOptions);
      }
export type SyncMenusMutationHookResult = ReturnType<typeof useSyncMenusMutation>;
export type SyncMenusMutationResult = Apollo.MutationResult<SyncMenusMutation>;
export type SyncMenusMutationOptions = Apollo.BaseMutationOptions<SyncMenusMutation, SyncMenusMutationVariables>;
export const UpdateStoreInfoDocument = gql`
    mutation UpdateStoreInfo($id: ID!, $restaurantId: ID, $name: String, $address: String, $latitude: Float, $longitude: Float, $description: String, $phoneNumber: String, $hide: Boolean, $deliveryOption: [DeliveryOption!], $deliveryServices: [DeliveryService!], $taxPercentage: Int, $counterFeePercentage: Float, $techFeePercentage: Float, $revenueSharePercentage: Float, $grabfoodStoreId: String, $foodpandaChainId: String, $shopeefoodStoreId: String, $categoryIds: [ID!], $image: File, $banner: File, $priceLevel: StorePriceLevel!) {
  updateStoreInfo(
    id: $id
    input: {restaurantId: $restaurantId, name: $name, address: $address, latitude: $latitude, longitude: $longitude, description: $description, phoneNumber: $phoneNumber, hide: $hide, deliveryOption: $deliveryOption, deliveryServices: $deliveryServices, taxPercentage: $taxPercentage, counterFeePercentage: $counterFeePercentage, techFeePercentage: $techFeePercentage, revenueSharePercentage: $revenueSharePercentage, grabfoodStoreId: $grabfoodStoreId, foodpandaChainId: $foodpandaChainId, shopeefoodStoreId: $shopeefoodStoreId, categoryIds: $categoryIds, image: $image, banner: $banner, priceLevel: $priceLevel}
  ) {
    store {
      id
      name
      description
      address
      longitude
      latitude
      deliveryOption
      taxPercentage
      counterFeePercentage
      techFeePercentage
      revenueSharePercentage
      priceLevel
      restaurant {
        id
      }
      categories {
        id
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateStoreInfoMutationFn = Apollo.MutationFunction<UpdateStoreInfoMutation, UpdateStoreInfoMutationVariables>;

/**
 * __useUpdateStoreInfoMutation__
 *
 * To run a mutation, you first call `useUpdateStoreInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreInfoMutation, { data, loading, error }] = useUpdateStoreInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      restaurantId: // value for 'restaurantId'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      description: // value for 'description'
 *      phoneNumber: // value for 'phoneNumber'
 *      hide: // value for 'hide'
 *      deliveryOption: // value for 'deliveryOption'
 *      deliveryServices: // value for 'deliveryServices'
 *      taxPercentage: // value for 'taxPercentage'
 *      counterFeePercentage: // value for 'counterFeePercentage'
 *      techFeePercentage: // value for 'techFeePercentage'
 *      revenueSharePercentage: // value for 'revenueSharePercentage'
 *      grabfoodStoreId: // value for 'grabfoodStoreId'
 *      foodpandaChainId: // value for 'foodpandaChainId'
 *      shopeefoodStoreId: // value for 'shopeefoodStoreId'
 *      categoryIds: // value for 'categoryIds'
 *      image: // value for 'image'
 *      banner: // value for 'banner'
 *      priceLevel: // value for 'priceLevel'
 *   },
 * });
 */
export function useUpdateStoreInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreInfoMutation, UpdateStoreInfoMutationVariables>) {
        return Apollo.useMutation<UpdateStoreInfoMutation, UpdateStoreInfoMutationVariables>(UpdateStoreInfoDocument, baseOptions);
      }
export type UpdateStoreInfoMutationHookResult = ReturnType<typeof useUpdateStoreInfoMutation>;
export type UpdateStoreInfoMutationResult = Apollo.MutationResult<UpdateStoreInfoMutation>;
export type UpdateStoreInfoMutationOptions = Apollo.BaseMutationOptions<UpdateStoreInfoMutation, UpdateStoreInfoMutationVariables>;
export const CancelStoreOrderDocument = gql`
    mutation CancelStoreOrder($id: ID!, $refundAmount: Float!, $cancelCode: Int!) {
  cancelStoreOrder(id: $id, refundAmount: $refundAmount, cancelCode: $cancelCode) {
    storeOrder {
      id
      state
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CancelStoreOrderMutationFn = Apollo.MutationFunction<CancelStoreOrderMutation, CancelStoreOrderMutationVariables>;

/**
 * __useCancelStoreOrderMutation__
 *
 * To run a mutation, you first call `useCancelStoreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStoreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStoreOrderMutation, { data, loading, error }] = useCancelStoreOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      refundAmount: // value for 'refundAmount'
 *      cancelCode: // value for 'cancelCode'
 *   },
 * });
 */
export function useCancelStoreOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelStoreOrderMutation, CancelStoreOrderMutationVariables>) {
        return Apollo.useMutation<CancelStoreOrderMutation, CancelStoreOrderMutationVariables>(CancelStoreOrderDocument, baseOptions);
      }
export type CancelStoreOrderMutationHookResult = ReturnType<typeof useCancelStoreOrderMutation>;
export type CancelStoreOrderMutationResult = Apollo.MutationResult<CancelStoreOrderMutation>;
export type CancelStoreOrderMutationOptions = Apollo.BaseMutationOptions<CancelStoreOrderMutation, CancelStoreOrderMutationVariables>;
export const EditStoreOrderDocument = gql`
    mutation EditStoreOrder($id: ID!, $items: [EditStoreOrderItemInput!]!) {
  editStoreOrder(id: $id, items: $items) {
    storeOrder {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EditStoreOrderMutationFn = Apollo.MutationFunction<EditStoreOrderMutation, EditStoreOrderMutationVariables>;

/**
 * __useEditStoreOrderMutation__
 *
 * To run a mutation, you first call `useEditStoreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStoreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStoreOrderMutation, { data, loading, error }] = useEditStoreOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useEditStoreOrderMutation(baseOptions?: Apollo.MutationHookOptions<EditStoreOrderMutation, EditStoreOrderMutationVariables>) {
        return Apollo.useMutation<EditStoreOrderMutation, EditStoreOrderMutationVariables>(EditStoreOrderDocument, baseOptions);
      }
export type EditStoreOrderMutationHookResult = ReturnType<typeof useEditStoreOrderMutation>;
export type EditStoreOrderMutationResult = Apollo.MutationResult<EditStoreOrderMutation>;
export type EditStoreOrderMutationOptions = Apollo.BaseMutationOptions<EditStoreOrderMutation, EditStoreOrderMutationVariables>;
export const ReadyStoreOrderDocument = gql`
    mutation ReadyStoreOrder($id: ID!) {
  readyStoreOrder(id: $id) {
    storeOrder {
      id
      state
      cabinetSlotNumber
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ReadyStoreOrderMutationFn = Apollo.MutationFunction<ReadyStoreOrderMutation, ReadyStoreOrderMutationVariables>;

/**
 * __useReadyStoreOrderMutation__
 *
 * To run a mutation, you first call `useReadyStoreOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadyStoreOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readyStoreOrderMutation, { data, loading, error }] = useReadyStoreOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadyStoreOrderMutation(baseOptions?: Apollo.MutationHookOptions<ReadyStoreOrderMutation, ReadyStoreOrderMutationVariables>) {
        return Apollo.useMutation<ReadyStoreOrderMutation, ReadyStoreOrderMutationVariables>(ReadyStoreOrderDocument, baseOptions);
      }
export type ReadyStoreOrderMutationHookResult = ReturnType<typeof useReadyStoreOrderMutation>;
export type ReadyStoreOrderMutationResult = Apollo.MutationResult<ReadyStoreOrderMutation>;
export type ReadyStoreOrderMutationOptions = Apollo.BaseMutationOptions<ReadyStoreOrderMutation, ReadyStoreOrderMutationVariables>;
export const CompletePurchaseOrderDocument = gql`
    mutation CompletePurchaseOrder($purchaseOrderId: ID!) {
  completeSupplierPurchaseOrder(purchaseOrderId: $purchaseOrderId) {
    purchaseOrder {
      ...SupplierPurchaseOrder
    }
    errors {
      ...Error
    }
  }
}
    ${SupplierPurchaseOrderFragmentDoc}
${ErrorFragmentDoc}`;
export type CompletePurchaseOrderMutationFn = Apollo.MutationFunction<CompletePurchaseOrderMutation, CompletePurchaseOrderMutationVariables>;

/**
 * __useCompletePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useCompletePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePurchaseOrderMutation, { data, loading, error }] = useCompletePurchaseOrderMutation({
 *   variables: {
 *      purchaseOrderId: // value for 'purchaseOrderId'
 *   },
 * });
 */
export function useCompletePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompletePurchaseOrderMutation, CompletePurchaseOrderMutationVariables>) {
        return Apollo.useMutation<CompletePurchaseOrderMutation, CompletePurchaseOrderMutationVariables>(CompletePurchaseOrderDocument, baseOptions);
      }
export type CompletePurchaseOrderMutationHookResult = ReturnType<typeof useCompletePurchaseOrderMutation>;
export type CompletePurchaseOrderMutationResult = Apollo.MutationResult<CompletePurchaseOrderMutation>;
export type CompletePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<CompletePurchaseOrderMutation, CompletePurchaseOrderMutationVariables>;
export const CreateFinancingDocument = gql`
    mutation CreateFinancing($purchaseOrderId: ID!) {
  createSupplierFinancing(purchaseOrderId: $purchaseOrderId) {
    purchaseOrder {
      ...SupplierPurchaseOrder
    }
    errors {
      ...Error
    }
  }
}
    ${SupplierPurchaseOrderFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateFinancingMutationFn = Apollo.MutationFunction<CreateFinancingMutation, CreateFinancingMutationVariables>;

/**
 * __useCreateFinancingMutation__
 *
 * To run a mutation, you first call `useCreateFinancingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinancingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinancingMutation, { data, loading, error }] = useCreateFinancingMutation({
 *   variables: {
 *      purchaseOrderId: // value for 'purchaseOrderId'
 *   },
 * });
 */
export function useCreateFinancingMutation(baseOptions?: Apollo.MutationHookOptions<CreateFinancingMutation, CreateFinancingMutationVariables>) {
        return Apollo.useMutation<CreateFinancingMutation, CreateFinancingMutationVariables>(CreateFinancingDocument, baseOptions);
      }
export type CreateFinancingMutationHookResult = ReturnType<typeof useCreateFinancingMutation>;
export type CreateFinancingMutationResult = Apollo.MutationResult<CreateFinancingMutation>;
export type CreateFinancingMutationOptions = Apollo.BaseMutationOptions<CreateFinancingMutation, CreateFinancingMutationVariables>;
export const CreateSupplierPaymentProofDocument = gql`
    mutation CreateSupplierPaymentProof($file: File!, $purchaseOrderId: ID!) {
  createSupplierPaymentProof(file: $file, purchaseOrderId: $purchaseOrderId) {
    purchaseOrder {
      ...SupplierPurchaseOrder
    }
    errors {
      ...Error
    }
  }
}
    ${SupplierPurchaseOrderFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateSupplierPaymentProofMutationFn = Apollo.MutationFunction<CreateSupplierPaymentProofMutation, CreateSupplierPaymentProofMutationVariables>;

/**
 * __useCreateSupplierPaymentProofMutation__
 *
 * To run a mutation, you first call `useCreateSupplierPaymentProofMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierPaymentProofMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierPaymentProofMutation, { data, loading, error }] = useCreateSupplierPaymentProofMutation({
 *   variables: {
 *      file: // value for 'file'
 *      purchaseOrderId: // value for 'purchaseOrderId'
 *   },
 * });
 */
export function useCreateSupplierPaymentProofMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierPaymentProofMutation, CreateSupplierPaymentProofMutationVariables>) {
        return Apollo.useMutation<CreateSupplierPaymentProofMutation, CreateSupplierPaymentProofMutationVariables>(CreateSupplierPaymentProofDocument, baseOptions);
      }
export type CreateSupplierPaymentProofMutationHookResult = ReturnType<typeof useCreateSupplierPaymentProofMutation>;
export type CreateSupplierPaymentProofMutationResult = Apollo.MutationResult<CreateSupplierPaymentProofMutation>;
export type CreateSupplierPaymentProofMutationOptions = Apollo.BaseMutationOptions<CreateSupplierPaymentProofMutation, CreateSupplierPaymentProofMutationVariables>;
export const CreatePurchaseOrderDocument = gql`
    mutation CreatePurchaseOrder($deliverTo: String!, $items: [CreatePurchaseOrderItemsInput!]!) {
  createSupplierPurchaseOrder(input: {deliverTo: $deliverTo, items: $items}) {
    purchaseOrders {
      ...SupplierPurchaseOrder
    }
    errors {
      ...Error
    }
  }
}
    ${SupplierPurchaseOrderFragmentDoc}
${ErrorFragmentDoc}`;
export type CreatePurchaseOrderMutationFn = Apollo.MutationFunction<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>;

/**
 * __useCreatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseOrderMutation, { data, loading, error }] = useCreatePurchaseOrderMutation({
 *   variables: {
 *      deliverTo: // value for 'deliverTo'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreatePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>) {
        return Apollo.useMutation<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>(CreatePurchaseOrderDocument, baseOptions);
      }
export type CreatePurchaseOrderMutationHookResult = ReturnType<typeof useCreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationResult = Apollo.MutationResult<CreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>;
export const SetupStripeIntentDocument = gql`
    mutation SetupStripeIntent {
  setupStripeIntent {
    clientSecret
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type SetupStripeIntentMutationFn = Apollo.MutationFunction<SetupStripeIntentMutation, SetupStripeIntentMutationVariables>;

/**
 * __useSetupStripeIntentMutation__
 *
 * To run a mutation, you first call `useSetupStripeIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupStripeIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupStripeIntentMutation, { data, loading, error }] = useSetupStripeIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetupStripeIntentMutation(baseOptions?: Apollo.MutationHookOptions<SetupStripeIntentMutation, SetupStripeIntentMutationVariables>) {
        return Apollo.useMutation<SetupStripeIntentMutation, SetupStripeIntentMutationVariables>(SetupStripeIntentDocument, baseOptions);
      }
export type SetupStripeIntentMutationHookResult = ReturnType<typeof useSetupStripeIntentMutation>;
export type SetupStripeIntentMutationResult = Apollo.MutationResult<SetupStripeIntentMutation>;
export type SetupStripeIntentMutationOptions = Apollo.BaseMutationOptions<SetupStripeIntentMutation, SetupStripeIntentMutationVariables>;
export const LogDocument = gql`
    query Log($id: ID!) {
  me {
    ...Me
  }
  log(id: $id) {
    id
    email
    action
    message
    createdAt
    store {
      id
      name
    }
    loggable {
      __typename
      ... on Store {
        id
      }
      ... on OpeningHour {
        id
      }
      ... on MenuSection {
        id
      }
      ... on MenuCategory {
        id
      }
      ... on MenuItem {
        id
      }
      ... on MenuOption {
        id
      }
      ... on Promo {
        id
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useLogQuery__
 *
 * To run a query within a React component, call `useLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogQuery(baseOptions: Apollo.QueryHookOptions<LogQuery, LogQueryVariables>) {
        return Apollo.useQuery<LogQuery, LogQueryVariables>(LogDocument, baseOptions);
      }
export function useLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogQuery, LogQueryVariables>) {
          return Apollo.useLazyQuery<LogQuery, LogQueryVariables>(LogDocument, baseOptions);
        }
export type LogQueryHookResult = ReturnType<typeof useLogQuery>;
export type LogLazyQueryHookResult = ReturnType<typeof useLogLazyQuery>;
export type LogQueryResult = Apollo.QueryResult<LogQuery, LogQueryVariables>;
export const OrderDetailPageDocument = gql`
    query OrderDetailPage($id: ID!) {
  me {
    ...Me
  }
  mixOrder(id: $id) {
    id
    state
    orderId
    orderCode
    consumerName
    consumerPhone
    integration
    deliveryOption
    platform
    cutlery
    paymentMethod
    acceptedTime
    address
    store {
      id
      name
    }
    deliveryBooking {
      id
      integration
      bookingId
      riderName
      riderPhoneNumber
      riderVehicleNo
      trackingLink
    }
    storeOrders {
      id
      isMine
      state
      cancelMessage
      cabinetSlotNumber
      originalPrice
      tax
      promo
      finalPrice
      revenue
      externalFee
      counterFee
      platformFee
      revenueShare
      netIncome
      cancelableData {
        id
        cancelable
        nonCancelableReason
        limit
        reasons {
          id
          code
          reason
        }
      }
      rating {
        id
        rate
        comment
      }
      store {
        id
        name
        country
      }
      orderMenuItemsWithCount {
        id
        count
        item {
          id
          externalId
          name
          price
          remark
          details {
            id
            name
          }
        }
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useOrderDetailPageQuery__
 *
 * To run a query within a React component, call `useOrderDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderDetailPageQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailPageQuery, OrderDetailPageQueryVariables>) {
        return Apollo.useQuery<OrderDetailPageQuery, OrderDetailPageQueryVariables>(OrderDetailPageDocument, baseOptions);
      }
export function useOrderDetailPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailPageQuery, OrderDetailPageQueryVariables>) {
          return Apollo.useLazyQuery<OrderDetailPageQuery, OrderDetailPageQueryVariables>(OrderDetailPageDocument, baseOptions);
        }
export type OrderDetailPageQueryHookResult = ReturnType<typeof useOrderDetailPageQuery>;
export type OrderDetailPageLazyQueryHookResult = ReturnType<typeof useOrderDetailPageLazyQuery>;
export type OrderDetailPageQueryResult = Apollo.QueryResult<OrderDetailPageQuery, OrderDetailPageQueryVariables>;
export const StoreInfoPageDocument = gql`
    query StoreInfoPage($id: ID!) {
  me {
    ...Me
  }
  outlets {
    id
    name
    restaurants {
      id
      name
      number
    }
  }
  categories {
    id
    name
  }
  priceLevels {
    id
    level
    name
  }
  store(id: $id) {
    id
    name
    description
    friendlyPath
    countryCode
    nationalNumber
    address
    hide
    image
    banner
    longitude
    latitude
    deliveryOption
    deliveryServices
    taxPercentage
    counterFeePercentage
    techFeePercentage
    revenueSharePercentage
    priceLevel
    grabfoodStoreId
    foodpandaChainId
    shopeefoodStoreId
    restaurant {
      id
    }
    categories {
      id
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useStoreInfoPageQuery__
 *
 * To run a query within a React component, call `useStoreInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreInfoPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreInfoPageQuery(baseOptions: Apollo.QueryHookOptions<StoreInfoPageQuery, StoreInfoPageQueryVariables>) {
        return Apollo.useQuery<StoreInfoPageQuery, StoreInfoPageQueryVariables>(StoreInfoPageDocument, baseOptions);
      }
export function useStoreInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreInfoPageQuery, StoreInfoPageQueryVariables>) {
          return Apollo.useLazyQuery<StoreInfoPageQuery, StoreInfoPageQueryVariables>(StoreInfoPageDocument, baseOptions);
        }
export type StoreInfoPageQueryHookResult = ReturnType<typeof useStoreInfoPageQuery>;
export type StoreInfoPageLazyQueryHookResult = ReturnType<typeof useStoreInfoPageLazyQuery>;
export type StoreInfoPageQueryResult = Apollo.QueryResult<StoreInfoPageQuery, StoreInfoPageQueryVariables>;
export const EditMenuCategoryPageDocument = gql`
    query EditMenuCategoryPage($id: ID!, $storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    menuCategory(id: $id) {
      id
      name
      availableStatus
      sequence
      menuSection {
        id
        name
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useEditMenuCategoryPageQuery__
 *
 * To run a query within a React component, call `useEditMenuCategoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuCategoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuCategoryPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useEditMenuCategoryPageQuery(baseOptions: Apollo.QueryHookOptions<EditMenuCategoryPageQuery, EditMenuCategoryPageQueryVariables>) {
        return Apollo.useQuery<EditMenuCategoryPageQuery, EditMenuCategoryPageQueryVariables>(EditMenuCategoryPageDocument, baseOptions);
      }
export function useEditMenuCategoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuCategoryPageQuery, EditMenuCategoryPageQueryVariables>) {
          return Apollo.useLazyQuery<EditMenuCategoryPageQuery, EditMenuCategoryPageQueryVariables>(EditMenuCategoryPageDocument, baseOptions);
        }
export type EditMenuCategoryPageQueryHookResult = ReturnType<typeof useEditMenuCategoryPageQuery>;
export type EditMenuCategoryPageLazyQueryHookResult = ReturnType<typeof useEditMenuCategoryPageLazyQuery>;
export type EditMenuCategoryPageQueryResult = Apollo.QueryResult<EditMenuCategoryPageQuery, EditMenuCategoryPageQueryVariables>;
export const CreateMenuCategoryPageDocument = gql`
    query CreateMenuCategoryPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    menuSection {
      id
      name
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useCreateMenuCategoryPageQuery__
 *
 * To run a query within a React component, call `useCreateMenuCategoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuCategoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMenuCategoryPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCreateMenuCategoryPageQuery(baseOptions: Apollo.QueryHookOptions<CreateMenuCategoryPageQuery, CreateMenuCategoryPageQueryVariables>) {
        return Apollo.useQuery<CreateMenuCategoryPageQuery, CreateMenuCategoryPageQueryVariables>(CreateMenuCategoryPageDocument, baseOptions);
      }
export function useCreateMenuCategoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateMenuCategoryPageQuery, CreateMenuCategoryPageQueryVariables>) {
          return Apollo.useLazyQuery<CreateMenuCategoryPageQuery, CreateMenuCategoryPageQueryVariables>(CreateMenuCategoryPageDocument, baseOptions);
        }
export type CreateMenuCategoryPageQueryHookResult = ReturnType<typeof useCreateMenuCategoryPageQuery>;
export type CreateMenuCategoryPageLazyQueryHookResult = ReturnType<typeof useCreateMenuCategoryPageLazyQuery>;
export type CreateMenuCategoryPageQueryResult = Apollo.QueryResult<CreateMenuCategoryPageQuery, CreateMenuCategoryPageQueryVariables>;
export const MenuCategoryListPageDocument = gql`
    query MenuCategoryListPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    country
    menuSection {
      id
    }
    menuCategories {
      id
      name
      availableStatus
      menuItems {
        id
        name
        availableStatus
        description
        price
        image
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMenuCategoryListPageQuery__
 *
 * To run a query within a React component, call `useMenuCategoryListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuCategoryListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuCategoryListPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useMenuCategoryListPageQuery(baseOptions: Apollo.QueryHookOptions<MenuCategoryListPageQuery, MenuCategoryListPageQueryVariables>) {
        return Apollo.useQuery<MenuCategoryListPageQuery, MenuCategoryListPageQueryVariables>(MenuCategoryListPageDocument, baseOptions);
      }
export function useMenuCategoryListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuCategoryListPageQuery, MenuCategoryListPageQueryVariables>) {
          return Apollo.useLazyQuery<MenuCategoryListPageQuery, MenuCategoryListPageQueryVariables>(MenuCategoryListPageDocument, baseOptions);
        }
export type MenuCategoryListPageQueryHookResult = ReturnType<typeof useMenuCategoryListPageQuery>;
export type MenuCategoryListPageLazyQueryHookResult = ReturnType<typeof useMenuCategoryListPageLazyQuery>;
export type MenuCategoryListPageQueryResult = Apollo.QueryResult<MenuCategoryListPageQuery, MenuCategoryListPageQueryVariables>;
export const EditMenuItemPageDocument = gql`
    query EditMenuItemPage($id: ID!, $storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    menuCategories {
      id
      name
      menuSection {
        id
        name
      }
    }
    menuItem(id: $id) {
      id
      name
      availableStatus
      description
      sequence
      image
      price
      stock
      stockEnabled
      menuCategories {
        id
        name
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useEditMenuItemPageQuery__
 *
 * To run a query within a React component, call `useEditMenuItemPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuItemPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuItemPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useEditMenuItemPageQuery(baseOptions: Apollo.QueryHookOptions<EditMenuItemPageQuery, EditMenuItemPageQueryVariables>) {
        return Apollo.useQuery<EditMenuItemPageQuery, EditMenuItemPageQueryVariables>(EditMenuItemPageDocument, baseOptions);
      }
export function useEditMenuItemPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuItemPageQuery, EditMenuItemPageQueryVariables>) {
          return Apollo.useLazyQuery<EditMenuItemPageQuery, EditMenuItemPageQueryVariables>(EditMenuItemPageDocument, baseOptions);
        }
export type EditMenuItemPageQueryHookResult = ReturnType<typeof useEditMenuItemPageQuery>;
export type EditMenuItemPageLazyQueryHookResult = ReturnType<typeof useEditMenuItemPageLazyQuery>;
export type EditMenuItemPageQueryResult = Apollo.QueryResult<EditMenuItemPageQuery, EditMenuItemPageQueryVariables>;
export const CreateMenuItemPageDocument = gql`
    query CreateMenuItemPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    menuCategories {
      id
      name
      menuSection {
        id
        name
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useCreateMenuItemPageQuery__
 *
 * To run a query within a React component, call `useCreateMenuItemPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMenuItemPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCreateMenuItemPageQuery(baseOptions: Apollo.QueryHookOptions<CreateMenuItemPageQuery, CreateMenuItemPageQueryVariables>) {
        return Apollo.useQuery<CreateMenuItemPageQuery, CreateMenuItemPageQueryVariables>(CreateMenuItemPageDocument, baseOptions);
      }
export function useCreateMenuItemPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateMenuItemPageQuery, CreateMenuItemPageQueryVariables>) {
          return Apollo.useLazyQuery<CreateMenuItemPageQuery, CreateMenuItemPageQueryVariables>(CreateMenuItemPageDocument, baseOptions);
        }
export type CreateMenuItemPageQueryHookResult = ReturnType<typeof useCreateMenuItemPageQuery>;
export type CreateMenuItemPageLazyQueryHookResult = ReturnType<typeof useCreateMenuItemPageLazyQuery>;
export type CreateMenuItemPageQueryResult = Apollo.QueryResult<CreateMenuItemPageQuery, CreateMenuItemPageQueryVariables>;
export const MenuItemListPageDocument = gql`
    query MenuItemListPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    country
    menuItems {
      id
      name
      availableStatus
      withCategories
      description
      image
      price
      stockEnabled
      stock
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMenuItemListPageQuery__
 *
 * To run a query within a React component, call `useMenuItemListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemListPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useMenuItemListPageQuery(baseOptions: Apollo.QueryHookOptions<MenuItemListPageQuery, MenuItemListPageQueryVariables>) {
        return Apollo.useQuery<MenuItemListPageQuery, MenuItemListPageQueryVariables>(MenuItemListPageDocument, baseOptions);
      }
export function useMenuItemListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuItemListPageQuery, MenuItemListPageQueryVariables>) {
          return Apollo.useLazyQuery<MenuItemListPageQuery, MenuItemListPageQueryVariables>(MenuItemListPageDocument, baseOptions);
        }
export type MenuItemListPageQueryHookResult = ReturnType<typeof useMenuItemListPageQuery>;
export type MenuItemListPageLazyQueryHookResult = ReturnType<typeof useMenuItemListPageLazyQuery>;
export type MenuItemListPageQueryResult = Apollo.QueryResult<MenuItemListPageQuery, MenuItemListPageQueryVariables>;
export const EditMenuOptionPageDocument = gql`
    query EditMenuOptionPage($id: ID!, $storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    country
    menuItems {
      id
      name
      price
      withCategories
    }
    menuOption(id: $id) {
      id
      name
      availableStatus
      sequence
      min
      max
      items {
        id
      }
      itemOptions {
        id
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useEditMenuOptionPageQuery__
 *
 * To run a query within a React component, call `useEditMenuOptionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuOptionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuOptionPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useEditMenuOptionPageQuery(baseOptions: Apollo.QueryHookOptions<EditMenuOptionPageQuery, EditMenuOptionPageQueryVariables>) {
        return Apollo.useQuery<EditMenuOptionPageQuery, EditMenuOptionPageQueryVariables>(EditMenuOptionPageDocument, baseOptions);
      }
export function useEditMenuOptionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuOptionPageQuery, EditMenuOptionPageQueryVariables>) {
          return Apollo.useLazyQuery<EditMenuOptionPageQuery, EditMenuOptionPageQueryVariables>(EditMenuOptionPageDocument, baseOptions);
        }
export type EditMenuOptionPageQueryHookResult = ReturnType<typeof useEditMenuOptionPageQuery>;
export type EditMenuOptionPageLazyQueryHookResult = ReturnType<typeof useEditMenuOptionPageLazyQuery>;
export type EditMenuOptionPageQueryResult = Apollo.QueryResult<EditMenuOptionPageQuery, EditMenuOptionPageQueryVariables>;
export const CreateMenuOptionPageDocument = gql`
    query CreateMenuOptionPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    country
    menuItems {
      id
      name
      price
      withCategories
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useCreateMenuOptionPageQuery__
 *
 * To run a query within a React component, call `useCreateMenuOptionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuOptionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMenuOptionPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCreateMenuOptionPageQuery(baseOptions: Apollo.QueryHookOptions<CreateMenuOptionPageQuery, CreateMenuOptionPageQueryVariables>) {
        return Apollo.useQuery<CreateMenuOptionPageQuery, CreateMenuOptionPageQueryVariables>(CreateMenuOptionPageDocument, baseOptions);
      }
export function useCreateMenuOptionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateMenuOptionPageQuery, CreateMenuOptionPageQueryVariables>) {
          return Apollo.useLazyQuery<CreateMenuOptionPageQuery, CreateMenuOptionPageQueryVariables>(CreateMenuOptionPageDocument, baseOptions);
        }
export type CreateMenuOptionPageQueryHookResult = ReturnType<typeof useCreateMenuOptionPageQuery>;
export type CreateMenuOptionPageLazyQueryHookResult = ReturnType<typeof useCreateMenuOptionPageLazyQuery>;
export type CreateMenuOptionPageQueryResult = Apollo.QueryResult<CreateMenuOptionPageQuery, CreateMenuOptionPageQueryVariables>;
export const MenuOptionListPageDocument = gql`
    query MenuOptionListPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    name
    country
    menuOptions {
      id
      name
      availableStatus
      min
      max
      items {
        id
        name
        price
      }
      itemOptions {
        id
        name
        price
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMenuOptionListPageQuery__
 *
 * To run a query within a React component, call `useMenuOptionListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOptionListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOptionListPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useMenuOptionListPageQuery(baseOptions: Apollo.QueryHookOptions<MenuOptionListPageQuery, MenuOptionListPageQueryVariables>) {
        return Apollo.useQuery<MenuOptionListPageQuery, MenuOptionListPageQueryVariables>(MenuOptionListPageDocument, baseOptions);
      }
export function useMenuOptionListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuOptionListPageQuery, MenuOptionListPageQueryVariables>) {
          return Apollo.useLazyQuery<MenuOptionListPageQuery, MenuOptionListPageQueryVariables>(MenuOptionListPageDocument, baseOptions);
        }
export type MenuOptionListPageQueryHookResult = ReturnType<typeof useMenuOptionListPageQuery>;
export type MenuOptionListPageLazyQueryHookResult = ReturnType<typeof useMenuOptionListPageLazyQuery>;
export type MenuOptionListPageQueryResult = Apollo.QueryResult<MenuOptionListPageQuery, MenuOptionListPageQueryVariables>;
export const EditMenuSectionPageDocument = gql`
    query EditMenuSectionPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    menuSection {
      id
      name
      sequence
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useEditMenuSectionPageQuery__
 *
 * To run a query within a React component, call `useEditMenuSectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuSectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuSectionPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useEditMenuSectionPageQuery(baseOptions: Apollo.QueryHookOptions<EditMenuSectionPageQuery, EditMenuSectionPageQueryVariables>) {
        return Apollo.useQuery<EditMenuSectionPageQuery, EditMenuSectionPageQueryVariables>(EditMenuSectionPageDocument, baseOptions);
      }
export function useEditMenuSectionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuSectionPageQuery, EditMenuSectionPageQueryVariables>) {
          return Apollo.useLazyQuery<EditMenuSectionPageQuery, EditMenuSectionPageQueryVariables>(EditMenuSectionPageDocument, baseOptions);
        }
export type EditMenuSectionPageQueryHookResult = ReturnType<typeof useEditMenuSectionPageQuery>;
export type EditMenuSectionPageLazyQueryHookResult = ReturnType<typeof useEditMenuSectionPageLazyQuery>;
export type EditMenuSectionPageQueryResult = Apollo.QueryResult<EditMenuSectionPageQuery, EditMenuSectionPageQueryVariables>;
export const OpeningHourPageDocument = gql`
    query OpeningHourPage($menuSectionId: ID!) {
  me {
    ...Me
  }
  menuSection(id: $menuSectionId) {
    id
    name
    openingHours {
      id
      day
      open
      close
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useOpeningHourPageQuery__
 *
 * To run a query within a React component, call `useOpeningHourPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpeningHourPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpeningHourPageQuery({
 *   variables: {
 *      menuSectionId: // value for 'menuSectionId'
 *   },
 * });
 */
export function useOpeningHourPageQuery(baseOptions: Apollo.QueryHookOptions<OpeningHourPageQuery, OpeningHourPageQueryVariables>) {
        return Apollo.useQuery<OpeningHourPageQuery, OpeningHourPageQueryVariables>(OpeningHourPageDocument, baseOptions);
      }
export function useOpeningHourPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpeningHourPageQuery, OpeningHourPageQueryVariables>) {
          return Apollo.useLazyQuery<OpeningHourPageQuery, OpeningHourPageQueryVariables>(OpeningHourPageDocument, baseOptions);
        }
export type OpeningHourPageQueryHookResult = ReturnType<typeof useOpeningHourPageQuery>;
export type OpeningHourPageLazyQueryHookResult = ReturnType<typeof useOpeningHourPageLazyQuery>;
export type OpeningHourPageQueryResult = Apollo.QueryResult<OpeningHourPageQuery, OpeningHourPageQueryVariables>;
export const CreateMenuSectionPageDocument = gql`
    query CreateMenuSectionPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    menuSection {
      id
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useCreateMenuSectionPageQuery__
 *
 * To run a query within a React component, call `useCreateMenuSectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuSectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMenuSectionPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCreateMenuSectionPageQuery(baseOptions: Apollo.QueryHookOptions<CreateMenuSectionPageQuery, CreateMenuSectionPageQueryVariables>) {
        return Apollo.useQuery<CreateMenuSectionPageQuery, CreateMenuSectionPageQueryVariables>(CreateMenuSectionPageDocument, baseOptions);
      }
export function useCreateMenuSectionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateMenuSectionPageQuery, CreateMenuSectionPageQueryVariables>) {
          return Apollo.useLazyQuery<CreateMenuSectionPageQuery, CreateMenuSectionPageQueryVariables>(CreateMenuSectionPageDocument, baseOptions);
        }
export type CreateMenuSectionPageQueryHookResult = ReturnType<typeof useCreateMenuSectionPageQuery>;
export type CreateMenuSectionPageLazyQueryHookResult = ReturnType<typeof useCreateMenuSectionPageLazyQuery>;
export type CreateMenuSectionPageQueryResult = Apollo.QueryResult<CreateMenuSectionPageQuery, CreateMenuSectionPageQueryVariables>;
export const MenuSectionListPageDocument = gql`
    query MenuSectionListPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    menuSection {
      id
      name
      openingHours {
        id
        day
        open
        close
      }
      menuCategories {
        id
        name
        availableStatus
      }
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMenuSectionListPageQuery__
 *
 * To run a query within a React component, call `useMenuSectionListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuSectionListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuSectionListPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useMenuSectionListPageQuery(baseOptions: Apollo.QueryHookOptions<MenuSectionListPageQuery, MenuSectionListPageQueryVariables>) {
        return Apollo.useQuery<MenuSectionListPageQuery, MenuSectionListPageQueryVariables>(MenuSectionListPageDocument, baseOptions);
      }
export function useMenuSectionListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuSectionListPageQuery, MenuSectionListPageQueryVariables>) {
          return Apollo.useLazyQuery<MenuSectionListPageQuery, MenuSectionListPageQueryVariables>(MenuSectionListPageDocument, baseOptions);
        }
export type MenuSectionListPageQueryHookResult = ReturnType<typeof useMenuSectionListPageQuery>;
export type MenuSectionListPageLazyQueryHookResult = ReturnType<typeof useMenuSectionListPageLazyQuery>;
export type MenuSectionListPageQueryResult = Apollo.QueryResult<MenuSectionListPageQuery, MenuSectionListPageQueryVariables>;
export const StorePromoUpdatePageDocument = gql`
    query StorePromoUpdatePage($id: ID!, $storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    country
    menuItemsWithCategories {
      id
      name
      price
    }
    promo(id: $id) {
      id
      name
      promoVariant
      amount
      validFrom
      validUntil
      startTime
      endTime
      days
      active
      menuItemIds
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useStorePromoUpdatePageQuery__
 *
 * To run a query within a React component, call `useStorePromoUpdatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorePromoUpdatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorePromoUpdatePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStorePromoUpdatePageQuery(baseOptions: Apollo.QueryHookOptions<StorePromoUpdatePageQuery, StorePromoUpdatePageQueryVariables>) {
        return Apollo.useQuery<StorePromoUpdatePageQuery, StorePromoUpdatePageQueryVariables>(StorePromoUpdatePageDocument, baseOptions);
      }
export function useStorePromoUpdatePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorePromoUpdatePageQuery, StorePromoUpdatePageQueryVariables>) {
          return Apollo.useLazyQuery<StorePromoUpdatePageQuery, StorePromoUpdatePageQueryVariables>(StorePromoUpdatePageDocument, baseOptions);
        }
export type StorePromoUpdatePageQueryHookResult = ReturnType<typeof useStorePromoUpdatePageQuery>;
export type StorePromoUpdatePageLazyQueryHookResult = ReturnType<typeof useStorePromoUpdatePageLazyQuery>;
export type StorePromoUpdatePageQueryResult = Apollo.QueryResult<StorePromoUpdatePageQuery, StorePromoUpdatePageQueryVariables>;
export const StorePromoPageDocument = gql`
    query StorePromoPage($storeId: ID!) {
  me {
    ...Me
  }
  store(id: $storeId) {
    id
    country
    menuItemsWithCategories {
      id
      name
      price
    }
  }
}
    ${MeFragmentDoc}`;

/**
 * __useStorePromoPageQuery__
 *
 * To run a query within a React component, call `useStorePromoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorePromoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorePromoPageQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStorePromoPageQuery(baseOptions: Apollo.QueryHookOptions<StorePromoPageQuery, StorePromoPageQueryVariables>) {
        return Apollo.useQuery<StorePromoPageQuery, StorePromoPageQueryVariables>(StorePromoPageDocument, baseOptions);
      }
export function useStorePromoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorePromoPageQuery, StorePromoPageQueryVariables>) {
          return Apollo.useLazyQuery<StorePromoPageQuery, StorePromoPageQueryVariables>(StorePromoPageDocument, baseOptions);
        }
export type StorePromoPageQueryHookResult = ReturnType<typeof useStorePromoPageQuery>;
export type StorePromoPageLazyQueryHookResult = ReturnType<typeof useStorePromoPageLazyQuery>;
export type StorePromoPageQueryResult = Apollo.QueryResult<StorePromoPageQuery, StorePromoPageQueryVariables>;
export const CreateStorePageDocument = gql`
    query CreateStorePage {
  me {
    ...Me
  }
  outlets {
    id
    name
    restaurants {
      id
      name
      number
    }
  }
  categories {
    id
    name
  }
  priceLevels {
    id
    level
    name
  }
}
    ${MeFragmentDoc}`;

/**
 * __useCreateStorePageQuery__
 *
 * To run a query within a React component, call `useCreateStorePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateStorePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateStorePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateStorePageQuery(baseOptions?: Apollo.QueryHookOptions<CreateStorePageQuery, CreateStorePageQueryVariables>) {
        return Apollo.useQuery<CreateStorePageQuery, CreateStorePageQueryVariables>(CreateStorePageDocument, baseOptions);
      }
export function useCreateStorePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateStorePageQuery, CreateStorePageQueryVariables>) {
          return Apollo.useLazyQuery<CreateStorePageQuery, CreateStorePageQueryVariables>(CreateStorePageDocument, baseOptions);
        }
export type CreateStorePageQueryHookResult = ReturnType<typeof useCreateStorePageQuery>;
export type CreateStorePageLazyQueryHookResult = ReturnType<typeof useCreateStorePageLazyQuery>;
export type CreateStorePageQueryResult = Apollo.QueryResult<CreateStorePageQuery, CreateStorePageQueryVariables>;
export const UpdateProfilePageDocument = gql`
    query UpdateProfilePage {
  me {
    ...Me
    countryCode
    nationalNumber
    phoneNumber
  }
}
    ${MeFragmentDoc}`;

/**
 * __useUpdateProfilePageQuery__
 *
 * To run a query within a React component, call `useUpdateProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>) {
        return Apollo.useQuery<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>(UpdateProfilePageDocument, baseOptions);
      }
export function useUpdateProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>) {
          return Apollo.useLazyQuery<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>(UpdateProfilePageDocument, baseOptions);
        }
export type UpdateProfilePageQueryHookResult = ReturnType<typeof useUpdateProfilePageQuery>;
export type UpdateProfilePageLazyQueryHookResult = ReturnType<typeof useUpdateProfilePageLazyQuery>;
export type UpdateProfilePageQueryResult = Apollo.QueryResult<UpdateProfilePageQuery, UpdateProfilePageQueryVariables>;
export const AppMetadataDocument = gql`
    query AppMetadata {
  app {
    id
    restaurantUserCountry
    restaurantUserCountryCode
    operatingCountries {
      id
      country
      code
      currency {
        id
        symbol
        code
      }
    }
  }
}
    `;

/**
 * __useAppMetadataQuery__
 *
 * To run a query within a React component, call `useAppMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppMetadataQuery(baseOptions?: Apollo.QueryHookOptions<AppMetadataQuery, AppMetadataQueryVariables>) {
        return Apollo.useQuery<AppMetadataQuery, AppMetadataQueryVariables>(AppMetadataDocument, baseOptions);
      }
export function useAppMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppMetadataQuery, AppMetadataQueryVariables>) {
          return Apollo.useLazyQuery<AppMetadataQuery, AppMetadataQueryVariables>(AppMetadataDocument, baseOptions);
        }
export type AppMetadataQueryHookResult = ReturnType<typeof useAppMetadataQuery>;
export type AppMetadataLazyQueryHookResult = ReturnType<typeof useAppMetadataLazyQuery>;
export type AppMetadataQueryResult = Apollo.QueryResult<AppMetadataQuery, AppMetadataQueryVariables>;
export const ExportMerchantSalesCsvDocument = gql`
    query ExportMerchantSalesCsv($storeIds: [ID!], $integrations: [Integration!], $states: [OrderState!], $paymentMethods: [PaymentMethod!], $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  merchantSalesCsv(
    storeIds: $storeIds
    integrations: $integrations
    states: $states
    paymentMethods: $paymentMethods
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useExportMerchantSalesCsvQuery__
 *
 * To run a query within a React component, call `useExportMerchantSalesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMerchantSalesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMerchantSalesCsvQuery({
 *   variables: {
 *      storeIds: // value for 'storeIds'
 *      integrations: // value for 'integrations'
 *      states: // value for 'states'
 *      paymentMethods: // value for 'paymentMethods'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportMerchantSalesCsvQuery(baseOptions: Apollo.QueryHookOptions<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>) {
        return Apollo.useQuery<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>(ExportMerchantSalesCsvDocument, baseOptions);
      }
export function useExportMerchantSalesCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>) {
          return Apollo.useLazyQuery<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>(ExportMerchantSalesCsvDocument, baseOptions);
        }
export type ExportMerchantSalesCsvQueryHookResult = ReturnType<typeof useExportMerchantSalesCsvQuery>;
export type ExportMerchantSalesCsvLazyQueryHookResult = ReturnType<typeof useExportMerchantSalesCsvLazyQuery>;
export type ExportMerchantSalesCsvQueryResult = Apollo.QueryResult<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>;
export const StoreStatusDocument = gql`
    query StoreStatus($storeId: ID!) {
  storeStatus(storeId: $storeId) {
    id
    grabfood {
      ...IntegrationStatusFragment
    }
    foodpanda {
      ...IntegrationStatusFragment
    }
    shopeefood {
      ...IntegrationStatusFragment
    }
  }
}
    ${IntegrationStatusFragmentFragmentDoc}`;

/**
 * __useStoreStatusQuery__
 *
 * To run a query within a React component, call `useStoreStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreStatusQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useStoreStatusQuery(baseOptions: Apollo.QueryHookOptions<StoreStatusQuery, StoreStatusQueryVariables>) {
        return Apollo.useQuery<StoreStatusQuery, StoreStatusQueryVariables>(StoreStatusDocument, baseOptions);
      }
export function useStoreStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreStatusQuery, StoreStatusQueryVariables>) {
          return Apollo.useLazyQuery<StoreStatusQuery, StoreStatusQueryVariables>(StoreStatusDocument, baseOptions);
        }
export type StoreStatusQueryHookResult = ReturnType<typeof useStoreStatusQuery>;
export type StoreStatusLazyQueryHookResult = ReturnType<typeof useStoreStatusLazyQuery>;
export type StoreStatusQueryResult = Apollo.QueryResult<StoreStatusQuery, StoreStatusQueryVariables>;
export const ItemsBulkAddTemplateDocument = gql`
    query ItemsBulkAddTemplate {
  itemsBulkAddTemplate
}
    `;

/**
 * __useItemsBulkAddTemplateQuery__
 *
 * To run a query within a React component, call `useItemsBulkAddTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsBulkAddTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsBulkAddTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemsBulkAddTemplateQuery(baseOptions?: Apollo.QueryHookOptions<ItemsBulkAddTemplateQuery, ItemsBulkAddTemplateQueryVariables>) {
        return Apollo.useQuery<ItemsBulkAddTemplateQuery, ItemsBulkAddTemplateQueryVariables>(ItemsBulkAddTemplateDocument, baseOptions);
      }
export function useItemsBulkAddTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsBulkAddTemplateQuery, ItemsBulkAddTemplateQueryVariables>) {
          return Apollo.useLazyQuery<ItemsBulkAddTemplateQuery, ItemsBulkAddTemplateQueryVariables>(ItemsBulkAddTemplateDocument, baseOptions);
        }
export type ItemsBulkAddTemplateQueryHookResult = ReturnType<typeof useItemsBulkAddTemplateQuery>;
export type ItemsBulkAddTemplateLazyQueryHookResult = ReturnType<typeof useItemsBulkAddTemplateLazyQuery>;
export type ItemsBulkAddTemplateQueryResult = Apollo.QueryResult<ItemsBulkAddTemplateQuery, ItemsBulkAddTemplateQueryVariables>;
export const ItemsCountByCategoriesDocument = gql`
    query ItemsCountByCategories($storeId: ID!) {
  itemsCountByCategories(storeId: $storeId) {
    itemCategories {
      id
      name
    }
    itemCount
  }
}
    `;

/**
 * __useItemsCountByCategoriesQuery__
 *
 * To run a query within a React component, call `useItemsCountByCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsCountByCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsCountByCategoriesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useItemsCountByCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ItemsCountByCategoriesQuery, ItemsCountByCategoriesQueryVariables>) {
        return Apollo.useQuery<ItemsCountByCategoriesQuery, ItemsCountByCategoriesQueryVariables>(ItemsCountByCategoriesDocument, baseOptions);
      }
export function useItemsCountByCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsCountByCategoriesQuery, ItemsCountByCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<ItemsCountByCategoriesQuery, ItemsCountByCategoriesQueryVariables>(ItemsCountByCategoriesDocument, baseOptions);
        }
export type ItemsCountByCategoriesQueryHookResult = ReturnType<typeof useItemsCountByCategoriesQuery>;
export type ItemsCountByCategoriesLazyQueryHookResult = ReturnType<typeof useItemsCountByCategoriesLazyQuery>;
export type ItemsCountByCategoriesQueryResult = Apollo.QueryResult<ItemsCountByCategoriesQuery, ItemsCountByCategoriesQueryVariables>;
export const ItemsBulkEditTemplateDocument = gql`
    query ItemsBulkEditTemplate($storeId: ID!, $menuCategoryIds: [ID!]!) {
  itemsBulkEditTemplate(storeId: $storeId, menuCategoryIds: $menuCategoryIds)
}
    `;

/**
 * __useItemsBulkEditTemplateQuery__
 *
 * To run a query within a React component, call `useItemsBulkEditTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsBulkEditTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsBulkEditTemplateQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      menuCategoryIds: // value for 'menuCategoryIds'
 *   },
 * });
 */
export function useItemsBulkEditTemplateQuery(baseOptions: Apollo.QueryHookOptions<ItemsBulkEditTemplateQuery, ItemsBulkEditTemplateQueryVariables>) {
        return Apollo.useQuery<ItemsBulkEditTemplateQuery, ItemsBulkEditTemplateQueryVariables>(ItemsBulkEditTemplateDocument, baseOptions);
      }
export function useItemsBulkEditTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsBulkEditTemplateQuery, ItemsBulkEditTemplateQueryVariables>) {
          return Apollo.useLazyQuery<ItemsBulkEditTemplateQuery, ItemsBulkEditTemplateQueryVariables>(ItemsBulkEditTemplateDocument, baseOptions);
        }
export type ItemsBulkEditTemplateQueryHookResult = ReturnType<typeof useItemsBulkEditTemplateQuery>;
export type ItemsBulkEditTemplateLazyQueryHookResult = ReturnType<typeof useItemsBulkEditTemplateLazyQuery>;
export type ItemsBulkEditTemplateQueryResult = Apollo.QueryResult<ItemsBulkEditTemplateQuery, ItemsBulkEditTemplateQueryVariables>;
export const KitchensDocument = gql`
    query Kitchens($outletIds: [ID!]!) {
  restaurants(outletIds: $outletIds) {
    id
    name
    number
  }
}
    `;

/**
 * __useKitchensQuery__
 *
 * To run a query within a React component, call `useKitchensQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchensQuery({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *   },
 * });
 */
export function useKitchensQuery(baseOptions: Apollo.QueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
        return Apollo.useQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, baseOptions);
      }
export function useKitchensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
          return Apollo.useLazyQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, baseOptions);
        }
export type KitchensQueryHookResult = ReturnType<typeof useKitchensQuery>;
export type KitchensLazyQueryHookResult = ReturnType<typeof useKitchensLazyQuery>;
export type KitchensQueryResult = Apollo.QueryResult<KitchensQuery, KitchensQueryVariables>;
export const KitchenStaffsDocument = gql`
    query KitchenStaffs {
  staffs {
    id
    email
    role
    phoneNumber
    pin
    isCashier
    isBanned
  }
}
    `;

/**
 * __useKitchenStaffsQuery__
 *
 * To run a query within a React component, call `useKitchenStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenStaffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKitchenStaffsQuery(baseOptions?: Apollo.QueryHookOptions<KitchenStaffsQuery, KitchenStaffsQueryVariables>) {
        return Apollo.useQuery<KitchenStaffsQuery, KitchenStaffsQueryVariables>(KitchenStaffsDocument, baseOptions);
      }
export function useKitchenStaffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenStaffsQuery, KitchenStaffsQueryVariables>) {
          return Apollo.useLazyQuery<KitchenStaffsQuery, KitchenStaffsQueryVariables>(KitchenStaffsDocument, baseOptions);
        }
export type KitchenStaffsQueryHookResult = ReturnType<typeof useKitchenStaffsQuery>;
export type KitchenStaffsLazyQueryHookResult = ReturnType<typeof useKitchenStaffsLazyQuery>;
export type KitchenStaffsQueryResult = Apollo.QueryResult<KitchenStaffsQuery, KitchenStaffsQueryVariables>;
export const KitchenStaffDocument = gql`
    query KitchenStaff($id: ID!) {
  staff(id: $id) {
    id
    email
    role
    phoneNumber
    pin
    isBanned
    timesheets {
      ...Timesheet
    }
  }
}
    ${TimesheetFragmentDoc}`;

/**
 * __useKitchenStaffQuery__
 *
 * To run a query within a React component, call `useKitchenStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKitchenStaffQuery(baseOptions: Apollo.QueryHookOptions<KitchenStaffQuery, KitchenStaffQueryVariables>) {
        return Apollo.useQuery<KitchenStaffQuery, KitchenStaffQueryVariables>(KitchenStaffDocument, baseOptions);
      }
export function useKitchenStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenStaffQuery, KitchenStaffQueryVariables>) {
          return Apollo.useLazyQuery<KitchenStaffQuery, KitchenStaffQueryVariables>(KitchenStaffDocument, baseOptions);
        }
export type KitchenStaffQueryHookResult = ReturnType<typeof useKitchenStaffQuery>;
export type KitchenStaffLazyQueryHookResult = ReturnType<typeof useKitchenStaffLazyQuery>;
export type KitchenStaffQueryResult = Apollo.QueryResult<KitchenStaffQuery, KitchenStaffQueryVariables>;
export const KitchenStaffsTimesheetsDocument = gql`
    query KitchenStaffsTimesheets {
  timesheets {
    id
    email
    latestTimesheets {
      ...Timesheet
    }
  }
}
    ${TimesheetFragmentDoc}`;

/**
 * __useKitchenStaffsTimesheetsQuery__
 *
 * To run a query within a React component, call `useKitchenStaffsTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenStaffsTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenStaffsTimesheetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKitchenStaffsTimesheetsQuery(baseOptions?: Apollo.QueryHookOptions<KitchenStaffsTimesheetsQuery, KitchenStaffsTimesheetsQueryVariables>) {
        return Apollo.useQuery<KitchenStaffsTimesheetsQuery, KitchenStaffsTimesheetsQueryVariables>(KitchenStaffsTimesheetsDocument, baseOptions);
      }
export function useKitchenStaffsTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenStaffsTimesheetsQuery, KitchenStaffsTimesheetsQueryVariables>) {
          return Apollo.useLazyQuery<KitchenStaffsTimesheetsQuery, KitchenStaffsTimesheetsQueryVariables>(KitchenStaffsTimesheetsDocument, baseOptions);
        }
export type KitchenStaffsTimesheetsQueryHookResult = ReturnType<typeof useKitchenStaffsTimesheetsQuery>;
export type KitchenStaffsTimesheetsLazyQueryHookResult = ReturnType<typeof useKitchenStaffsTimesheetsLazyQuery>;
export type KitchenStaffsTimesheetsQueryResult = Apollo.QueryResult<KitchenStaffsTimesheetsQuery, KitchenStaffsTimesheetsQueryVariables>;
export const LogsPaginateDocument = gql`
    query LogsPaginate($page: Int!, $storeIds: [ID!]!, $actions: [String!]!) {
  logsPaginate(page: $page, storeIds: $storeIds, actions: $actions) {
    id
    total
    pageLimit
    logs {
      id
      email
      action
      createdAt
      store {
        id
        name
      }
      loggable {
        __typename
        ... on Store {
          id
        }
        ... on OpeningHour {
          id
        }
        ... on MenuSection {
          id
        }
        ... on MenuCategory {
          id
        }
        ... on MenuItem {
          id
        }
        ... on MenuOption {
          id
        }
        ... on Promo {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useLogsPaginateQuery__
 *
 * To run a query within a React component, call `useLogsPaginateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogsPaginateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogsPaginateQuery({
 *   variables: {
 *      page: // value for 'page'
 *      storeIds: // value for 'storeIds'
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useLogsPaginateQuery(baseOptions: Apollo.QueryHookOptions<LogsPaginateQuery, LogsPaginateQueryVariables>) {
        return Apollo.useQuery<LogsPaginateQuery, LogsPaginateQueryVariables>(LogsPaginateDocument, baseOptions);
      }
export function useLogsPaginateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogsPaginateQuery, LogsPaginateQueryVariables>) {
          return Apollo.useLazyQuery<LogsPaginateQuery, LogsPaginateQueryVariables>(LogsPaginateDocument, baseOptions);
        }
export type LogsPaginateQueryHookResult = ReturnType<typeof useLogsPaginateQuery>;
export type LogsPaginateLazyQueryHookResult = ReturnType<typeof useLogsPaginateLazyQuery>;
export type LogsPaginateQueryResult = Apollo.QueryResult<LogsPaginateQuery, LogsPaginateQueryVariables>;
export const LogActionsMetaDocument = gql`
    query LogActionsMeta {
  logActionsMeta
}
    `;

/**
 * __useLogActionsMetaQuery__
 *
 * To run a query within a React component, call `useLogActionsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogActionsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogActionsMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogActionsMetaQuery(baseOptions?: Apollo.QueryHookOptions<LogActionsMetaQuery, LogActionsMetaQueryVariables>) {
        return Apollo.useQuery<LogActionsMetaQuery, LogActionsMetaQueryVariables>(LogActionsMetaDocument, baseOptions);
      }
export function useLogActionsMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogActionsMetaQuery, LogActionsMetaQueryVariables>) {
          return Apollo.useLazyQuery<LogActionsMetaQuery, LogActionsMetaQueryVariables>(LogActionsMetaDocument, baseOptions);
        }
export type LogActionsMetaQueryHookResult = ReturnType<typeof useLogActionsMetaQuery>;
export type LogActionsMetaLazyQueryHookResult = ReturnType<typeof useLogActionsMetaLazyQuery>;
export type LogActionsMetaQueryResult = Apollo.QueryResult<LogActionsMetaQuery, LogActionsMetaQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeWithCardDocument = gql`
    query MeWithCard {
  me {
    ...Me
    savedCardDetails
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMeWithCardQuery__
 *
 * To run a query within a React component, call `useMeWithCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeWithCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWithCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeWithCardQuery(baseOptions?: Apollo.QueryHookOptions<MeWithCardQuery, MeWithCardQueryVariables>) {
        return Apollo.useQuery<MeWithCardQuery, MeWithCardQueryVariables>(MeWithCardDocument, baseOptions);
      }
export function useMeWithCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeWithCardQuery, MeWithCardQueryVariables>) {
          return Apollo.useLazyQuery<MeWithCardQuery, MeWithCardQueryVariables>(MeWithCardDocument, baseOptions);
        }
export type MeWithCardQueryHookResult = ReturnType<typeof useMeWithCardQuery>;
export type MeWithCardLazyQueryHookResult = ReturnType<typeof useMeWithCardLazyQuery>;
export type MeWithCardQueryResult = Apollo.QueryResult<MeWithCardQuery, MeWithCardQueryVariables>;
export const MixOrdersDocument = gql`
    query MixOrders($limit: Int!, $offset: Int!, $state: [OrderState!]!, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $storeIds: [ID!], $integrations: [Integration!]) {
  mixOrders(
    offsetAttributes: {limit: $limit, offset: $offset}
    state: $state
    startDate: $startDate
    endDate: $endDate
    storeIds: $storeIds
    integrations: $integrations
  ) {
    id
    orderCode
    integration
    state
    acceptedTime
    brandNames
    storeOrders {
      id
      cabinetSlotNumber
    }
    store {
      id
      name
    }
  }
}
    `;

/**
 * __useMixOrdersQuery__
 *
 * To run a query within a React component, call `useMixOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      state: // value for 'state'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      storeIds: // value for 'storeIds'
 *      integrations: // value for 'integrations'
 *   },
 * });
 */
export function useMixOrdersQuery(baseOptions: Apollo.QueryHookOptions<MixOrdersQuery, MixOrdersQueryVariables>) {
        return Apollo.useQuery<MixOrdersQuery, MixOrdersQueryVariables>(MixOrdersDocument, baseOptions);
      }
export function useMixOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixOrdersQuery, MixOrdersQueryVariables>) {
          return Apollo.useLazyQuery<MixOrdersQuery, MixOrdersQueryVariables>(MixOrdersDocument, baseOptions);
        }
export type MixOrdersQueryHookResult = ReturnType<typeof useMixOrdersQuery>;
export type MixOrdersLazyQueryHookResult = ReturnType<typeof useMixOrdersLazyQuery>;
export type MixOrdersQueryResult = Apollo.QueryResult<MixOrdersQuery, MixOrdersQueryVariables>;
export const OutletsDocument = gql`
    query Outlets {
  outlets {
    id
    name
  }
}
    `;

/**
 * __useOutletsQuery__
 *
 * To run a query within a React component, call `useOutletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutletsQuery(baseOptions?: Apollo.QueryHookOptions<OutletsQuery, OutletsQueryVariables>) {
        return Apollo.useQuery<OutletsQuery, OutletsQueryVariables>(OutletsDocument, baseOptions);
      }
export function useOutletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletsQuery, OutletsQueryVariables>) {
          return Apollo.useLazyQuery<OutletsQuery, OutletsQueryVariables>(OutletsDocument, baseOptions);
        }
export type OutletsQueryHookResult = ReturnType<typeof useOutletsQuery>;
export type OutletsLazyQueryHookResult = ReturnType<typeof useOutletsLazyQuery>;
export type OutletsQueryResult = Apollo.QueryResult<OutletsQuery, OutletsQueryVariables>;
export const PosMenuSetupDocument = gql`
    query PosMenuSetup($storeId: ID!) {
  store(id: $storeId) {
    id
    name
    country
    menuCategories {
      id
      name
      availableStatus
      posItemPositions
      menuItems {
        id
        image
        name
        availableStatus
        stockEnabled
        price
      }
    }
  }
}
    `;

/**
 * __usePosMenuSetupQuery__
 *
 * To run a query within a React component, call `usePosMenuSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosMenuSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosMenuSetupQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePosMenuSetupQuery(baseOptions: Apollo.QueryHookOptions<PosMenuSetupQuery, PosMenuSetupQueryVariables>) {
        return Apollo.useQuery<PosMenuSetupQuery, PosMenuSetupQueryVariables>(PosMenuSetupDocument, baseOptions);
      }
export function usePosMenuSetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosMenuSetupQuery, PosMenuSetupQueryVariables>) {
          return Apollo.useLazyQuery<PosMenuSetupQuery, PosMenuSetupQueryVariables>(PosMenuSetupDocument, baseOptions);
        }
export type PosMenuSetupQueryHookResult = ReturnType<typeof usePosMenuSetupQuery>;
export type PosMenuSetupLazyQueryHookResult = ReturnType<typeof usePosMenuSetupLazyQuery>;
export type PosMenuSetupQueryResult = Apollo.QueryResult<PosMenuSetupQuery, PosMenuSetupQueryVariables>;
export const PromosDocument = gql`
    query Promos($storeId: ID!) {
  store(id: $storeId) {
    id
    country
    promos {
      id
      active
      name
      promoVariant
      amount
      validFrom
      validUntil
      startTime
      endTime
      days
    }
  }
}
    `;

/**
 * __usePromosQuery__
 *
 * To run a query within a React component, call `usePromosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromosQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePromosQuery(baseOptions: Apollo.QueryHookOptions<PromosQuery, PromosQueryVariables>) {
        return Apollo.useQuery<PromosQuery, PromosQueryVariables>(PromosDocument, baseOptions);
      }
export function usePromosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromosQuery, PromosQueryVariables>) {
          return Apollo.useLazyQuery<PromosQuery, PromosQueryVariables>(PromosDocument, baseOptions);
        }
export type PromosQueryHookResult = ReturnType<typeof usePromosQuery>;
export type PromosLazyQueryHookResult = ReturnType<typeof usePromosLazyQuery>;
export type PromosQueryResult = Apollo.QueryResult<PromosQuery, PromosQueryVariables>;
export const RatingsBreakdownDocument = gql`
    query RatingsBreakdown {
  ratingsBreakdown {
    id
    starCount
    value
  }
}
    `;

/**
 * __useRatingsBreakdownQuery__
 *
 * To run a query within a React component, call `useRatingsBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingsBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingsBreakdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useRatingsBreakdownQuery(baseOptions?: Apollo.QueryHookOptions<RatingsBreakdownQuery, RatingsBreakdownQueryVariables>) {
        return Apollo.useQuery<RatingsBreakdownQuery, RatingsBreakdownQueryVariables>(RatingsBreakdownDocument, baseOptions);
      }
export function useRatingsBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatingsBreakdownQuery, RatingsBreakdownQueryVariables>) {
          return Apollo.useLazyQuery<RatingsBreakdownQuery, RatingsBreakdownQueryVariables>(RatingsBreakdownDocument, baseOptions);
        }
export type RatingsBreakdownQueryHookResult = ReturnType<typeof useRatingsBreakdownQuery>;
export type RatingsBreakdownLazyQueryHookResult = ReturnType<typeof useRatingsBreakdownLazyQuery>;
export type RatingsBreakdownQueryResult = Apollo.QueryResult<RatingsBreakdownQuery, RatingsBreakdownQueryVariables>;
export const RatingsDocument = gql`
    query Ratings {
  ratings {
    id
    rate
    comment
    createdAt
    storeMixOrder {
      id
      consumer {
        id
        name
      }
      store {
        id
        name
      }
      orderMenuItems {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRatingsQuery__
 *
 * To run a query within a React component, call `useRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRatingsQuery(baseOptions?: Apollo.QueryHookOptions<RatingsQuery, RatingsQueryVariables>) {
        return Apollo.useQuery<RatingsQuery, RatingsQueryVariables>(RatingsDocument, baseOptions);
      }
export function useRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatingsQuery, RatingsQueryVariables>) {
          return Apollo.useLazyQuery<RatingsQuery, RatingsQueryVariables>(RatingsDocument, baseOptions);
        }
export type RatingsQueryHookResult = ReturnType<typeof useRatingsQuery>;
export type RatingsLazyQueryHookResult = ReturnType<typeof useRatingsLazyQuery>;
export type RatingsQueryResult = Apollo.QueryResult<RatingsQuery, RatingsQueryVariables>;
export const HeatMapAnalyticDocument = gql`
    query HeatMapAnalytic($storeId: ID!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  heatMapAnalytic(storeId: $storeId, startDate: $startDate, endDate: $endDate) {
    id
    days
    hours
    values
  }
}
    `;

/**
 * __useHeatMapAnalyticQuery__
 *
 * To run a query within a React component, call `useHeatMapAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatMapAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatMapAnalyticQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useHeatMapAnalyticQuery(baseOptions: Apollo.QueryHookOptions<HeatMapAnalyticQuery, HeatMapAnalyticQueryVariables>) {
        return Apollo.useQuery<HeatMapAnalyticQuery, HeatMapAnalyticQueryVariables>(HeatMapAnalyticDocument, baseOptions);
      }
export function useHeatMapAnalyticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatMapAnalyticQuery, HeatMapAnalyticQueryVariables>) {
          return Apollo.useLazyQuery<HeatMapAnalyticQuery, HeatMapAnalyticQueryVariables>(HeatMapAnalyticDocument, baseOptions);
        }
export type HeatMapAnalyticQueryHookResult = ReturnType<typeof useHeatMapAnalyticQuery>;
export type HeatMapAnalyticLazyQueryHookResult = ReturnType<typeof useHeatMapAnalyticLazyQuery>;
export type HeatMapAnalyticQueryResult = Apollo.QueryResult<HeatMapAnalyticQuery, HeatMapAnalyticQueryVariables>;
export const MerchantSalesDocument = gql`
    query MerchantSales($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $integrations: [Integration!], $states: [OrderState!], $paymentMethods: [PaymentMethod!], $storeIds: [ID!], $page: Int!) {
  merchantSales(
    startDate: $startDate
    endDate: $endDate
    integrations: $integrations
    states: $states
    paymentMethods: $paymentMethods
    storeIds: $storeIds
    page: $page
  ) {
    id
    pageLimit
    sales {
      id
      mixOrderId
      state
      orderCode
      orderId
      integration
      paymentMethod
      createdAt
      discountCode
      originalPrice
      isFirstMobileOrder
      platformFundedPromo
      merchantFundedPromo
      counterFeePercentage
      platformFeePercentage
      revenueSharePercentage
      revenue
      tax
      externalFee
      counterFee
      platformFee
      revenueShare
      netIncome
      consumerName
      consumerPhone
      store {
        id
        name
        country
        restaurant {
          id
          name
          number
        }
      }
    }
  }
}
    `;

/**
 * __useMerchantSalesQuery__
 *
 * To run a query within a React component, call `useMerchantSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSalesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      integrations: // value for 'integrations'
 *      states: // value for 'states'
 *      paymentMethods: // value for 'paymentMethods'
 *      storeIds: // value for 'storeIds'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMerchantSalesQuery(baseOptions: Apollo.QueryHookOptions<MerchantSalesQuery, MerchantSalesQueryVariables>) {
        return Apollo.useQuery<MerchantSalesQuery, MerchantSalesQueryVariables>(MerchantSalesDocument, baseOptions);
      }
export function useMerchantSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSalesQuery, MerchantSalesQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSalesQuery, MerchantSalesQueryVariables>(MerchantSalesDocument, baseOptions);
        }
export type MerchantSalesQueryHookResult = ReturnType<typeof useMerchantSalesQuery>;
export type MerchantSalesLazyQueryHookResult = ReturnType<typeof useMerchantSalesLazyQuery>;
export type MerchantSalesQueryResult = Apollo.QueryResult<MerchantSalesQuery, MerchantSalesQueryVariables>;
export const MerchantSalesTotalDocument = gql`
    query MerchantSalesTotal($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $integrations: [Integration!], $states: [OrderState!], $paymentMethods: [PaymentMethod!], $storeIds: [ID!]) {
  merchantSalesTotal(
    startDate: $startDate
    endDate: $endDate
    integrations: $integrations
    states: $states
    paymentMethods: $paymentMethods
    storeIds: $storeIds
  ) {
    id
    totalCount
    totalOriginalPrice
    totalPlatformFundedPromo
    totalMerchantFundedPromo
    totalRevenue
    totalTax
    totalExternalFee
    totalCounterFee
    totalPlatformFee
    totalRevenueShare
    totalNetIncome
  }
}
    `;

/**
 * __useMerchantSalesTotalQuery__
 *
 * To run a query within a React component, call `useMerchantSalesTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSalesTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSalesTotalQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      integrations: // value for 'integrations'
 *      states: // value for 'states'
 *      paymentMethods: // value for 'paymentMethods'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useMerchantSalesTotalQuery(baseOptions: Apollo.QueryHookOptions<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>) {
        return Apollo.useQuery<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>(MerchantSalesTotalDocument, baseOptions);
      }
export function useMerchantSalesTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>(MerchantSalesTotalDocument, baseOptions);
        }
export type MerchantSalesTotalQueryHookResult = ReturnType<typeof useMerchantSalesTotalQuery>;
export type MerchantSalesTotalLazyQueryHookResult = ReturnType<typeof useMerchantSalesTotalLazyQuery>;
export type MerchantSalesTotalQueryResult = Apollo.QueryResult<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>;
export const SettlementsDocument = gql`
    query Settlements($restaurantIds: [ID!]!, $dateRanges: [DateRanges!]!) {
  settlements(restaurantIds: $restaurantIds, dateRanges: $dateRanges) {
    id
    pdf
    createdAt
    restaurant {
      id
      name
      number
      outlet {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSettlementsQuery__
 *
 * To run a query within a React component, call `useSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsQuery({
 *   variables: {
 *      restaurantIds: // value for 'restaurantIds'
 *      dateRanges: // value for 'dateRanges'
 *   },
 * });
 */
export function useSettlementsQuery(baseOptions: Apollo.QueryHookOptions<SettlementsQuery, SettlementsQueryVariables>) {
        return Apollo.useQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, baseOptions);
      }
export function useSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettlementsQuery, SettlementsQueryVariables>) {
          return Apollo.useLazyQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, baseOptions);
        }
export type SettlementsQueryHookResult = ReturnType<typeof useSettlementsQuery>;
export type SettlementsLazyQueryHookResult = ReturnType<typeof useSettlementsLazyQuery>;
export type SettlementsQueryResult = Apollo.QueryResult<SettlementsQuery, SettlementsQueryVariables>;
export const SettlementsDownloadDocument = gql`
    query SettlementsDownload($ids: [ID!]!) {
  settlementsDownload(ids: $ids)
}
    `;

/**
 * __useSettlementsDownloadQuery__
 *
 * To run a query within a React component, call `useSettlementsDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsDownloadQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSettlementsDownloadQuery(baseOptions: Apollo.QueryHookOptions<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>) {
        return Apollo.useQuery<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>(SettlementsDownloadDocument, baseOptions);
      }
export function useSettlementsDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>) {
          return Apollo.useLazyQuery<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>(SettlementsDownloadDocument, baseOptions);
        }
export type SettlementsDownloadQueryHookResult = ReturnType<typeof useSettlementsDownloadQuery>;
export type SettlementsDownloadLazyQueryHookResult = ReturnType<typeof useSettlementsDownloadLazyQuery>;
export type SettlementsDownloadQueryResult = Apollo.QueryResult<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>;
export const SiblingStoresDocument = gql`
    query SiblingStores {
  siblingStores {
    id
    name
  }
}
    `;

/**
 * __useSiblingStoresQuery__
 *
 * To run a query within a React component, call `useSiblingStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiblingStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiblingStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiblingStoresQuery(baseOptions?: Apollo.QueryHookOptions<SiblingStoresQuery, SiblingStoresQueryVariables>) {
        return Apollo.useQuery<SiblingStoresQuery, SiblingStoresQueryVariables>(SiblingStoresDocument, baseOptions);
      }
export function useSiblingStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiblingStoresQuery, SiblingStoresQueryVariables>) {
          return Apollo.useLazyQuery<SiblingStoresQuery, SiblingStoresQueryVariables>(SiblingStoresDocument, baseOptions);
        }
export type SiblingStoresQueryHookResult = ReturnType<typeof useSiblingStoresQuery>;
export type SiblingStoresLazyQueryHookResult = ReturnType<typeof useSiblingStoresLazyQuery>;
export type SiblingStoresQueryResult = Apollo.QueryResult<SiblingStoresQuery, SiblingStoresQueryVariables>;
export const StoresDocument = gql`
    query Stores($kitchenIds: [ID!]!, $shouldSort: Boolean) {
  stores(restaurantIds: $kitchenIds, shouldSort: $shouldSort) {
    id
    name
    paused
    pauseTime
  }
}
    `;

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *      kitchenIds: // value for 'kitchenIds'
 *      shouldSort: // value for 'shouldSort'
 *   },
 * });
 */
export function useStoresQuery(baseOptions: Apollo.QueryHookOptions<StoresQuery, StoresQueryVariables>) {
        return Apollo.useQuery<StoresQuery, StoresQueryVariables>(StoresDocument, baseOptions);
      }
export function useStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresQuery, StoresQueryVariables>) {
          return Apollo.useLazyQuery<StoresQuery, StoresQueryVariables>(StoresDocument, baseOptions);
        }
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>;
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>;
export type StoresQueryResult = Apollo.QueryResult<StoresQuery, StoresQueryVariables>;
export const StoreDocument = gql`
    query Store($id: ID!) {
  store(id: $id) {
    id
    name
    paused
    grabfoodStoreId
    foodpandaChainId
    shopeefoodStoreId
  }
}
    `;

/**
 * __useStoreQuery__
 *
 * To run a query within a React component, call `useStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreQuery(baseOptions: Apollo.QueryHookOptions<StoreQuery, StoreQueryVariables>) {
        return Apollo.useQuery<StoreQuery, StoreQueryVariables>(StoreDocument, baseOptions);
      }
export function useStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreQuery, StoreQueryVariables>) {
          return Apollo.useLazyQuery<StoreQuery, StoreQueryVariables>(StoreDocument, baseOptions);
        }
export type StoreQueryHookResult = ReturnType<typeof useStoreQuery>;
export type StoreLazyQueryHookResult = ReturnType<typeof useStoreLazyQuery>;
export type StoreQueryResult = Apollo.QueryResult<StoreQuery, StoreQueryVariables>;
export const StoreAnalyticDocument = gql`
    query StoreAnalytic($storeIds: [ID!]!, $integrations: [Integration!]!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  storesAnalytic(
    storeIds: $storeIds
    integrations: $integrations
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    sales
    ordersCount
    salesByBrand {
      id
      brand
      value
      color
    }
    salesByDeliveryOption {
      id
      deliveryOption
      value
      color
    }
    dailySales {
      id
      time
      value
    }
    dailySoldItems {
      id
      name
      count
    }
  }
}
    `;

/**
 * __useStoreAnalyticQuery__
 *
 * To run a query within a React component, call `useStoreAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAnalyticQuery({
 *   variables: {
 *      storeIds: // value for 'storeIds'
 *      integrations: // value for 'integrations'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useStoreAnalyticQuery(baseOptions: Apollo.QueryHookOptions<StoreAnalyticQuery, StoreAnalyticQueryVariables>) {
        return Apollo.useQuery<StoreAnalyticQuery, StoreAnalyticQueryVariables>(StoreAnalyticDocument, baseOptions);
      }
export function useStoreAnalyticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreAnalyticQuery, StoreAnalyticQueryVariables>) {
          return Apollo.useLazyQuery<StoreAnalyticQuery, StoreAnalyticQueryVariables>(StoreAnalyticDocument, baseOptions);
        }
export type StoreAnalyticQueryHookResult = ReturnType<typeof useStoreAnalyticQuery>;
export type StoreAnalyticLazyQueryHookResult = ReturnType<typeof useStoreAnalyticLazyQuery>;
export type StoreAnalyticQueryResult = Apollo.QueryResult<StoreAnalyticQuery, StoreAnalyticQueryVariables>;
export const SupplierItemCategoriesDocument = gql`
    query SupplierItemCategories {
  supplierItemCategories {
    id
    name
  }
}
    `;

/**
 * __useSupplierItemCategoriesQuery__
 *
 * To run a query within a React component, call `useSupplierItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierItemCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplierItemCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SupplierItemCategoriesQuery, SupplierItemCategoriesQueryVariables>) {
        return Apollo.useQuery<SupplierItemCategoriesQuery, SupplierItemCategoriesQueryVariables>(SupplierItemCategoriesDocument, baseOptions);
      }
export function useSupplierItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierItemCategoriesQuery, SupplierItemCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<SupplierItemCategoriesQuery, SupplierItemCategoriesQueryVariables>(SupplierItemCategoriesDocument, baseOptions);
        }
export type SupplierItemCategoriesQueryHookResult = ReturnType<typeof useSupplierItemCategoriesQuery>;
export type SupplierItemCategoriesLazyQueryHookResult = ReturnType<typeof useSupplierItemCategoriesLazyQuery>;
export type SupplierItemCategoriesQueryResult = Apollo.QueryResult<SupplierItemCategoriesQuery, SupplierItemCategoriesQueryVariables>;
export const SuppliersDocument = gql`
    query Suppliers {
  suppliers {
    id
    name
  }
}
    `;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
      }
export function useSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const SupplierItemsDocument = gql`
    query SupplierItems($supplierItemCategoryIds: [ID!], $supplierIds: [ID!]) {
  supplierItems(
    supplierItemCategoryIds: $supplierItemCategoryIds
    supplierIds: $supplierIds
  ) {
    id
    image
    sku
    name
    uom
    price
    availableStatus
    stockEnabled
    stockCount
    deliveryDays
    supplier {
      id
      name
    }
  }
}
    `;

/**
 * __useSupplierItemsQuery__
 *
 * To run a query within a React component, call `useSupplierItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierItemsQuery({
 *   variables: {
 *      supplierItemCategoryIds: // value for 'supplierItemCategoryIds'
 *      supplierIds: // value for 'supplierIds'
 *   },
 * });
 */
export function useSupplierItemsQuery(baseOptions?: Apollo.QueryHookOptions<SupplierItemsQuery, SupplierItemsQueryVariables>) {
        return Apollo.useQuery<SupplierItemsQuery, SupplierItemsQueryVariables>(SupplierItemsDocument, baseOptions);
      }
export function useSupplierItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierItemsQuery, SupplierItemsQueryVariables>) {
          return Apollo.useLazyQuery<SupplierItemsQuery, SupplierItemsQueryVariables>(SupplierItemsDocument, baseOptions);
        }
export type SupplierItemsQueryHookResult = ReturnType<typeof useSupplierItemsQuery>;
export type SupplierItemsLazyQueryHookResult = ReturnType<typeof useSupplierItemsLazyQuery>;
export type SupplierItemsQueryResult = Apollo.QueryResult<SupplierItemsQuery, SupplierItemsQueryVariables>;
export const SupplierDestinationsDocument = gql`
    query SupplierDestinations {
  outlets {
    id
    name
    address
  }
}
    `;

/**
 * __useSupplierDestinationsQuery__
 *
 * To run a query within a React component, call `useSupplierDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierDestinationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplierDestinationsQuery(baseOptions?: Apollo.QueryHookOptions<SupplierDestinationsQuery, SupplierDestinationsQueryVariables>) {
        return Apollo.useQuery<SupplierDestinationsQuery, SupplierDestinationsQueryVariables>(SupplierDestinationsDocument, baseOptions);
      }
export function useSupplierDestinationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierDestinationsQuery, SupplierDestinationsQueryVariables>) {
          return Apollo.useLazyQuery<SupplierDestinationsQuery, SupplierDestinationsQueryVariables>(SupplierDestinationsDocument, baseOptions);
        }
export type SupplierDestinationsQueryHookResult = ReturnType<typeof useSupplierDestinationsQuery>;
export type SupplierDestinationsLazyQueryHookResult = ReturnType<typeof useSupplierDestinationsLazyQuery>;
export type SupplierDestinationsQueryResult = Apollo.QueryResult<SupplierDestinationsQuery, SupplierDestinationsQueryVariables>;
export const SupplierPurchaseOrdersDocument = gql`
    query SupplierPurchaseOrders($states: [SupplierPurchaseOrderRestaurantUserState!]!) {
  supplierPurchaseOrders(states: $states) {
    ...SupplierPurchaseOrders
  }
}
    ${SupplierPurchaseOrdersFragmentDoc}`;

/**
 * __useSupplierPurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useSupplierPurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPurchaseOrdersQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useSupplierPurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>) {
        return Apollo.useQuery<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>(SupplierPurchaseOrdersDocument, baseOptions);
      }
export function useSupplierPurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>) {
          return Apollo.useLazyQuery<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>(SupplierPurchaseOrdersDocument, baseOptions);
        }
export type SupplierPurchaseOrdersQueryHookResult = ReturnType<typeof useSupplierPurchaseOrdersQuery>;
export type SupplierPurchaseOrdersLazyQueryHookResult = ReturnType<typeof useSupplierPurchaseOrdersLazyQuery>;
export type SupplierPurchaseOrdersQueryResult = Apollo.QueryResult<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>;
export const SupplierPurchaseOrderDocument = gql`
    query SupplierPurchaseOrder($id: ID!) {
  supplierPurchaseOrder(id: $id) {
    ...SupplierPurchaseOrder
  }
}
    ${SupplierPurchaseOrderFragmentDoc}`;

/**
 * __useSupplierPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useSupplierPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPurchaseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierPurchaseOrderQuery(baseOptions: Apollo.QueryHookOptions<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>) {
        return Apollo.useQuery<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>(SupplierPurchaseOrderDocument, baseOptions);
      }
export function useSupplierPurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>) {
          return Apollo.useLazyQuery<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>(SupplierPurchaseOrderDocument, baseOptions);
        }
export type SupplierPurchaseOrderQueryHookResult = ReturnType<typeof useSupplierPurchaseOrderQuery>;
export type SupplierPurchaseOrderLazyQueryHookResult = ReturnType<typeof useSupplierPurchaseOrderLazyQuery>;
export type SupplierPurchaseOrderQueryResult = Apollo.QueryResult<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>;
export const SupplyBnplDocument = gql`
    query SupplyBnpl {
  bnpl
}
    `;

/**
 * __useSupplyBnplQuery__
 *
 * To run a query within a React component, call `useSupplyBnplQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyBnplQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyBnplQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplyBnplQuery(baseOptions?: Apollo.QueryHookOptions<SupplyBnplQuery, SupplyBnplQueryVariables>) {
        return Apollo.useQuery<SupplyBnplQuery, SupplyBnplQueryVariables>(SupplyBnplDocument, baseOptions);
      }
export function useSupplyBnplLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplyBnplQuery, SupplyBnplQueryVariables>) {
          return Apollo.useLazyQuery<SupplyBnplQuery, SupplyBnplQueryVariables>(SupplyBnplDocument, baseOptions);
        }
export type SupplyBnplQueryHookResult = ReturnType<typeof useSupplyBnplQuery>;
export type SupplyBnplLazyQueryHookResult = ReturnType<typeof useSupplyBnplLazyQuery>;
export type SupplyBnplQueryResult = Apollo.QueryResult<SupplyBnplQuery, SupplyBnplQueryVariables>;